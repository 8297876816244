import {MessageState} from "../../module/message";
import {notificationActions} from "../../module/notification";
import {coreActions} from "../../module/core";
import {useAppDispatch, useAppSelector} from "../../module/hook";
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getFirestore,
    limit,
    onSnapshot,
    orderBy,
    query,
    startAfter,
} from "firebase/firestore";
import {useNavigate} from "react-router-dom";
import {useBottomScrollListener} from "react-bottom-scroll-listener";
import React, {useEffect, useState} from "react";
import "./notification.css";
import {Header} from "../header";
import {Sidebar} from "../sidebar";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {RightSidebar} from "../right_sidebar";
import moment from "moment";
import defaultAvatar from "../../asset/image/default_avatar.png";
import LinearProgress from "@mui/material/LinearProgress";
import trash from "../../asset/image/trash.png";
import {Loading} from "../loading/loading";

export function Notification() {
    const navigate = useNavigate();

    const auth = getAuth();
    const firestore = getFirestore();

    const isLoading = useAppSelector((state) => state.core.isLoading);

    const dispatch = useAppDispatch();

    const [lastVisible, setLastVisible] = useState<any>(undefined);

    const getMessages = async () => {
        let q;
        if (lastVisible === -1) {
            return;
        } else if (lastVisible !== undefined) {
            q = query(
                collection(firestore, "user", auth.currentUser!.uid, "notification"),
                orderBy("timestamp", "desc"),
                limit(10),
                startAfter(lastVisible),
            );
        } else {
            dispatch(notificationActions.resetFetching());
            dispatch(notificationActions.resetFetched());

            q = query(
                collection(firestore, "user", auth.currentUser!.uid, "notification"),
                orderBy("timestamp", "desc"),
                limit(10),
            );
        }

        onSnapshot(q, async (snapshot) => {
            const newDocuments = snapshot.docs.map((elem) => elem.data());

            dispatch(notificationActions.incrementFetching(newDocuments.length));

            for (const elem of newDocuments) {
                getMessage(elem);
            }

            if (snapshot.docs.length === 0) {
                setLastVisible(-1);
            } else {
                setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
            }
        });
    };

    const getMessage = async (elem: any) => {
        if (auth.currentUser !== null) {
            const blockDocRef = doc(
                firestore,
                "user",
                auth.currentUser.uid,
                "block",
                elem.authorUid,
            );
            const blockDocSnap = await getDoc(blockDocRef);
            if (blockDocSnap.exists()) {
                if (elem.messageType === "comment") {
                    const notificationRef = doc(
                        firestore,
                        "user",
                        auth.currentUser!.uid,
                        "notification",
                        elem.commentId,
                    );
                    await deleteDoc(notificationRef);
                } else {
                    const notificationRef = doc(
                        firestore,
                        "user",
                        auth.currentUser!.uid,
                        "notification",
                        elem.replyId,
                    );
                    await deleteDoc(notificationRef);
                }

                dispatch(notificationActions.appendBlocked(elem.documentId));
                dispatch(notificationActions.incrementFetched(1));
                return;
            }
        }

        let documentAuthorUid = "";
        let documentTimestamp = 0;
        let documentContent = "";
        let documentHashtags = [];

        const documentDocSnap = await getDoc(
            doc(firestore, "board", elem.documentId),
        );
        if (documentDocSnap.exists()) {
            const data = documentDocSnap.data();
            documentAuthorUid = data.authorUid || "";
            documentTimestamp = data.timestamp || "";
            documentContent = data.content || "";
            documentHashtags = data.hashtags.split(",") || [];
        } else {
            if (elem.messageType === "comment") {
                const notificationRef = doc(
                    firestore,
                    "user",
                    auth.currentUser!.uid,
                    "notification",
                    elem.commentId,
                );
                await deleteDoc(notificationRef);
            } else {
                const notificationRef = doc(
                    firestore,
                    "user",
                    auth.currentUser!.uid,
                    "notification",
                    elem.replyId,
                );
                await deleteDoc(notificationRef);
            }

            dispatch(notificationActions.appendBlocked(elem.documentId));
            dispatch(notificationActions.incrementFetched(1));
            return;
        }

        let documentAuthorAvatarUrl = "";
        let documentAuthorNickname = "";

        const documentAuthorDocSnap = await getDoc(
            doc(firestore, "user", documentAuthorUid),
        );
        if (documentAuthorDocSnap.exists()) {
            const data = documentAuthorDocSnap.data();
            documentAuthorAvatarUrl = data.avatarUrl || "";
            documentAuthorNickname = data.nickname || "";
        } else {
            if (elem.messageType === "comment") {
                const notificationRef = doc(
                    firestore,
                    "user",
                    auth.currentUser!.uid,
                    "notification",
                    elem.commentId,
                );

                await deleteDoc(notificationRef);
            } else {
                const notificationRef = doc(
                    firestore,
                    "user",
                    auth.currentUser!.uid,
                    "notification",
                    elem.replyId,
                );

                await deleteDoc(notificationRef);
            }

            dispatch(notificationActions.appendBlocked(elem.documentId));
            dispatch(notificationActions.incrementFetched(1));
            return;
        }

        let commentAuthorUid = "";
        let commentTimestamp = 0;
        let commentContent = "";

        const commentDocSnap = await getDoc(
            doc(firestore, "board", elem.documentId, "comment", elem.commentId),
        );
        if (commentDocSnap.exists()) {
            const data = commentDocSnap.data();
            commentAuthorUid = data.authorUid || "";
            commentTimestamp = data.timestamp || "";
            commentContent = data.content || "";
        } else {
            if (elem.messageType === "comment") {
                const notificationRef = doc(
                    firestore,
                    "user",
                    auth.currentUser!.uid,
                    "notification",
                    elem.commentId,
                );

                await deleteDoc(notificationRef);
            } else {
                const notificationRef = doc(
                    firestore,
                    "user",
                    auth.currentUser!.uid,
                    "notification",
                    elem.replyId,
                );

                await deleteDoc(notificationRef);
            }

            dispatch(notificationActions.appendBlocked(elem.documentId));
            dispatch(notificationActions.incrementFetched(1));
            return;
        }

        let commentAuthorAvatarUrl = "";
        let commentAuthorNickname = "";

        const commentAuthorDocSnap = await getDoc(
            doc(firestore, "user", commentAuthorUid),
        );
        if (commentAuthorDocSnap.exists()) {
            const data = commentAuthorDocSnap.data();
            commentAuthorAvatarUrl = data.avatarUrl || "";
            commentAuthorNickname = data.nickname || "";
        } else {
            if (elem.messageType === "comment") {
                const notificationRef = doc(
                    firestore,
                    "user",
                    auth.currentUser!.uid,
                    "notification",
                    elem.commentId,
                );

                await deleteDoc(notificationRef);
            } else {
                const notificationRef = doc(
                    firestore,
                    "user",
                    auth.currentUser!.uid,
                    "notification",
                    elem.replyId,
                );

                await deleteDoc(notificationRef);
            }

            dispatch(notificationActions.appendBlocked(elem.documentId));
            dispatch(notificationActions.incrementFetched(1));
            return;
        }

        let authorNickname = "";
        let authorAvatarUrl = "";

        const authorDocSnap = await getDoc(doc(firestore, "user", elem.authorUid));
        if (authorDocSnap.exists()) {
            const data = authorDocSnap.data();
            authorNickname = data.nickname || "";
            authorAvatarUrl = data.avatarUrl || "";
        } else {
            if (elem.messageType === "comment") {
                const notificationRef = doc(
                    firestore,
                    "user",
                    auth.currentUser!.uid,
                    "notification",
                    elem.commentId,
                );

                await deleteDoc(notificationRef);
            } else {
                const notificationRef = doc(
                    firestore,
                    "user",
                    auth.currentUser!.uid,
                    "notification",
                    elem.replyId,
                );

                await deleteDoc(notificationRef);
            }

            dispatch(notificationActions.appendBlocked(elem.documentId));
            dispatch(notificationActions.incrementFetched(1));
            return;
        }

        const documentUpRef = doc(
            firestore,
            "metadata",
            elem.documentId,
            "up",
            "summary",
        );
        const documentDownRef = doc(
            firestore,
            "metadata",
            elem.documentId,
            "down",
            "summary",
        );
        const documentCommentRef = doc(
            firestore,
            "board",
            elem.documentId,
            "comment",
            "summary",
        );

        const commentUpRef = doc(
            firestore,
            "metadata",
            elem.documentId,
            "comment",
            elem.commentId,
            "up",
            "summary",
        );
        const commentDownRef = doc(
            firestore,
            "metadata",
            elem.documentId,
            "comment",
            elem.commentId,
            "down",
            "summary",
        );
        const commentReplyRef = doc(
            firestore,
            "board",
            elem.documentId,
            "comment",
            elem.commentId,
            "reply",
            "summary",
        );

        const documentUpSnap = await getDoc(documentUpRef);
        const documentDownSnap = await getDoc(documentDownRef);
        const documentCommentSnap = await getDoc(documentCommentRef);
        const commentUpSnap = await getDoc(commentUpRef);
        const commentDownSnap = await getDoc(commentDownRef);
        const commentReplySnap = await getDoc(commentReplyRef);

        let documentNumUps = 0;
        let documentNumDowns = 0;
        let documentNumComments = 0;
        let commentNumUps = 0;
        let commentNumDowns = 0;
        let commentNumReplies = 0;

        if (documentUpSnap.exists()) {
            documentNumUps = documentUpSnap.data().numUps || 0;
        }

        if (documentDownSnap.exists()) {
            documentNumDowns = documentDownSnap.data().numDowns || 0;
        }

        if (documentCommentSnap.exists()) {
            documentNumComments = documentCommentSnap.data().numComments || 0;
        }

        if (commentUpSnap.exists()) {
            commentNumUps = commentUpSnap.data().numUps || 0;
        }

        if (commentDownSnap.exists()) {
            commentNumDowns = commentDownSnap.data().numDowns || 0;
        }

        if (commentReplySnap.exists()) {
            commentNumReplies = commentReplySnap.data().numComments || 0;
        }

        let documentClickUp = false;
        let documentClickDown = false;
        let documentClickBookmark = false;
        let commentClickUp = false;
        let commentClickDown = false;

        if (auth.currentUser !== null) {
            const documentUpDocRef = doc(
                firestore,
                "metadata",
                elem.documentId,
                "up",
                auth.currentUser.uid,
            );

            const documentDownDocRef = doc(
                firestore,
                "metadata",
                elem.documentId,
                "down",
                auth.currentUser.uid,
            );

            const documentBookmarkDocRef = doc(
                firestore,
                "user",
                auth.currentUser.uid,
                "bookmark",
                elem.documentId,
            );

            const commentUpDocRef = doc(
                firestore,
                "metadata",
                elem.documentId,
                "comment",
                elem.commentId,
                "up",
                auth.currentUser.uid,
            );

            const commentDownDocRef = doc(
                firestore,
                "metadata",
                elem.documentId,
                "comment",
                elem.commentId,
                "down",
                auth.currentUser.uid,
            );

            await getDoc(documentUpDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    documentClickUp = true;
                }
            });

            await getDoc(documentDownDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    documentClickDown = true;
                }
            });

            await getDoc(documentBookmarkDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    documentClickBookmark = true;
                }
            });

            await getDoc(commentUpDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    commentClickUp = true;
                }
            });

            await getDoc(commentDownDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    commentClickDown = true;
                }
            });
        }

        const message: MessageState = {
            messageType: elem.messageType,
            isDeleted: false,

            documentId: elem.documentId,
            documentAuthorUid: documentAuthorUid,
            documentAuthorAvatarUrl: documentAuthorAvatarUrl,
            documentAuthorNickname: documentAuthorNickname,
            documentTimestamp: documentTimestamp,
            documentContent: documentContent,
            documentThumbnailContent: [],
            documentThumbnailImageSizes: [],
            documentHashtags: documentHashtags,
            documentNumUps: documentNumUps,
            documentNumDowns: documentNumDowns,
            documentNumComments: documentNumComments,
            documentNumTokens: 0,
            documentClickUp: documentClickUp,
            documentClickDown: documentClickDown,
            documentClickBookmark: documentClickBookmark,

            commentId: elem.commentId,
            commentAuthorUid: commentAuthorUid,
            commentAuthorAvatarUrl: commentAuthorAvatarUrl,
            commentAuthorNickname: commentAuthorNickname,
            commentTimestamp: commentTimestamp,
            commentContent: commentContent,
            commentNumUps: commentNumUps,
            commentNumDowns: commentNumDowns,
            commentNumReplies: commentNumReplies,
            commentNumTokens: 0,
            commentClickUp: commentClickUp,
            commentClickDown: commentClickDown,

            replyId: elem.replyId,

            authorUid: elem.authorUid,
            authorNickname: authorNickname,
            authorAvatarUrl: authorAvatarUrl,
            timestamp: elem.timestamp,
        };

        dispatch(notificationActions.appendMessage(message));

        dispatch(notificationActions.incrementFetched(1));
    };

    useEffect(() => {
        if (auth.currentUser === null) {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    dispatch(coreActions.setFocus("notification"));

                    dispatch(notificationActions.resetBlocked());
                    dispatch(notificationActions.resetMessages());

                    setLastVisible(undefined);

                    getMessages();
                } else {
                    navigate("/login");
                }
            });
        } else {
            dispatch(coreActions.setFocus("notification"));

            dispatch(notificationActions.resetBlocked());
            dispatch(notificationActions.resetMessages());

            setLastVisible(undefined);

            getMessages();
        }
    }, [auth.currentUser]);

    useBottomScrollListener(getMessages);

    return (
        <div className="Notification">{isLoading ? <Loading/> : <Body/>}</div>
    );
}

function Body() {
    const messages = useAppSelector((state) => state.notification.messages);

    const fetching = useAppSelector((state) => state.notification.fetching);
    const fetched = useAppSelector((state) => state.notification.fetched);

    if (fetched < fetching) {
        return (
            <div className="Notification-Body">
                <Header/>
                <Sidebar/>
                <Title/>
                <LoadingBar/>
                <Messages/>
                <RightSidebar/>
            </div>
        );
    } else {
        let isEmpty = messages.length === 0;
        let isDeletedAll = true;

        for (const message of messages) {
            if (!message.isDeleted) {
                isDeletedAll = false;
                break;
            }
        }

        if (isEmpty || isDeletedAll) {
            return (
                <div className="Notification-Body">
                    <Header/>
                    <Sidebar/>
                    <Title/>
                    <p>알림이 없습니다.</p>
                    <RightSidebar/>
                </div>
            );
        } else {
            return (
                <div className="Notification-Body">
                    <Header/>
                    <Sidebar/>
                    <Title/>
                    <Messages/>
                    <RightSidebar/>
                </div>
            );
        }
    }
}

function Title() {
    const messages = useAppSelector((state) => state.notification.messages);

    let isEmpty = messages.length === 0;
    let isDeletedAll = true;

    for (const message of messages) {
        if (!message.isDeleted) {
            isDeletedAll = false;
            break;
        }
    }

    if (isEmpty || isDeletedAll) {
        return (
            <div className="Notification-Title">
                <h1>알림</h1>
            </div>
        );
    } else {
        return (
            <div className="Notification-Title">
                <h1>알림</h1>
                <TrashAll/>
            </div>
        );
    }
}

function TrashAll() {
    const navigate = useNavigate();

    const messages = useAppSelector((state) => state.notification.messages);

    const auth = getAuth();
    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            dispatch(coreActions.setIsLoading(true));

            for (const message of messages) {
                cleanUp(message);
            }

            dispatch(coreActions.setIsLoading(false));
        }
    };

    const cleanUp = async (message: MessageState) => {
        if (message.messageType === "comment") {
            const notificationRef = doc(
                firestore,
                "user",
                auth.currentUser!.uid,
                "notification",
                message.commentId,
            );

            await deleteDoc(notificationRef);

            dispatch(notificationActions.deleteComment(message.commentId));
        } else {
            const notificationRef = doc(
                firestore,
                "user",
                auth.currentUser!.uid,
                "notification",
                message.replyId,
            );

            await deleteDoc(notificationRef);

            dispatch(notificationActions.deleteReply(message.replyId));
        }
    };

    return (
        <div className="Notification-TrashAll">
            <button onClick={onClick}>전체삭제</button>
        </div>
    );
}

function LoadingBar() {
    return (
        <div className="Notification-LoadingBar">
            <LinearProgress/>
        </div>
    );
}

function Messages() {
    const messages = useAppSelector((state) => state.notification.messages);

    const m = messages.map((item, _) => {
        if (!item.isDeleted) {
            if (item.messageType === "comment") {
                return <Comment message={item}/>;
            } else {
                return <Reply message={item}/>;
            }
        }
    });

    return <div className="Notification-Messages">{m}</div>;
}

function Comment(props: { message: MessageState }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate("/notification_view", {
            state: {
                message: props.message,
            },
        });
    };

    if (props.message.authorNickname.length > 5) {
        return (
            <div className="Notification-Comment">
                <Avatar message={props.message}/>
                <button onClick={onClick}>
                    {props.message.authorNickname.slice(0, 5)}...님이 회원님의 게시글에
                    댓글을 남겼습니다.
                </button>
                <Time message={props.message}/>
                <CommentTrash message={props.message}/>
            </div>
        );
    } else {
        return (
            <div className="Notification-Comment">
                <Avatar message={props.message}/>
                <button onClick={onClick}>
                    {props.message.authorNickname}님이 회원님의 게시글에 댓글을
                    남겼습니다.
                </button>
                <Time message={props.message}/>
                <CommentTrash message={props.message}/>
            </div>
        );
    }
}

function CommentTrash(props: { message: MessageState }) {
    const auth = getAuth();
    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            dispatch(coreActions.setIsLoading(true));

            const notificationRef = doc(
                firestore,
                "user",
                auth.currentUser!.uid,
                "notification",
                props.message.commentId,
            );

            await deleteDoc(notificationRef);

            dispatch(notificationActions.deleteComment(props.message.commentId));

            dispatch(coreActions.setIsLoading(false));
        }
    };

    return (
        <div className="Notification-CommentTrash">
            <img src={trash} onClick={onClick}/>
        </div>
    );
}

function Reply(props: { message: MessageState }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate("/notification_view", {
            state: {
                message: props.message,
            },
        });
    };

    if (props.message.authorNickname.length > 5) {
        return (
            <div className="Notification-Reply">
                <Avatar message={props.message}/>
                <button onClick={onClick}>
                    {props.message.authorNickname.slice(0, 5)}...님이 회원님의 댓글에
                    답글을 남겼습니다.
                </button>
                <Time message={props.message}/>
                <ReplyTrash message={props.message}/>
            </div>
        );
    } else {
        return (
            <div className="Notification-Reply">
                <Avatar message={props.message}/>
                <button onClick={onClick}>
                    {props.message.authorNickname}님이 회원님의 댓글에 답글을 남겼습니다.
                </button>
                <Time message={props.message}/>
                <ReplyTrash message={props.message}/>
            </div>
        );
    }
}

function ReplyTrash(props: { message: MessageState }) {
    const auth = getAuth();
    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            dispatch(coreActions.setIsLoading(true));

            const notificationRef = doc(
                firestore,
                "user",
                auth.currentUser!.uid,
                "notification",
                props.message.replyId,
            );

            await deleteDoc(notificationRef);

            dispatch(notificationActions.deleteReply(props.message.replyId));

            dispatch(coreActions.setIsLoading(false));
        }
    };

    return (
        <div className="Notification-ReplyTrash">
            <img src={trash} onClick={onClick}/>
        </div>
    );
}

function Avatar(props: { message: MessageState }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/profile_view/${props.message.authorUid}`);
    };

    if (props.message.authorAvatarUrl !== "") {
        return (
            <div className="Notification-Avatar">
                <img src={props.message.authorAvatarUrl} onClick={onClick}/>
            </div>
        );
    } else {
        return (
            <div className="Notification-Avatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        );
    }
}

function Time(props: { message: MessageState }) {
    const endTimestamp = Date.now();

    const diff = endTimestamp - props.message.timestamp;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 1) {
        return (
            <div className="Notification-Time">
                <p>방금 전</p>
            </div>
        );
    } else if (hours < 1) {
        return (
            <div className="Notification-Time">
                <p>{minutes} 분 전</p>
            </div>
        );
    } else if (days < 1) {
        return (
            <div className="Notification-Time">
                <p>{hours} 시간 전</p>
            </div>
        );
    } else {
        const date = moment(props.message.timestamp);

        return (
            <div className="Notification-Time">
                <p>{date.format("YYYY-MM-DD")}</p>
            </div>
        );
    }
}
