import "./mobile_easy_sign_up.css";
import logoTransparent from "../../asset/image/logo_transparent.png";
import {useAppDispatch, useAppSelector} from "../../module/hook";
import {MobileLoading} from "../loading/mobile_loading";
import {MobileHeader} from "../mobile_header";
import {signUpActions} from "../../module/sign_up";
import {coreActions} from "../../module/core";
import {deleteUser, getAuth, signOut} from "firebase/auth";
import {collection, doc, getDocs, getFirestore, query, setDoc, where,} from "firebase/firestore";
import {useNavigate} from "react-router-dom";

export function MobileEasySignUp() {
    const isLoading = useAppSelector((state) => state.core.isLoading);

    return (
        <div className="MobileEasySignUp">
            {isLoading ? <MobileLoading/> : <Body/>}
        </div>
    );
}

function Body() {
    return (
        <div className={"MobileEasySignUp-Body"}>
            <MobileHeader/>
            <Title/>
            <Background/>
            <Inputs/>
            <Continue/>
        </div>
    );
}

function Title() {
    return (
        <div className="MobileEasySignUp-Title">
            <h1>회원가입(간편)</h1>
        </div>
    );
}

function Background() {
    return (
        <div className="MobileEasySignUp-Background">
            <img src={logoTransparent}/>
        </div>
    );
}

function Inputs() {
    return (
        <div className="MobileEasySignUp-Inputs">
            <NameInput/>
            <NicknameInput/>
        </div>
    );
}

function NameInput() {
    return (
        <div className="MobileEasySignUp-NameInput">
            <NameInputHint/>
            <NameInputForm/>
        </div>
    );
}

function NameInputHint() {
    return (
        <div className="MobileEasySignUp-NameInputHint">
            <h1>아이디</h1>
            <p>*</p>
        </div>
    );
}

function NameInputForm() {
    const name = useAppSelector((state) => state.signUp.name);

    const dispatch = useAppDispatch();

    const onChange = (e: any) => {
        const value = e.target.value
            ? e.target.value.replace(/[^0-9a-zA-Z]+/gi, "")
            : "";

        if (e.target.value !== value) {
            e.target.value = value;
        }

        dispatch(signUpActions.setName(value));
    };

    return (
        <div className="MobileEasySignUp-NameInputForm">
            <form>
                <input
                    type="text"
                    placeholder="영문, 변경 불가능"
                    required
                    value={name}
                    onChange={onChange}
                />
            </form>
        </div>
    );
}

function NicknameInput() {
    return (
        <div className="MobileEasySignUp-NicknameInput">
            <NicknameInputHint/>
            <NicknameInputForm/>
        </div>
    );
}

function NicknameInputHint() {
    return (
        <div className="MobileEasySignUp-NicknameInputHint">
            <h1>닉네임</h1>
            <p>*</p>
        </div>
    );
}

function NicknameInputForm() {
    const nickname = useAppSelector((state) => state.signUp.nickname);

    const dispatch = useAppDispatch();

    const onChange = (e: any) => {
        dispatch(signUpActions.setNickname(e.target.value));
    };

    return (
        <div className="MobileEasySignUp-NicknameInputForm">
            <form>
                <input
                    type="text"
                    placeholder="닉네임"
                    required
                    value={nickname}
                    onChange={onChange}
                />
            </form>
        </div>
    );
}

function Continue() {
    const name = useAppSelector((state) => state.signUp.name);
    const nickname = useAppSelector((state) => state.signUp.nickname);

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (name !== "" && nickname !== "") {
            let isDuplicated = false;
            dispatch(coreActions.setIsLoading(true));

            const qName = query(
                collection(firestore, "user"),
                where("name", "==", name),
            );
            await getDocs(qName).then((snapshot) => {
                if (!snapshot.empty) {
                    alert("해당 아이디는 이미 사용 중입니다.");

                    isDuplicated = true;

                    dispatch(coreActions.setIsLoading(false));
                }
            });

            const qNickname = query(
                collection(firestore, "user"),
                where("nickname", "==", nickname),
            );
            await getDocs(qNickname).then((snapshot) => {
                if (!snapshot.empty) {
                    alert("해당 닉네임은 이미 사용 중입니다.");

                    isDuplicated = true;

                    dispatch(coreActions.setIsLoading(false));
                }
            });

            if (!isDuplicated) {
                const uid = auth.currentUser!.uid;

                const userDocRef = doc(firestore, "user", uid);
                await setDoc(userDocRef, {
                    uid: uid,
                    name: name,
                    nickname: nickname,
                })
                    .then(() => {
                        dispatch(coreActions.setIsLoading(false));

                        navigate("/");
                    })
                    .catch((_) => {
                        deleteUser(auth.currentUser!);
                        signOut(auth);

                        alert("서버와의 통신에 실패하였습니다.");

                        dispatch(coreActions.setIsLoading(false));
                    });
            }
        } else {
            alert("모두 기입해주십시오.");
        }
    };

    if (name !== "" && nickname !== "") {
        return (
            <div className="MobileEasySignUp-ContinueActive">
                <button onClick={onClick}>가입하기</button>
            </div>
        );
    } else {
        return (
            <div className="MobileEasySignUp-ContinueInactive">
                <button onClick={onClick}>가입하기</button>
            </div>
        );
    }
}
