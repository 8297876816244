import {reply, ReplyState} from "../../module/reply";
import {CommentState} from "../../module/comment";
import {DocumentState} from "../../module/document";
import {reportViewActions} from "../../module/report_view";
import {coreActions} from "../../module/core";
import {useAppDispatch, useAppSelector} from "../../module/hook";
import {deleteDoc, doc, getDoc, getFirestore} from "firebase/firestore";
import {useLocation, useNavigate} from "react-router-dom";
import defaultAvatar from "../../asset/image/default_avatar.png";
import React, {useEffect} from "react";
import "./report_view.css";
import moment from "moment";
import trash from "../../asset/image/trash.png";
import comment from "../../asset/image/comment.png";
import hashtag from "../../asset/image/hashtag.png";
import {Loading} from "../loading/loading";
import {AdminHeader} from "../admin_header";
import parse from "html-react-parser";
import up from "../../asset/image/up.png";
import down from "../../asset/image/down.png";
import comingSoon from "../../asset/image/coming_soon.png";
import {getAuth} from "firebase/auth";

export function ReportView() {
    const location = useLocation();
    const navigate = useNavigate();

    const auth = getAuth();
    const firestore = getFirestore();

    const isLoading = useAppSelector((state) => state.core.isLoading);

    const dispatch = useAppDispatch();

    const getDocument = async (documentId: string) => {
        const docRef = doc(firestore, "board", documentId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();

            let authorAvatarUrl = "";
            let authorNickname = "";

            const userDocRef = doc(firestore, "user", docSnap.data()!.authorUid);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
                const userData = userDocSnap.data();

                authorAvatarUrl = userData.avatarUrl || "";
                authorNickname = userData.nickname || "";
            } else {
                return null;
            }

            const upRef = doc(firestore, "metadata", documentId, "up", "summary");
            const downRef = doc(firestore, "metadata", documentId, "down", "summary");
            const commentRef = doc(
                firestore,
                "board",
                documentId,
                "comment",
                "summary",
            );

            const upSnap = await getDoc(upRef);
            const downSnap = await getDoc(downRef);
            const commentSnap = await getDoc(commentRef);

            let numUps = 0;
            let numDowns = 0;
            let numComments = 0;

            if (upSnap.exists()) {
                numUps = upSnap.data().numUps || 0;
            }

            if (downSnap.exists()) {
                numDowns = downSnap.data().numDowns || 0;
            }

            if (commentSnap.exists()) {
                numComments = commentSnap.data().numComments || 0;
            }

            let clickUp = false;
            let clickDown = false;
            let clickBookmark = false;

            if (auth.currentUser !== null) {
                const upDocRef = doc(
                    firestore,
                    "metadata",
                    documentId,
                    "up",
                    auth.currentUser.uid,
                );

                const downDocRef = doc(
                    firestore,
                    "metadata",
                    documentId,
                    "down",
                    auth.currentUser.uid,
                );

                const bookmarkDocRef = doc(
                    firestore,
                    "user",
                    auth.currentUser.uid,
                    "bookmark",
                    documentId,
                );

                await getDoc(upDocRef).then((docSnap) => {
                    if (docSnap.exists()) {
                        clickUp = true;
                    }
                });

                await getDoc(downDocRef).then((docSnap) => {
                    if (docSnap.exists()) {
                        clickDown = true;
                    }
                });

                await getDoc(bookmarkDocRef).then((docSnap) => {
                    if (docSnap.exists()) {
                        clickBookmark = true;
                    }
                });
            }

            const document: DocumentState = {
                documentId: documentId,
                authorUid: data.authorUid || "",
                authorAvatarUrl: authorAvatarUrl,
                authorNickname: authorNickname,
                timestamp: data.timestamp || 0,
                content: data.content || "",
                thumbnailContent: [],
                thumbnailImageSizes: [],
                hashtags: data.hashtags.split(","),
                numUps: numUps,
                numDowns: numDowns,
                numComments: numComments,
                numTokens: 0,
                clickUp: clickUp,
                clickDown: clickDown,
                clickBookmark: clickBookmark,
            };

            return document;
        } else {
            return null;
        }
    };

    const getComment = async (documentId: string, commentId: string) => {
        const docRef = doc(firestore, "board", documentId, "comment", commentId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();

            let authorAvatarUrl = "";
            let authorNickname = "";

            const userDocRef = doc(firestore, "user", docSnap.data()!.authorUid);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
                const userData = userDocSnap.data();

                authorAvatarUrl = userData.avatarUrl || "";
                authorNickname = userData.nickname || "";
            } else {
                return null;
            }

            const upRef = doc(
                firestore,
                "metadata",
                documentId,
                "comment",
                commentId,
                "up",
                "summary",
            );
            const downRef = doc(
                firestore,
                "metadata",
                documentId,
                "comment",
                commentId,
                "down",
                "summary",
            );
            const replyRef = doc(
                firestore,
                "board",
                documentId,
                "comment",
                commentId,
                "reply",
                "summary",
            );

            const upSnap = await getDoc(upRef);
            const downSnap = await getDoc(downRef);
            const replySnap = await getDoc(replyRef);

            let numUps = 0;
            let numDowns = 0;
            let numReplies = 0;

            if (upSnap.exists()) {
                numUps = upSnap.data().numUps || 0;
            }

            if (downSnap.exists()) {
                numDowns = downSnap.data().numDowns || 0;
            }

            if (replySnap.exists()) {
                numReplies = replySnap.data().numReplies || 0;
            }

            let clickUp = false;
            let clickDown = false;

            if (auth.currentUser !== null) {
                const upDocRef = doc(
                    firestore,
                    "metadata",
                    documentId,
                    "comment",
                    commentId,
                    "up",
                    auth.currentUser.uid,
                );

                const downDocRef = doc(
                    firestore,
                    "metadata",
                    documentId,
                    "comment",
                    commentId,
                    "down",
                    auth.currentUser.uid,
                );

                await getDoc(upDocRef).then((docSnap) => {
                    if (docSnap.exists()) {
                        clickUp = true;
                    }
                });

                await getDoc(downDocRef).then((docSnap) => {
                    if (docSnap.exists()) {
                        clickDown = true;
                    }
                });
            }

            const comment: CommentState = {
                commentId: commentId,
                authorUid: data.authorUid || "",
                authorAvatarUrl: authorAvatarUrl,
                authorNickname: authorNickname,
                timestamp: data.timestamp || 0,
                content: data.content || "",
                numUps: numUps,
                numDowns: numDowns,
                numReplies: numReplies,
                numTokens: 0,
                clickUp: clickUp,
                clickDown: clickDown,
            };

            return comment;
        } else {
            return null;
        }
    };

    const getReply = async (
        documentId: string,
        commentId: string,
        replyId: string,
    ) => {
        const docRef = doc(
            firestore,
            "board",
            documentId,
            "comment",
            commentId,
            "reply",
            replyId,
        );
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();

            let authorAvatarUrl = "";
            let authorNickname = "";

            const userDocRef = doc(firestore, "user", docSnap.data()!.authorUid);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
                const userData = userDocSnap.data();

                authorAvatarUrl = userData.avatarUrl || "";
                authorNickname = userData.nickname || "";
            } else {
                return null;
            }

            const upRef = doc(
                firestore,
                "metadata",
                documentId,
                "comment",
                commentId,
                "reply",
                replyId,
                "up",
                "summary",
            );
            const downRef = doc(
                firestore,
                "metadata",
                documentId,
                "comment",
                commentId,
                "reply",
                replyId,
                "down",
                "summary",
            );

            const upSnap = await getDoc(upRef);
            const downSnap = await getDoc(downRef);

            let numUps = 0;
            let numDowns = 0;
            let numComments = 0;

            if (upSnap.exists()) {
                numUps = upSnap.data().numUps || 0;
            }

            if (downSnap.exists()) {
                numDowns = downSnap.data().numDowns || 0;
            }

            let clickUp = false;
            let clickDown = false;

            if (auth.currentUser !== null) {
                const upDocRef = doc(
                    firestore,
                    "metadata",
                    documentId,
                    "comment",
                    commentId,
                    "reply",
                    replyId,
                    "up",
                    auth.currentUser.uid,
                );

                const downDocRef = doc(
                    firestore,
                    "metadata",
                    documentId,
                    "comment",
                    commentId,
                    "reply",
                    replyId,
                    "down",
                    auth.currentUser.uid,
                );

                await getDoc(upDocRef).then((docSnap) => {
                    if (docSnap.exists()) {
                        clickUp = true;
                    }
                });

                await getDoc(downDocRef).then((docSnap) => {
                    if (docSnap.exists()) {
                        clickDown = true;
                    }
                });
            }

            const reply: ReplyState = {
                replyId: replyId,
                authorUid: data.authorUid || "",
                authorAvatarUrl: authorAvatarUrl,
                authorNickname: authorNickname,
                timestamp: data.timestamp || 0,
                content: data.content || "",
                numUps: numUps,
                numDowns: numDowns,
                clickUp: clickUp,
                clickDown: clickDown,
            };

            return reply;
        } else {
            return null;
        }
    };

    const getMessage = async () => {
        dispatch(coreActions.setIsLoading(true));

        if (location.state.commentId === "" && location.state.replyId === "") {
            const document = await getDocument(location.state.documentId);

            if (document !== null) {
                dispatch(reportViewActions.setDocument(document));
            } else {
                await deleteDoc(doc(firestore, "report", location.state.reportId));

                navigate("/admin");
            }
        } else if (location.state.replyId === "") {
            const comment = await getComment(
                location.state.documentId,
                location.state.commentId,
            );

            if (comment !== null) {
                dispatch(reportViewActions.setComment(comment));
            } else {
                await deleteDoc(doc(firestore, "report", location.state.reportId));

                navigate("/admin");
            }
        } else {
            const reply = await getReply(
                location.state.documentId,
                location.state.commentId,
                location.state.replyId,
            );

            if (reply !== null) {
                dispatch(reportViewActions.setReply(reply));
            } else {
                await deleteDoc(doc(firestore, "report", location.state.reportId));

                navigate("/admin");
            }
        }

        dispatch(coreActions.setIsLoading(false));
    };

    useEffect(() => {
        dispatch(coreActions.setFocus("report"));

        dispatch(reportViewActions.setReportId(location.state.reportId));
        dispatch(reportViewActions.setDocumentId(location.state.documentId));
        dispatch(reportViewActions.setCommentId(location.state.commentId));
        dispatch(reportViewActions.setReplyId(location.state.replyId));
        getMessage();
    }, []);

    return <div className="ReportView">{isLoading ? <Loading/> : <Body/>}</div>;
}

function Body() {
    return (
        <div className="ReportView-Body">
            <AdminHeader/>
            <Main/>
        </div>
    );
}

function Main() {
    const documentId = useAppSelector((state) => state.reportView.documentId);
    const commentId = useAppSelector((state) => state.reportView.commentId);
    const replyId = useAppSelector((state) => state.reportView.replyId);

    if (commentId === "" && replyId === "") {
        return (
            <div className="ReportView-Main">
                <Document/>
            </div>
        );
    } else if (replyId === "") {
        return (
            <div className="ReportView-Main">
                <Comment/>
            </div>
        );
    } else {
        return (
            <div className="ReportView-Main">
                <Reply/>
            </div>
        );
    }
}

function Document() {
    const document = useAppSelector((state) => state.reportView.document);

    if (
        document !== null &&
        document!.hashtags.length > 0 &&
        document!.hashtags[0] !== ""
    ) {
        return (
            <div className="ReportView-Document">
                <DocumentHeader/>
                <DocumentContent/>
                <Hashtags/>
                <DocumentSummary/>
            </div>
        );
    } else {
        return (
            <div className="ReportView-Document">
                <DocumentHeader/>
                <DocumentContent/>
                <DocumentSummary/>
            </div>
        );
    }
}

function DocumentHeader() {
    return (
        <div className="ReportView-DocumentHeader">
            <DocumentAvatar/>
            <DocumentNickname/>
            <DocumentTime/>
            <DocumentTrash/>
        </div>
    );
}

function DocumentAvatar() {
    const document = useAppSelector((state) => state.reportView.document);

    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/profile_view/${document!.authorUid}`);
    };

    if (document !== null && document!.authorAvatarUrl !== "") {
        return (
            <div className="ReportView-DocumentAvatar">
                <img src={document!.authorAvatarUrl} onClick={onClick}/>
            </div>
        );
    } else {
        return (
            <div className="ReportView-DocumentAvatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        );
    }
}

function DocumentNickname() {
    const navigate = useNavigate();

    const document = useAppSelector((state) => state.reportView.document);

    const onClick = () => {
        navigate(`/profile_view/${document!.authorUid}`);
    };

    if (document !== null && document!.authorUid !== "") {
        return (
            <div className="ReportView-DocumentNickname">
                <button onClick={onClick}>{document!.authorNickname}</button>
            </div>
        );
    } else {
        return (
            <div className="ReportView-DocumentNickname">
                <button onClick={onClick}>닉네임 없음</button>
            </div>
        );
    }
}

function DocumentTime() {
    const document = useAppSelector((state) => state.reportView.document);

    let timestamp;
    if (document !== null) {
        timestamp = document.timestamp;
    } else {
        timestamp = 0;
    }

    const endTimestamp = Date.now();

    let diff = endTimestamp - timestamp || 0;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 1) {
        return (
            <div className="ReportView-DocumentTime">
                <p>방금 전</p>
            </div>
        );
    } else if (hours < 1) {
        return (
            <div className="ReportView-DocumentTime">
                <p>{minutes} 분 전</p>
            </div>
        );
    } else if (days < 1) {
        return (
            <div className="ReportView-DocumentTime">
                <p>{hours} 시간 전</p>
            </div>
        );
    } else {
        const date = moment(timestamp || 0);

        return (
            <div className="ReportView-DocumentTime">
                <p>{date.format("YYYY-MM-DD")}</p>
            </div>
        );
    }
}

function DocumentTrash() {
    const navigate = useNavigate();

    const reportId = useAppSelector((state) => state.reportView.reportId);
    const document = useAppSelector((state) => state.reportView.document);

    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    const onClick = async () => {
        if (reportId !== "" && document !== null) {
            const answer = window.confirm("정말 삭제하시겠습니까?");

            if (answer) {
                dispatch(coreActions.setIsLoading(true));

                await deleteDoc(doc(firestore, "metadata", document.documentId));

                await deleteDoc(
                    doc(
                        firestore,
                        "user",
                        document.authorUid,
                        "post",
                        document.documentId,
                    ),
                );

                for (const hashtag of document.hashtags) {
                    if (hashtag !== "") {
                        await deleteDoc(
                            doc(firestore, "hashtag", hashtag, "post", document.documentId),
                        );
                    }
                }

                await deleteDoc(doc(firestore, "board", document.documentId));

                await deleteDoc(doc(firestore, "report", reportId));

                navigate("/admin");

                dispatch(coreActions.setIsLoading(false));
            }
        }
    };

    return (
        <div className="ReportView-DocumentTrash">
            <button onClick={onClick}>
                <img src={trash}/>
            </button>
        </div>
    );
}

function DocumentContent() {
    const document = useAppSelector((state) => state.reportView.document);

    let parsedContent;
    if (document !== null) {
        parsedContent = parse(document!.content);
    } else {
        parsedContent = parse("");
    }

    return <div className="ReportView-Content">{parsedContent}</div>;
}

function Hashtags() {
    const document = useAppSelector((state) => state.reportView.document);

    return (
        <div className="ReportView-Hashtags">
            {
                <ul>
                    {document!.hashtags.map((hashtag, index) => (
                        <li key={index}>
                            <Hashtag hashtag={hashtag}/>
                        </li>
                    ))}
                </ul>
            }
        </div>
    );
}

function Hashtag(props: { hashtag: string }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/search_query/${props.hashtag.slice(1)}`);
    };

    return (
        <div className="ReportView-Hashtag">
            <img src={hashtag}/>
            <button className="ReportView-Hashtag-tag" onClick={onClick}>
                {props.hashtag.slice(1)}
            </button>
        </div>
    );
}

function DocumentSummary() {
    return (
        <div className="ReportView-DocumentSummary">
            <DocumentNumUps/>
            <DocumentNumDowns/>
            <DocumentNumComments/>
            <DocumentNumTokens/>
        </div>
    );
}

function DocumentNumUps() {
    const document = useAppSelector((state) => state.reportView.document);

    let numUps;
    if (document !== null) {
        numUps = document.numUps;
    } else {
        numUps = 0;
    }

    return (
        <div className="ReportView-DocumentNumUps">
            <button>
                <img src={up}/>
                <p>{numUps}</p>
            </button>
        </div>
    );
}

function DocumentNumDowns() {
    const document = useAppSelector((state) => state.reportView.document);

    let numDowns;
    if (document !== null) {
        numDowns = document.numDowns;
    } else {
        numDowns = 0;
    }

    return (
        <div className="ReportView-DocumentNumDowns">
            <button>
                <img src={down}/>
                <p>{numDowns}</p>
            </button>
        </div>
    );
}

function DocumentNumComments() {
    const document = useAppSelector((state) => state.reportView.document);

    let numComments;
    if (document !== null) {
        numComments = document.numComments;
    } else {
        numComments = 0;
    }

    return (
        <div className="ReportView-DocumentNumComments">
            <button>
                <img src={comment}/>
                <p>{numComments}</p>
            </button>
        </div>
    );
}

function DocumentNumTokens() {
    return (
        <div className="ReportView-DocumentNumTokens">
            <button>
                <p>TOKEN</p>
                <ComingSoon/>
            </button>
        </div>
    );
}

function ComingSoon() {
    return (
        <div className="ReportView-ComingSoon">
            <button>
                <img src={comingSoon}/>
            </button>
        </div>
    );
}

function Comment() {
    return (
        <div className="ReportView-Comment">
            <CommentHeader/>
            <CommentBody/>
            <CommentSummary/>
        </div>
    );
}

function CommentHeader() {
    return (
        <div className="ReportView-CommentHeader">
            <CommentAvatar/>
            <CommentNickname/>
            <CommentTime/>
            <CommentTrash/>
        </div>
    );
}

function CommentAvatar() {
    const comment = useAppSelector((state) => state.reportView.comment);

    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/profile_view/${comment!.authorUid}`);
    };

    if (comment !== null && comment.authorAvatarUrl !== "") {
        return (
            <div className="ReportView-CommentAvatar">
                <img src={comment.authorAvatarUrl} onClick={onClick}/>
            </div>
        );
    } else {
        return (
            <div className="ReportView-CommentAvatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        );
    }
}

function CommentTime() {
    const comment = useAppSelector((state) => state.reportView.comment);

    let timestamp;
    if (comment !== null) {
        timestamp = comment.timestamp;
    } else {
        timestamp = 0;
    }

    const endTimestamp = Date.now();

    const diff = endTimestamp - timestamp;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 1) {
        return (
            <div className="ReportView-CommentTime">
                <p>방금 전</p>
            </div>
        );
    } else if (hours < 1) {
        return (
            <div className="ReportView-CommentTime">
                <p>{minutes} 분 전</p>
            </div>
        );
    } else if (days < 1) {
        return (
            <div className="ReportView-CommentTime">
                <p>{hours} 시간 전</p>
            </div>
        );
    } else {
        const date = moment(timestamp);

        return (
            <div className="ReportView-CommentTime">
                <p>{date.format("YYYY-MM-DD")}</p>
            </div>
        );
    }
}

function CommentNickname() {
    const comment = useAppSelector((state) => state.reportView.comment);

    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/profile_view/${comment!.authorUid}`);
    };

    if (comment !== null && comment.authorNickname !== "") {
        return (
            <div className="ReportView-CommentNickname">
                <button onClick={onClick}>{comment.authorNickname}</button>
            </div>
        );
    } else {
        return (
            <div className="ReportView-CommentNickname">
                <button onClick={onClick}>닉네임 없음</button>
            </div>
        );
    }
}

function CommentTrash() {
    const location = useLocation();
    const navigate = useNavigate();

    const reportId = useAppSelector((state) => state.reportView.reportId);
    const comment = useAppSelector((state) => state.reportView.comment);

    const auth = getAuth();
    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    const onClick = async () => {
        if (
            reportId !== "" &&
            location.state.documentId !== null &&
            comment !== null
        ) {
            const answer = window.confirm("정말 삭제하시겠습니까?");

            if (answer) {
                dispatch(coreActions.setIsLoading(true));

                await deleteDoc(
                    doc(
                        firestore,
                        "metadata",
                        location.state.documentId,
                        "comment",
                        comment.commentId,
                    ),
                );

                await deleteDoc(
                    doc(
                        firestore,
                        "board",
                        location.state.documentId,
                        "comment",
                        comment.commentId,
                    ),
                );

                await deleteDoc(doc(firestore, "report", reportId));

                dispatch(coreActions.setIsLoading(false));

                navigate("/admin");
            }
        }
    };

    return (
        <div className="ReportView-CommentTrash">
            <button onClick={onClick}>
                <img src={trash}/>
            </button>
        </div>
    );
}

function CommentBody() {
    const comment = useAppSelector((state) => state.reportView.comment);

    let content;
    if (comment !== null) {
        content = comment.content;
    } else {
        content = "";
    }

    return (
        <div className="ReportView-CommentBody">
            <p>{content}</p>
        </div>
    );
}

function CommentSummary() {
    return (
        <div className="ReportView-CommentSummary">
            <CommentNumUps/>
            <CommentNumDowns/>
            <CommentNumReplies/>
            <CommentNumTokens/>
        </div>
    );
}

function CommentNumUps() {
    const comment = useAppSelector((state) => state.reportView.comment);

    let numUps;
    if (comment !== null) {
        numUps = comment.numUps;
    } else {
        numUps = 0;
    }

    return (
        <div className="ReportView-CommentNumUps">
            <button>
                <img src={up}/>
                <p>{numUps}</p>
            </button>
        </div>
    );
}

function CommentNumDowns() {
    const comment = useAppSelector((state) => state.reportView.comment);

    let numDowns;
    if (comment !== null) {
        numDowns = comment.numDowns;
    } else {
        numDowns = 0;
    }

    return (
        <div className="ReportView-CommentNumDowns">
            <button>
                <img src={down}/>
                <p>{numDowns}</p>
            </button>
        </div>
    );
}

function CommentNumReplies() {
    const c = useAppSelector((state) => state.reportView.comment);

    let numReplies;
    if (c !== null) {
        numReplies = c.numReplies;
    } else {
        numReplies = 0;
    }

    return (
        <div className="ReportView-CommentNumReplies">
            <button>
                <img src={comment}/>
                <p>{numReplies}</p>
            </button>
        </div>
    );
}

function CommentNumTokens() {
    return (
        <div className="ReportView-CommentNumTokens">
            <button>
                <p>TOKEN</p>
                <ComingSoon/>
            </button>
        </div>
    );
}

function Reply() {
    return (
        <div className="ReportView-Reply">
            <ReplyHeader/>
            <ReplyBody/>
            <ReplySummary/>
        </div>
    );
}

function ReplyHeader() {
    return (
        <div className="ReportView-ReplyHeader">
            <ReplyAvatar/>
            <ReplyNickname/>
            <ReplyTime/>
            <ReplyTrash/>
        </div>
    );
}

function ReplyAvatar() {
    const navigate = useNavigate();

    const reply = useAppSelector((state) => state.reportView.reply);

    const onClick = () => {
        navigate(`/profile_view/${reply!.authorUid}`);
    };

    if (reply !== null && reply.authorAvatarUrl !== "") {
        return (
            <div className="ReportView-ReplyAvatar">
                <img src={reply.authorAvatarUrl} onClick={onClick}/>
            </div>
        );
    } else {
        return (
            <div className="ReportView-ReplyAvatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        );
    }
}

function ReplyTime() {
    const reply = useAppSelector((state) => state.reportView.reply);

    let timestamp;
    if (reply !== null) {
        timestamp = reply.timestamp;
    } else {
        timestamp = 0;
    }

    const endTimestamp = Date.now();

    const diff = endTimestamp - timestamp;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 1) {
        return (
            <div className="ReportView-ReplyTime">
                <p>방금 전</p>
            </div>
        );
    } else if (hours < 1) {
        return (
            <div className="ReportView-ReplyTime">
                <p>{minutes} 분 전</p>
            </div>
        );
    } else if (days < 1) {
        return (
            <div className="ReportView-ReplyTime">
                <p>{hours} 시간 전</p>
            </div>
        );
    } else {
        const date = moment(timestamp);

        return (
            <div className="ReportView-ReplyTime">
                <p>{date.format("YYYY-MM-DD")}</p>
            </div>
        );
    }
}

function ReplyNickname() {
    const reply = useAppSelector((state) => state.reportView.reply);

    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/profile_view/${reply!.authorUid}`);
    };

    if (reply !== null && reply.authorNickname !== "") {
        return (
            <div className="ReportView-ReplyNickname">
                <button onClick={onClick}>{reply.authorNickname}</button>
            </div>
        );
    } else {
        return (
            <div className="ReportView-ReplyNickname">
                <button onClick={onClick}>닉네임 없음</button>
            </div>
        );
    }
}

function ReplyTrash() {
    const location = useLocation();
    const navigate = useNavigate();

    const reportId = useAppSelector((state) => state.reportView.reportId);
    const reply = useAppSelector((state) => state.reportView.reply);

    const auth = getAuth();
    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    const onClick = async () => {
        if (
            reportId !== "" &&
            location.state.documentId !== "" &&
            location.state.commentId !== "" &&
            reply !== null
        ) {
            const answer = window.confirm("정말 삭제하시겠습니까?");

            if (answer) {
                dispatch(coreActions.setIsLoading(true));

                await deleteDoc(
                    doc(
                        firestore,
                        "metadata",
                        location.state.documentId,
                        "comment",
                        location.state.commentId,
                        "reply",
                        reply.replyId,
                    ),
                );

                await deleteDoc(
                    doc(
                        firestore,
                        "board",
                        location.state.documentId,
                        "comment",
                        location.state.commentId,
                        "reply",
                        reply.replyId,
                    ),
                );

                await deleteDoc(doc(firestore, "report", reportId));

                dispatch(coreActions.setIsLoading(false));

                navigate("/admin");
            }
        }
    };

    return (
        <div className="ReportView-ReplyTrash">
            <button onClick={onClick}>
                <img src={trash}/>
            </button>
        </div>
    );
}

function ReplyBody() {
    const reply = useAppSelector((state) => state.reportView.reply);

    let content;
    if (reply !== null) {
        content = reply.content;
    } else {
        content = "";
    }

    return (
        <div className="ReportView-ReplyBody">
            <p>{content}</p>
        </div>
    );
}

function ReplySummary() {
    return (
        <div className="ReportView-ReplySummary">
            <ReplyNumUps/>
            <ReplyNumDowns/>
        </div>
    );
}

function ReplyNumUps() {
    const reply = useAppSelector((state) => state.reportView.reply);

    let numUps;
    if (reply !== null) {
        numUps = reply.numUps;
    } else {
        numUps = 0;
    }

    return (
        <div className="ReportView-ReplyNumUps">
            <button>
                <img src={up}/>
                <p>{numUps}</p>
            </button>
        </div>
    );
}

function ReplyNumDowns() {
    const reply = useAppSelector((state) => state.reportView.reply);

    let numDowns;
    if (reply !== null) {
        numDowns = reply.numDowns;
    } else {
        numDowns = 0;
    }

    return (
        <div className="ReportView-ReplyNumDowns">
            <button>
                <img src={down}/>
                <p>{numDowns}</p>
            </button>
        </div>
    );
}
