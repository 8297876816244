import {DocumentState} from "../../module/document";
import {eventActions} from "../../module/event";
import {useAppDispatch, useAppSelector} from "../../module/hook";
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getFirestore,
    limit,
    onSnapshot,
    orderBy,
    query,
    startAfter,
} from "firebase/firestore";
import {useNavigate} from "react-router-dom";
import {useBottomScrollListener} from "react-bottom-scroll-listener";
import React, {useEffect, useState} from "react";
import "./event.css";
import {Header} from "../header";
import {Sidebar} from "../sidebar";
import parse from "html-react-parser";
import {getAuth} from "firebase/auth";
import {RightSidebar} from "../right_sidebar";
import LinearProgress from "@mui/material/LinearProgress";
import {Loading} from "../loading/loading";
import {coreActions} from "../../module/core";
import trash from "../../asset/image/trash.png";
import {PenNib} from "@phosphor-icons/react";

export function Event() {
    const auth = getAuth();
    const firestore = getFirestore();

    const isLoading = useAppSelector((state) => state.core.isLoading);
    const documents = useAppSelector((state) => state.event.documents);

    const dispatch = useAppDispatch();

    const [images, setImages] = useState(new Map());
    const [timestamp, setTimestamp] = useState(Date.now());
    const [lastVisible, setLastVisible] = useState<any>(undefined);

    const getDocuments = async () => {
        let q;
        if (lastVisible === -1) {
            return;
        } else if (lastVisible !== undefined) {
            q = query(
                collection(firestore, "event"),
                orderBy("timestamp", "desc"),
                limit(10),
                startAfter(lastVisible),
            );
        } else {
            dispatch(eventActions.resetFetching());
            dispatch(eventActions.resetFetched());

            q = query(
                collection(firestore, "event"),
                orderBy("timestamp", "desc"),
                limit(10),
            );
        }

        onSnapshot(q, async (snapshot) => {
            const newDocuments = snapshot.docs.map((elem) => elem.data());

            dispatch(eventActions.incrementFetching(newDocuments.length));

            for (const elem of newDocuments) {
                getDocument(elem);
            }

            if (snapshot.docs.length === 0) {
                setLastVisible(-1);
            } else {
                setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
            }
        });
    };

    const getDocument = async (elem: any) => {
        let authorAvatarUrl = "";
        let authorNickname = "";

        const userDocRef = doc(firestore, "user", elem.authorUid);

        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
            const data = docSnap.data();
            authorAvatarUrl = data.avatarUrl || "";
            authorNickname = data.nickname || "";
        } else {
            dispatch(eventActions.appendBlocked(elem.documentId));
            dispatch(eventActions.incrementFetched(1));
            return;
        }

        const document: DocumentState = {
            documentId: elem.documentId,
            authorUid: elem.authorUid,
            authorAvatarUrl: authorAvatarUrl,
            authorNickname: authorNickname,
            timestamp: elem.timestamp,
            content: elem.content,
            thumbnailContent: [],
            thumbnailImageSizes: [],
            hashtags: [],
            numUps: 0,
            numDowns: 0,
            numComments: 0,
            numTokens: 0,
            clickUp: false,
            clickDown: false,
            clickBookmark: false,
        };

        if (elem.timestamp < timestamp) {
            dispatch(eventActions.appendDocument(document));
        }

        dispatch(eventActions.incrementFetched(1));
    };

    useEffect(() => {
        dispatch(coreActions.setFocus("event"));

        dispatch(eventActions.resetBlocked());
        dispatch(eventActions.resetDocuments());

        setLastVisible(undefined);

        getDocuments();
    }, []);

    useBottomScrollListener(getDocuments);

    return (
        <div className="Event">
            {isLoading ? <Loading/> : <Body documents={documents}/>}
        </div>
    );
}

function Body(props: { documents: DocumentState[] }) {
    const fetching = useAppSelector((state) => state.event.fetching);
    const fetched = useAppSelector((state) => state.event.fetched);

    if (fetched < fetching) {
        return (
            <div className="Event-Body">
                <Header/>
                <Sidebar/>
                <Title/>
                <LoadingBar/>
                <Documents documents={props.documents}/>
                <RightSidebar/>
            </div>
        );
    } else {
        return (
            <div className="Event-Body">
                <Header/>
                <Sidebar/>
                <Title/>
                <Documents documents={props.documents}/>
                <RightSidebar/>
            </div>
        );
    }
}

function Title() {
    const isAdmin = useAppSelector((state) => state.core.isAdmin);

    if (isAdmin) {
        return (
            <div className="Event-Title">
                <h1>이벤트</h1>
                <Write/>
            </div>
        );
    } else {
        return (
            <div className="Event-Title">
                <h1>이벤트</h1>
            </div>
        );
    }
}

function Write() {
    const navigate = useNavigate();

    const onClick = () => {
        navigate("/event_write");
    };

    return (
        <div className="Event-Write">
            <button onClick={onClick}>
                <PenNib size={24}/>
            </button>
        </div>
    );
}

function LoadingBar() {
    return (
        <div className="Event-LoadingBar">
            <LinearProgress/>
        </div>
    );
}

function Documents(props: { documents: DocumentState[] }) {
    const documents = props.documents.map((item, _) => {
        return <Document document={item}/>;
    });

    return <div className="Event-Documents">{documents}</div>;
}

function Document(props: { document: DocumentState }) {
    const isAdmin = useAppSelector((state) => state.core.isAdmin);

    if (isAdmin) {
        return (
            <div className="Event-Document">
                <DocumentHeader document={props.document}/>
                <Content document={props.document}/>
            </div>
        );
    } else {
        return (
            <div className="Event-Document">
                <Content document={props.document}/>
            </div>
        );
    }
}

function DocumentHeader(props: { document: DocumentState }) {
    return (
        <div className="Event-DocumentHeader">
            <Delete document={props.document}/>
        </div>
    );
}

function Delete(props: { document: DocumentState }) {
    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    const onClick = async () => {
        const answer = window.confirm("정말 삭제하시겠습니까?");

        if (answer) {
            dispatch(coreActions.setIsLoading(true));

            await deleteDoc(doc(firestore, "event", props.document.documentId));

            dispatch(eventActions.removeDocument(props.document));

            dispatch(coreActions.setIsLoading(false));
        }
    };

    return (
        <div className="Event-Delete">
            <img onClick={onClick} src={trash}/>
        </div>
    );
}

function Content(props: { document: DocumentState }) {
    const parsedContent = parse(props.document.content);

    return <div className="Event-Content">{parsedContent}</div>;
}
