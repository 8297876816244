import {ReportMessageState} from "../../module/report_message";
import {useAppDispatch, useAppSelector} from "../../module/hook";
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    limit,
    onSnapshot,
    orderBy,
    query,
    startAfter,
    where,
} from "firebase/firestore";
import {useNavigate} from "react-router-dom";
import {useBottomScrollListener} from "react-bottom-scroll-listener";
import React, {useEffect, useState} from "react";
import "./admin.css";
import {adminActions} from "../../module/admin";
import {AdminHeader} from "../admin_header";
import defaultAvatar from "../../asset/image/default_avatar.png";
import moment from "moment/moment";
import {Loading} from "../loading/loading";
import {coreActions} from "../../module/core";
import trash from "../../asset/image/trash.png";

export function Admin() {
    const firestore = getFirestore();

    const isAdmin = useAppSelector((state) => state.core.isAdmin);
    const isLoading = useAppSelector((state) => state.core.isLoading);

    const dispatch = useAppDispatch();

    const [lastVisible, setLastVisible] = useState<any>(undefined);

    const getReportMessages = async () => {
        let q;
        if (lastVisible === -1) {
            return;
        } else if (lastVisible !== undefined) {
            q = query(
                collection(firestore, "report"),
                orderBy("timestamp", "desc"),
                limit(10),
                startAfter(lastVisible),
            );
        } else {
            q = query(
                collection(firestore, "report"),
                orderBy("timestamp", "desc"),
                limit(10),
            );
        }

        onSnapshot(q, async (snapshot) => {
            const newDocuments = snapshot.docs.map((elem) => elem.data());

            for (const elem of newDocuments) {
                getReportMessage(elem);
            }

            if (snapshot.docs.length === 0) {
                setLastVisible(-1);
            } else {
                setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
            }
        });
    };

    const getReportMessage = async (elem: any) => {
        let authorAvatarUrl = "";
        let authorNickname = "";

        const docRef = doc(firestore, "user", elem.authorUid);
        await getDoc(docRef).then((docSnap) => {
            if (docSnap.exists() && docSnap.data().hasOwnProperty("avatarUrl")) {
                authorAvatarUrl = docSnap.data()["avatarUrl"];
            }

            if (docSnap.exists() && docSnap.data().hasOwnProperty("nickname")) {
                authorNickname = docSnap.data()["nickname"];
            }
        });

        const reportMessage: ReportMessageState = {
            reportId: elem.reportId,
            documentId: elem.documentId,
            commentId: elem.commentId,
            replyId: elem.replyId,
            dislikeChecked: elem.dislikeChecked,
            harmfulChecked: elem.harmfulChecked,
            adChecked: elem.adChecked,
            pornChecked: elem.pornChecked,
            etcChecked: elem.etcChecked,
            etcContent: elem.etcContent,
            authorUid: elem.authorUid,
            authorAvatarUrl: authorAvatarUrl,
            authorNickname: authorNickname,
            timestamp: elem.timestamp,
        };

        if (reportMessage.commentId === "" && reportMessage.replyId === "") {
            dispatch(adminActions.appendReportDocumentMessage(reportMessage));
        } else if (reportMessage.replyId === "") {
            dispatch(adminActions.appendReportCommentMessage(reportMessage));
        } else {
            dispatch(adminActions.appendReportReplyMessage(reportMessage));
        }
    };

    useEffect(() => {
        dispatch(coreActions.setFocus("admin"));

        dispatch(adminActions.setFocus("documents"));
        dispatch(adminActions.resetReportDocumentMessages());
        dispatch(adminActions.resetReportCommentMessages());
        dispatch(adminActions.resetReportReplyMessages());
        dispatch(adminActions.setName(""));
        setLastVisible(undefined);

        getReportMessages();
    }, []);

    useBottomScrollListener(getReportMessages);

    if (isAdmin) {
        return <div className="Admin">{isLoading ? <Loading/> : <Body/>}</div>;
    } else {
        return <div className="Admin"></div>;
    }
}

function Body() {
    const focus = useAppSelector((state) => state.admin.focus);

    if (focus === "documents") {
        return (
            <div className="Admin-Body">
                <AdminHeader/>
                <ReportDocumentMessages/>
            </div>
        );
    } else if (focus === "comments") {
        return (
            <div className="Admin-Body">
                <AdminHeader/>
                <ReportCommentMessages/>
            </div>
        );
    } else if (focus === "replies") {
        return (
            <div className="Admin-Body">
                <AdminHeader/>
                <ReportReplyMessages/>
            </div>
        );
    } else {
        return (
            <div className="Admin-Body">
                <AdminHeader/>
                <SearchBody/>
            </div>
        );
    }
}

function ReportDocumentMessages() {
    const reportDocumentMessages = useAppSelector(
        (state) => state.admin.reportDocumentMessages,
    );

    const r = reportDocumentMessages.map((item, _) => {
        return <ReportMessage reportMessage={item}/>;
    });

    if (reportDocumentMessages.length > 0) {
        return <div className="Admin-ReportDocumentMessages">{r}</div>;
    } else {
        return (
            <div className="Admin-ReportDocumentMessages-Void">
                <p>신고된 게시글이 없습니다.</p>
            </div>
        );
    }
}

function ReportCommentMessages() {
    const reportCommentMessages = useAppSelector(
        (state) => state.admin.reportCommentMessages,
    );

    const r = reportCommentMessages.map((item, _) => {
        return <ReportMessage reportMessage={item}/>;
    });

    if (reportCommentMessages.length > 0) {
        return <div className="Admin-ReportCommentMessages">{r}</div>;
    } else {
        return (
            <div className="Admin-ReportCommentMessages-Void">
                <p>신고된 댓글이 없습니다.</p>
            </div>
        );
    }
}

function ReportReplyMessages() {
    const reportReplyMessages = useAppSelector(
        (state) => state.admin.reportReplyMessages,
    );

    const r = reportReplyMessages.map((item, _) => {
        return <ReportMessage reportMessage={item}/>;
    });

    if (reportReplyMessages.length > 0) {
        return <div className="Admin-ReportReplyMessages">{r}</div>;
    } else {
        return (
            <div className="Admin-ReportReplyMessages-Void">
                <p>신고된 답글이 없습니다.</p>
            </div>
        );
    }
}

function ReportMessage(props: { reportMessage: ReportMessageState }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate("/report_view", {
            state: {
                reportId: props.reportMessage.reportId,
                documentId: props.reportMessage.documentId,
                commentId: props.reportMessage.commentId,
                replyId: props.reportMessage.replyId,
            },
        });
    };

    return (
        <div className="Admin-ReportMessage">
            <Header reportMessage={props.reportMessage}/>
            <button onClick={onClick}>
                <DislikeChecked reportMessage={props.reportMessage}/>
                <HarmfulChecked reportMessage={props.reportMessage}/>
                <AdChecked reportMessage={props.reportMessage}/>
                <PornChecked reportMessage={props.reportMessage}/>
                <EtcChecked reportMessage={props.reportMessage}/>
                <EtcInputBody reportMessage={props.reportMessage}/>
            </button>
        </div>
    );
}

function Header(props: { reportMessage: ReportMessageState }) {
    return (
        <div className="Admin-Header">
            <Avatar reportMessage={props.reportMessage}/>
            <Nickname reportMessage={props.reportMessage}/>
            <Time reportMessage={props.reportMessage}/>
            <Trash reportMessage={props.reportMessage}/>
        </div>
    );
}

function Avatar(props: { reportMessage: ReportMessageState }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/profile_view/${props.reportMessage.authorUid}`);
    };

    if (props.reportMessage.authorAvatarUrl !== "") {
        return (
            <div className="Admin-Avatar">
                <img src={props.reportMessage.authorAvatarUrl} onClick={onClick}/>
            </div>
        );
    } else {
        return (
            <div className="Admin-Avatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        );
    }
}

function Nickname(props: { reportMessage: ReportMessageState }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/profile_view/${props.reportMessage.authorUid}`);
    };

    if (props.reportMessage.authorNickname !== "") {
        return (
            <div className="Admin-Nickname">
                <button onClick={onClick}>{props.reportMessage.authorNickname}</button>
            </div>
        );
    } else {
        return (
            <div className="Admin-Nickname">
                <button onClick={onClick}>닉네임 없음</button>
            </div>
        );
    }
}

function Time(props: { reportMessage: ReportMessageState }) {
    const endTimestamp = Date.now();

    const diff = endTimestamp - props.reportMessage.timestamp;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 1) {
        return (
            <div className="Admin-Time">
                <p>방금 전</p>
            </div>
        );
    } else if (hours < 1) {
        return (
            <div className="Admin-Time">
                <p>{minutes} 분 전</p>
            </div>
        );
    } else if (days < 1) {
        return (
            <div className="Admin-Time">
                <p>{hours} 시간 전</p>
            </div>
        );
    } else {
        const date = moment(props.reportMessage.timestamp);

        return (
            <div className="Admin-Time">
                <p>{date.format("YYYY-MM-DD")}</p>
            </div>
        );
    }
}

function Trash(props: { reportMessage: ReportMessageState }) {
    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    const onClick = async () => {
        const answer = window.confirm("정말 삭제하시겠습니까?");

        if (answer) {
            dispatch(coreActions.setIsLoading(true));

            await deleteDoc(doc(firestore, "report", props.reportMessage.reportId));

            dispatch(adminActions.removeReportReplyMessage(props.reportMessage));

            dispatch(coreActions.setIsLoading(false));
        }
    };

    return (
        <div className="Admin-Trash">
            <button onClick={onClick}>
                <img src={trash}/>
            </button>
        </div>
    );
}

function DislikeChecked(props: { reportMessage: ReportMessageState }) {
    return (
        <div className="Admin-DislikeChecked">
            <input
                type="checkbox"
                checked={props.reportMessage.dislikeChecked}
                readOnly={true}
            />
            <p>마음에 들지 않습니다</p>
        </div>
    );
}

function HarmfulChecked(props: { reportMessage: ReportMessageState }) {
    return (
        <div className="Admin-HarmfulChecked">
            <input
                type="checkbox"
                checked={props.reportMessage.harmfulChecked}
                readOnly={true}
            />
            <p>폭력, 혐오, 차별 발언</p>
        </div>
    );
}

function AdChecked(props: { reportMessage: ReportMessageState }) {
    return (
        <div className="Admin-AdChecked">
            <input
                type="checkbox"
                checked={props.reportMessage.adChecked}
                readOnly={true}
            />
            <p>광고, 홍보</p>
        </div>
    );
}

function PornChecked(props: { reportMessage: ReportMessageState }) {
    return (
        <div className="Admin-PornChecked">
            <input
                type="checkbox"
                checked={props.reportMessage.pornChecked}
                readOnly={true}
            />
            <p>포르노, 선정성</p>
        </div>
    );
}

function EtcChecked(props: { reportMessage: ReportMessageState }) {
    return (
        <div className="Admin-EtcChecked">
            <input
                type="checkbox"
                checked={props.reportMessage.etcChecked}
                readOnly={true}
            />
            <p>기타</p>
        </div>
    );
}

function EtcInputBody(props: { reportMessage: ReportMessageState }) {
    return (
        <div className="Admin-EtcInputBody">
            <form>
        <textarea
            placeholder={"신고 내용을 설명해주세요."}
            wrap="soft"
            value={props.reportMessage.etcContent}
            readOnly={true}
        />
            </form>
        </div>
    );
}

function SearchBody() {
    return (
        <div className="Admin-SearchBody">
            <SearchBar/>
            <SearchName/>
            <NamedUser/>
        </div>
    );
}

function SearchBar() {
    const name = useAppSelector((state) => state.admin.name);

    const dispatch = useAppDispatch();

    const addName = (event: any) => {
        const inputVal = event.target.value;

        if (event.key === "Enter" && inputVal !== "" && name !== inputVal) {
            if (name !== "") {
                alert("아이디 검색은 최대 1개까지만 지정할 수 있습니다.");

                event.target.value = "";
            } else {
                if (inputVal[0] !== "#") {
                    dispatch(adminActions.setName(inputVal));
                } else {
                    dispatch(adminActions.setName(inputVal));
                }

                event.target.value = "";
            }
        }
    };

    return (
        <div className="Admin-SearchBar">
            {
                <input
                    type="text"
                    onKeyUp={(e) => addName(e)}
                    placeholder="아이디입력(최대 1개)"
                />
            }
        </div>
    );
}

function SearchName() {
    const name = useAppSelector((state) => state.admin.name);

    const dispatch = useAppDispatch();

    const removeHashtag = () => {
        dispatch(adminActions.setName(""));
    };

    return (
        <div className="Admin-SearchName">
            {
                <span className="Admin-SearchName-tag" onClick={removeHashtag}>
          @{name}
        </span>
            }
        </div>
    );
}

function NamedUser() {
    const uid = useAppSelector((state) => state.admin.uid);
    const name = useAppSelector((state) => state.admin.name);

    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    const getUser = async () => {
        const q = query(collection(firestore, "user"), where("name", "==", name));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            dispatch(adminActions.setUid(doc.data()!.uid));
            dispatch(adminActions.setAvatarUrl(doc.data()!.avatarUrl));
            dispatch(adminActions.setNickname(doc.data()!.nickname));
        });
    };

    useEffect(() => {
        getUser();
    }, [name]);

    if (uid !== "") {
        return (
            <div className="Admin-NamedUser">
                <NamedUserAvatar/>
                <NamedUserNickname/>
                <NamedUserName/>
            </div>
        );
    } else {
        return (
            <div className="Admin-NamedUser">
                <p>해당 아이디의 유저가 존재하지 않습니다.</p>
            </div>
        );
    }
}

function NamedUserAvatar() {
    const navigate = useNavigate();

    const uid = useAppSelector((state) => state.admin.uid);
    const avatarUrl = useAppSelector((state) => state.admin.avatarUrl);

    const onClick = () => {
        navigate(`/profile_view/${uid}`);
    };

    if (avatarUrl !== "") {
        return (
            <div className="Admin-NamedUserAvatar">
                <img src={avatarUrl} onClick={onClick}/>
            </div>
        );
    } else {
        return (
            <div className="Admin-NamedUserAvatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        );
    }
}

function NamedUserNickname() {
    const navigate = useNavigate();

    const uid = useAppSelector((state) => state.admin.uid);
    const nickname = useAppSelector((state) => state.admin.nickname);

    const onClick = () => {
        navigate(`/profile_view/${uid}`);
    };

    if (nickname !== "") {
        return (
            <div className="Admin-NamedUserNickname">
                <button onClick={onClick}>{nickname}</button>
            </div>
        );
    } else {
        return (
            <div className="Admin-NamedUserNickname">
                <button onClick={onClick}>닉네임 없음</button>
            </div>
        );
    }
}

function NamedUserName() {
    const name = useAppSelector((state) => state.admin.name);

    if (name !== "") {
        return (
            <div className="Admin-NamedUserName">
                <p>{"@" + name}</p>
            </div>
        );
    } else {
        return (
            <div className="Admin-NamedUserName">
                <p>아이디 없음</p>
            </div>
        );
    }
}
