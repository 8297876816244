import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {MessageState} from "./message";
import {ReplyState} from "./reply";

export interface NotificationViewState {
    message: MessageState;
    replies: ReplyState[];
    fetching: number;
    fetched: number;
    blocked: string[];
}

const initialState: NotificationViewState = {
    message: {
        messageType: "",
        isDeleted: false,

        documentId: "",
        documentAuthorUid: "",
        documentAuthorAvatarUrl: "",
        documentAuthorNickname: "",
        documentTimestamp: 0,
        documentContent: "",
        documentThumbnailContent: [],
        documentThumbnailImageSizes: [],
        documentHashtags: [],
        documentNumUps: 0,
        documentNumDowns: 0,
        documentNumComments: 0,
        documentNumTokens: 0,
        documentClickUp: false,
        documentClickDown: false,
        documentClickBookmark: false,

        commentId: "",
        commentAuthorUid: "",
        commentAuthorAvatarUrl: "",
        commentAuthorNickname: "",
        commentTimestamp: 0,
        commentContent: "",
        commentNumUps: 0,
        commentNumDowns: 0,
        commentNumReplies: 0,
        commentNumTokens: 0,
        commentClickUp: false,
        commentClickDown: false,

        replyId: "",

        authorUid: "",
        authorNickname: "",
        authorAvatarUrl: "",
        timestamp: 0,
    },

    replies: [],
    fetching: 0,
    fetched: 0,
    blocked: [],
};

export const notificationView = createSlice({
    name: "notificationView",
    initialState,
    reducers: {
        resetMessage(state) {
            state.message = {
                messageType: "",
                isDeleted: false,

                documentId: "",
                documentAuthorUid: "",
                documentAuthorAvatarUrl: "",
                documentAuthorNickname: "",
                documentTimestamp: 0,
                documentContent: "",
                documentThumbnailContent: [],
                documentThumbnailImageSizes: [],
                documentHashtags: [],
                documentNumUps: 0,
                documentNumDowns: 0,
                documentNumComments: 0,
                documentNumTokens: 0,
                documentClickUp: false,
                documentClickDown: false,
                documentClickBookmark: false,

                commentId: "",
                commentAuthorUid: "",
                commentAuthorAvatarUrl: "",
                commentAuthorNickname: "",
                commentTimestamp: 0,
                commentContent: "",
                commentNumUps: 0,
                commentNumDowns: 0,
                commentNumReplies: 0,
                commentNumTokens: 0,
                commentClickUp: false,
                commentClickDown: false,

                replyId: "",

                authorUid: "",
                authorNickname: "",
                authorAvatarUrl: "",
                timestamp: 0,
            };
        },

        setMessage(state, action: PayloadAction<MessageState>) {
            state.message = action.payload;
        },

        resetReplies(state) {
            state.replies = [];
        },

        resetFetching(state) {
            state.fetching = 0;
        },

        incrementFetching(state, action: PayloadAction<number>) {
            state.fetching += action.payload;
        },

        resetFetched(state) {
            state.fetched = 0;
        },

        incrementFetched(state, action: PayloadAction<number>) {
            state.fetched += action.payload;
        },

        resetBlocked(state) {
            state.blocked = [];
        },

        appendBlocked(state, action: PayloadAction<string>) {
            state.blocked = [...state.blocked, action.payload];
        },

        setDocumentClickUp(state) {
            state.message.documentClickUp = true;
            state.message.documentNumUps += 1;
        },

        unsetDocumentClickUp(state) {
            state.message.documentClickUp = false;
            state.message.documentNumUps -= 1;
        },

        setDocumentClickDown(state) {
            state.message.documentClickDown = true;
            state.message.documentNumDowns += 1;
        },

        unsetDocumentClickDown(state) {
            state.message.documentClickDown = false;
            state.message.documentNumDowns -= 1;
        },

        setDocumentClickBookmark(state) {
            state.message.documentClickBookmark = true;
        },

        unsetDocumentClickBookmark(state) {
            state.message.documentClickBookmark = false;
        },

        setCommentClickUp(state) {
            state.message.commentClickUp = true;
            state.message.commentNumUps += 1;
        },

        unsetCommentClickUp(state) {
            state.message.commentClickUp = false;
            state.message.commentNumUps -= 1;
        },

        setCommentClickDown(state) {
            state.message.commentClickDown = true;
            state.message.commentNumDowns += 1;
        },

        unsetCommentClickDown(state) {
            state.message.commentClickDown = false;
            state.message.commentNumDowns -= 1;
        },

        appendReply(state, action: PayloadAction<ReplyState>) {
            const isEqualId = (reply: ReplyState) =>
                reply.replyId === action.payload.replyId;

            if (state.replies.findIndex(isEqualId) >= 0) {
                return;
            } else {
                state.replies = [...state.replies, action.payload].sort((a, b) =>
                    a.timestamp < b.timestamp ? 1 : -1,
                );
            }
        },

        setReplyClickUp(state, action: PayloadAction<string>) {
            const isEqualId = (reply: ReplyState) => reply.replyId === action.payload;

            const index = state.replies.findIndex(isEqualId);
            if (index >= 0) {
                state.replies[index].clickUp = true;
                state.replies[index].numUps += 1;
            }
        },

        unsetReplyClickUp(state, action: PayloadAction<string>) {
            const isEqualId = (reply: ReplyState) => reply.replyId === action.payload;

            const index = state.replies.findIndex(isEqualId);
            if (index >= 0) {
                state.replies[index].clickUp = false;
                state.replies[index].numUps -= 1;
            }
        },

        setReplyClickDown(state, action: PayloadAction<string>) {
            const isEqualId = (reply: ReplyState) => reply.replyId === action.payload;

            const index = state.replies.findIndex(isEqualId);
            if (index >= 0) {
                state.replies[index].clickDown = true;
                state.replies[index].numDowns += 1;
            }
        },

        unsetReplyClickDown(state, action: PayloadAction<string>) {
            const isEqualId = (reply: ReplyState) => reply.replyId === action.payload;

            const index = state.replies.findIndex(isEqualId);
            if (index >= 0) {
                state.replies[index].clickDown = false;
                state.replies[index].numDowns -= 1;
            }
        },

        removeReply(state, action: PayloadAction<ReplyState>) {
            const isEqualId = (reply: ReplyState) =>
                reply.replyId === action.payload.replyId;

            const index = state.replies.findIndex(isEqualId);

            if (index >= 0) {
                state.replies = [
                    ...state.replies.slice(0, index),
                    ...state.replies.slice(index + 1, state.replies.length),
                ];
            }
        },

        incrementNumReplies(state, action: PayloadAction<number>) {
            state.message.commentNumReplies += action.payload;
        },
    },
});

export const notificationViewActions = notificationView.actions;
export default notificationView.reducer;
