import {useAppDispatch, useAppSelector} from "../../module/hook";
import {MobileLoading} from "../loading/mobile_loading";
import {loginActions} from "../../module/login";
import {useNavigate} from "react-router-dom";
import {coreActions} from "../../module/core";
import {
    getAuth,
    GoogleAuthProvider,
    OAuthProvider,
    signInWithCredential,
    signInWithCustomToken,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
} from "firebase/auth";
import "./mobile_login.css";
import logo from "../../asset/image/logo.png";
import logoTransparent from "../../asset/image/logo_transparent.png";
import user from "../../asset/image/user.png";
import lock from "../../asset/image/lock.png";
import horizontalLine from "../../asset/image/horizontal_line.png";
import verticalLine from "../../asset/image/vertical_line.png";
import naver from "../../asset/image/naver.png";
import kakao from "../../asset/image/kakao.png";
import google from "../../asset/image/google.png";
import apple from "../../asset/image/apple.png";
import {doc, getDoc, getFirestore} from "firebase/firestore";
import {MobileHeader} from "../mobile_header";
import KakaoLogin from "react-kakao-login";
import {getFunctions, httpsCallable} from "firebase/functions";
import {useEffect} from "react";

export function MobileLogin() {
    const isLoading = useAppSelector((state) => state.core.isLoading);

    return (
        <div className="MobileLogin">
            {isLoading ? <MobileLoading/> : <Body/>}
        </div>
    );
}

function Body() {
    return (
        <div className="MobileLogin-Body">
            <MobileHeader/>
            <Title/>
            <Background/>
            <EmailInput/>
            <PasswordInput/>
            <Help/>
            <SignIn/>
            <SignUp/>
            <EasySignInTitle/>
            <EasySignIn/>
        </div>
    );
}

function Title() {
    return (
        <div className="MobileLogin-Title">
            <img src={logo}/>
        </div>
    );
}

function Background() {
    return (
        <div className="MobileLogin-Background">
            <img src={logoTransparent}/>
        </div>
    );
}

function EmailInput() {
    const email = useAppSelector((state) => state.login.email);

    const dispatch = useAppDispatch();

    const onChange = (e: any) => {
        dispatch(loginActions.setEmail(e.target.value));
    };

    return (
        <div className="MobileLogin-Email">
            <img src={user}/>
            <form>
                <input
                    type="text"
                    placeholder="이메일을 입력해주세요"
                    required
                    value={email}
                    onChange={onChange}
                />
            </form>
        </div>
    );
}

function PasswordInput() {
    const password = useAppSelector((state) => state.login.password);

    const dispatch = useAppDispatch();

    const onChange = (e: any) => {
        dispatch(loginActions.setPassword(e.target.value));
    };

    return (
        <div className="MobileLogin-Password">
            <form>
                <img src={lock}/>
                <input
                    type="password"
                    placeholder="비밀번호를 입력해주세요"
                    required
                    value={password}
                    onChange={onChange}
                />
            </form>
        </div>
    );
}

function SignIn() {
    const auth = getAuth();
    const firestore = getFirestore();
    const navigate = useNavigate();

    const email = useAppSelector((state) => state.login.email);
    const password = useAppSelector((state) => state.login.password);

    const dispatch = useAppDispatch();

    const onClick = () => {
        dispatch(coreActions.setIsLoading(true));

        signInWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                const user = userCredential.user;

                if (user.emailVerified) {
                    const userDocRef = doc(firestore, "admin", auth.currentUser!.uid);
                    const userDocSnap = await getDoc(userDocRef);

                    if (
                        userDocSnap.exists() &&
                        userDocSnap.data()!.uid === auth.currentUser!.uid
                    ) {
                        dispatch(coreActions.setIsAdmin(true));
                    } else {
                        dispatch(coreActions.setIsAdmin(false));
                    }

                    dispatch(coreActions.setIsLoading(false));

                    navigate("/");
                } else {
                    dispatch(coreActions.setIsAdmin(false));

                    dispatch(coreActions.setIsLoading(false));

                    signOut(auth);

                    alert("이메일 인증을 완료해주세요");
                }
            })
            .catch((error) => {
                const errorCode = error.code;

                if (errorCode === "auth/invalid-credential") {
                    alert(
                        "해당 유저를 찾을 수 없습니다. 아이디와 비밀번호를 다시 확인해주세요.",
                    );
                } else if (errorCode === "auth/invalid-email") {
                    alert("이메일 주소가 올바르지 않습니다.");
                } else if (errorCode === "auth/missing-password") {
                    alert("비밀번호를 입력해주세요.");
                } else {
                    alert("서버와의 통신에 실패하였습니다.");
                }

                dispatch(coreActions.setIsLoading(false));
            });
    };

    return (
        <div className="MobileLogin-SignIn">
            <button onClick={onClick}>로그인</button>
        </div>
    );
}

function Help() {
    return (
        <div className="MobileLogin-Help">
            <EmailVerification/>
            <img src={verticalLine}/>
            <PasswordReset/>
        </div>
    );
}

function EmailVerification() {
    const navigate = useNavigate();

    const onClick = () => {
        navigate("/email_verification");
    };

    return (
        <div className="MobileLogin-EmailVerification">
            <button onClick={onClick}>이메일 재인증</button>
        </div>
    );
}

function PasswordReset() {
    const navigate = useNavigate();

    const onClick = () => {
        navigate("/password_reset");
    };

    return (
        <div className="MobileLogin-PasswordReset">
            <button onClick={onClick}>비밀번호 재설정</button>
        </div>
    );
}

function SignUp() {
    const navigate = useNavigate();

    const onClick = () => {
        navigate("/eula");
    };

    return (
        <div className="MobileLogin-SignUp">
            <p>처음이신가요?</p>
            <button onClick={onClick}>회원 가입</button>
        </div>
    );
}

function EasySignInTitle() {
    return (
        <div className="MobileLogin-EasySignInTitle">
            <img src={horizontalLine}/>
            <p>간편로그인</p>
            <img src={horizontalLine}/>
        </div>
    );
}

function EasySignIn() {
    return (
        <div className="MobileLogin-EasySignIn">
            <Naver/>
            <Kakao/>
            <Google/>
            <Apple/>
        </div>
    );
}

function Naver() {
    let naverLogin: any;
    const auth = getAuth();
    const firestore = getFirestore();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleClickNaverLogin = async (body: {
        uid: string;
        email: string;
        displayName: string;
        photoURL: string;
    }) => {
        dispatch(coreActions.setIsLoading(true));

        const getFirebaseTokenByNaverCode = httpsCallable<
            {
                uid: string;
            },
            {
                firebaseToken: string;
            }
        >(getFunctions(), "naverCustomAuth");

        const res = await getFirebaseTokenByNaverCode({
            uid: body.uid,
        });

        const firebaseToken = res?.data?.firebaseToken;
        const result = await signInWithCustomToken(auth, firebaseToken);
        const user = result.user;
        Object.assign(user, {
            email: body.email,
            displayName: body.displayName,
            photoURL: body.photoURL,
        });

        const userRef = doc(firestore, "user", result.user.uid);
        const userSnap = await getDoc(userRef);

        if (!userSnap.exists()) {
            dispatch(coreActions.setIsAdmin(false));
            navigate("/easy_sign_up");
            dispatch(coreActions.setIsLoading(false));
        } else {
            dispatch(coreActions.setIsAdmin(false));
            navigate("/");
            dispatch(coreActions.setIsLoading(false));
        }
    };

    const initNaverLogin = () => {
        // @ts-ignore
        naverLogin = new window.naver.LoginWithNaverId({
            clientId: process.env.REACT_APP_NAVER_CLIENT_ID,
            callbackUrl: `https://www.giggles.expert/login/`,
            isPopup: false,
            loginButton: {color: "green", type: 1, height: 60},
            callbackHandle: true,
        });
        naverLogin.init();
    };

    const getData = () => {
        if (window.location.href.includes("access_token")) {
            naverLogin.getLoginStatus((status: boolean) => {
                if (status) {
                    const userInfo = naverLogin.user;

                    if (userInfo?.id) {
                        handleClickNaverLogin({
                            uid: userInfo?.id,
                            email: userInfo?.email,
                            displayName: userInfo?.nickname,
                            photoURL: userInfo?.profile_image,
                        });
                    }
                } else {
                    console.error("Failed to login");
                }
            });
        }
    };

    useEffect(() => {
        initNaverLogin();
        getData();
    }, []);

    const handleNaverClick = () => {
        const naverLoginButton = document.getElementById(
            "naverIdLogin_loginButton",
        );
        if (naverLoginButton) naverLoginButton.click();
    };

    return (
        <div
            className="Login-Naver"
            onClick={handleNaverClick}
            style={{cursor: "pointer"}}
        >
            <img src={naver}/>
            <div id="naverIdLogin" style={{display: "none"}}/>
        </div>
    );
}

function Kakao() {
    const auth = getAuth();
    const firestore = getFirestore();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleClickKakaoLogin = async (
        idToken: string,
        accessToken: string,
        displayName: string,
        photoURL: string,
    ) => {
        dispatch(coreActions.setIsLoading(true));

        const provider = new OAuthProvider("oidc.kakao");

        const credential = provider.credential({
            idToken,
            accessToken,
        });
        try {
            const result = await signInWithCredential(auth, credential);
            const user = result.user;
            Object.assign(user, {
                displayName: displayName,
                photoURL: photoURL,
            });

            const userRef = doc(firestore, "user", result.user.uid);
            const userSnap = await getDoc(userRef);
            if (!userSnap.exists()) {
                dispatch(coreActions.setIsAdmin(false));

                navigate("/easy_sign_up");

                dispatch(coreActions.setIsLoading(false));
            } else {
                dispatch(coreActions.setIsAdmin(false));

                navigate("/");

                dispatch(coreActions.setIsLoading(false));
            }
        } catch (e) {
            if (
                // @ts-ignore
                e.message?.includes("auth/account-exists-with-different-credential")
            ) {
                alert("이미 등록된 유저입니다.");
            }
        }
    };

    const token = process.env.REACT_APP_KAKAO_TOKEN;

    return (
        <KakaoLogin
            token={token!}
            onSuccess={(res) => {
                // @ts-ignore
                const idToken = res.response?.id_token;
                const accessToken = res.response?.access_token;

                handleClickKakaoLogin(
                    idToken,
                    accessToken,
                    res.profile?.kakao_account?.profile?.nickname || "",
                    res.profile?.kakao_account?.profile?.thumbnail_image_url || "",
                );
            }}
            onFail={() => {
            }}
            render={({onClick}) => (
                <div
                    className="MobileLogin-Kakao"
                    onClick={onClick}
                    style={{cursor: "pointer"}}
                >
                    <img src={kakao} onClick={onClick}/>
                </div>
            )}
        />
    );
}

function Google() {
    const navigate = useNavigate();

    const auth = getAuth();
    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    const onClick = async () => {
        dispatch(coreActions.setIsLoading(true));

        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);

        const userRef = doc(firestore, "user", result.user.uid);
        const userSnap = await getDoc(userRef);
        if (!userSnap.exists()) {
            dispatch(coreActions.setIsAdmin(false));

            navigate("/easy_sign_up");

            dispatch(coreActions.setIsLoading(false));
        } else {
            dispatch(coreActions.setIsAdmin(false));

            navigate("/");

            dispatch(coreActions.setIsLoading(false));
        }
    };

    return (
        <div className="MobileLogin-Google">
            <img src={google} onClick={onClick}/>
        </div>
    );
}

function Apple() {
    const navigate = useNavigate();

    const auth = getAuth();
    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    const onClick = async () => {
        dispatch(coreActions.setIsLoading(true));

        const provider = new OAuthProvider("apple.com");
        const result = await signInWithPopup(auth, provider);

        const userRef = doc(firestore, "user", result.user.uid);
        const userSnap = await getDoc(userRef);
        if (!userSnap.exists()) {
            dispatch(coreActions.setIsAdmin(false));

            navigate("/easy_sign_up");

            dispatch(coreActions.setIsLoading(false));
        } else {
            dispatch(coreActions.setIsAdmin(false));

            navigate("/");

            dispatch(coreActions.setIsLoading(false));
        }
    };

    return (
        <div className="MobileLogin-Apple">
            <img src={apple} onClick={onClick}/>
        </div>
    );
}
