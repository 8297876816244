import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface EventWriteState {
    isImageLoading: boolean;
    content: string;
}

const initialState: EventWriteState = {
    isImageLoading: false,
    content: "",
};

export const eventWrite = createSlice({
    name: "eventWrite",
    initialState,
    reducers: {
        setIsImageLoading(state, action: PayloadAction<boolean>) {
            state.isImageLoading = action.payload;
        },

        setContent(state, action: PayloadAction<string>) {
            state.content = action.payload;
        },
    },
});

export const eventWriteActions = eventWrite.actions;
export default eventWrite.reducer;
