import {useAppDispatch, useAppSelector} from "../../module/hook";
import {Header} from "../header";
import {Sidebar} from "../sidebar";
import {Loading} from "../loading/loading";
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    limit,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    startAfter,
} from "firebase/firestore";
import {getAuth} from "firebase/auth";
import {profileViewActions} from "../../module/profile_view";
import defaultAvatar from "../../asset/image/default_avatar.png";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import "./profile_view.css";
import underscore from "../../asset/image/underscore.png";
import {DocumentState} from "../../module/document";
import {UserState} from "../../module/user";
import {useBottomScrollListener} from "react-bottom-scroll-listener";
import {coreActions} from "../../module/core";
import parse from "html-react-parser";
import moment from "moment";
import threeDots from "../../asset/image/three_dots.png";
import comment from "../../asset/image/comment.png";
import bookmark from "../../asset/image/bookmark.png";
import bookmarkFocus from "../../asset/image/bookmark_focus.png";
import hashtag from "../../asset/image/hashtag.png";
import back from "../../asset/image/back.png";
import upFocus from "../../asset/image/up_focus.png";
import up from "../../asset/image/up.png";
import down from "../../asset/image/down.png";
import downFocus from "../../asset/image/down_focus.png";
import more from "../../asset/image/more.png";
import comingSoon from "../../asset/image/coming_soon.png";
import LinearProgress from "@mui/material/LinearProgress";
import block from "../../asset/image/block.png";
import notify from "../../asset/image/notify.png";
import trash from "../../asset/image/trash.png";
import verticalLine from "../../asset/image/vertical_line.png";
import gRanking from "../../asset/image/g_ranking.png";
import gToken from "../../asset/image/g_token.png";
import {RightSidebar} from "../right_sidebar";

export function ProfileView() {
    const {slug} = useParams();

    const isLoading = useAppSelector((state) => state.core.isLoading);

    const auth = getAuth();
    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (slug! !== undefined) {
            dispatch(coreActions.setIsLoading(true));

            dispatch(coreActions.setFocus("profile"));
            dispatch(profileViewActions.setUid(slug!));

            const userDocRef = doc(firestore, "user", slug!);

            getDoc(userDocRef).then(async (docSnap) => {
                if (docSnap.exists()) {
                    if (docSnap.data().hasOwnProperty("avatarUrl")) {
                        dispatch(
                            profileViewActions.setAvatarUrl(docSnap.data()["avatarUrl"]),
                        );
                    } else {
                        dispatch(profileViewActions.setAvatarUrl(""));
                    }

                    if (docSnap.data().hasOwnProperty("name")) {
                        dispatch(profileViewActions.setName(docSnap.data()["name"]));
                    } else {
                        dispatch(profileViewActions.setName(""));
                    }

                    if (docSnap.data().hasOwnProperty("nickname")) {
                        dispatch(
                            profileViewActions.setNickname(docSnap.data()["nickname"]),
                        );
                    } else {
                        dispatch(profileViewActions.setNickname(""));
                    }

                    if (docSnap.data().hasOwnProperty("bio")) {
                        dispatch(profileViewActions.setBio(docSnap.data()["bio"]));
                    } else {
                        dispatch(profileViewActions.setBio(""));
                    }
                }

                const rankingQuery = query(
                    collection(firestore, "ranking"),
                    orderBy("timestamp", "desc"),
                    limit(1),
                );

                onSnapshot(rankingQuery, async (snapshot) => {
                    const newDocuments = snapshot.docs.map((elem) => elem.data());

                    for (const elem of newDocuments) {
                        const docRankingDocRef = doc(
                            firestore,
                            "ranking",
                            elem.timestamp.toString(),
                            "docPoint",
                            slug!,
                        );
                        const commentRankingDocRef = doc(
                            firestore,
                            "ranking",
                            elem.timestamp.toString(),
                            "commentPoint",
                            slug!,
                        );
                        const totalRankingDocRef = doc(
                            firestore,
                            "ranking",
                            elem.timestamp.toString(),
                            "totalPoint",
                            slug!,
                        );

                        getDoc(docRankingDocRef).then(async (docSnap) => {
                            if (docSnap.exists()) {
                                if (docSnap.data().hasOwnProperty("docPoint")) {
                                    dispatch(
                                        profileViewActions.setDocPoint(docSnap.data()["docPoint"]),
                                    );
                                } else {
                                    dispatch(profileViewActions.setDocPoint(0));
                                }

                                if (docSnap.data().hasOwnProperty("docRanking")) {
                                    dispatch(
                                        profileViewActions.setDocRanking(
                                            docSnap.data()["docRanking"],
                                        ),
                                    );
                                } else {
                                    dispatch(profileViewActions.setDocRanking(0));
                                }
                            } else {
                                dispatch(profileViewActions.setDocPoint(0));
                                dispatch(profileViewActions.setDocRanking(0));
                            }
                        });

                        getDoc(commentRankingDocRef).then(async (docSnap) => {
                            if (docSnap.exists()) {
                                if (docSnap.data().hasOwnProperty("commentPoint")) {
                                    dispatch(
                                        profileViewActions.setCommentPoint(
                                            docSnap.data()["commentPoint"],
                                        ),
                                    );
                                } else {
                                    dispatch(profileViewActions.setCommentPoint(0));
                                }

                                if (docSnap.data().hasOwnProperty("commentRanking")) {
                                    dispatch(
                                        profileViewActions.setCommentRanking(
                                            docSnap.data()["commentRanking"],
                                        ),
                                    );
                                } else {
                                    dispatch(profileViewActions.setCommentRanking(0));
                                }
                            } else {
                                dispatch(profileViewActions.setCommentPoint(0));
                                dispatch(profileViewActions.setCommentRanking(0));
                            }
                        });

                        getDoc(totalRankingDocRef).then(async (docSnap) => {
                            if (docSnap.exists()) {
                                if (docSnap.data().hasOwnProperty("totalPoint")) {
                                    dispatch(
                                        profileViewActions.setTotalPoint(
                                            docSnap.data()["totalPoint"],
                                        ),
                                    );
                                } else {
                                    dispatch(profileViewActions.setTotalPoint(0));
                                }

                                if (docSnap.data().hasOwnProperty("totalRanking")) {
                                    dispatch(
                                        profileViewActions.setTotalRanking(
                                            docSnap.data()["totalRanking"],
                                        ),
                                    );
                                } else {
                                    dispatch(profileViewActions.setTotalRanking(0));
                                }
                            } else {
                                dispatch(profileViewActions.setTotalPoint(0));
                                dispatch(profileViewActions.setTotalRanking(0));
                            }
                        });
                    }
                });

                const followerCollectionRef = collection(
                    firestore,
                    "user",
                    slug!,
                    "follower",
                );
                const followingCollectionRef = collection(
                    firestore,
                    "user",
                    slug!,
                    "following",
                );
                const postCollectionRef = collection(firestore, "user", slug!, "post");

                const followerCollectionSnapshot = await getDocs(followerCollectionRef);
                const followingCollectionSnapshot = await getDocs(
                    followingCollectionRef,
                );
                const postCollectionSnapshot = await getDocs(postCollectionRef);

                dispatch(
                    profileViewActions.setNumFollowers(
                        followerCollectionSnapshot.docs.length,
                    ),
                );
                dispatch(
                    profileViewActions.setNumFollowings(
                        followingCollectionSnapshot.docs.length,
                    ),
                );
                dispatch(
                    profileViewActions.setNumPosts(postCollectionSnapshot.docs.length),
                );

                dispatch(coreActions.setIsLoading(false));
            });

            if (auth.currentUser !== null) {
                const followingDocRef = doc(
                    firestore,
                    "user",
                    auth.currentUser!.uid,
                    "following",
                    slug!,
                );

                getDoc(followingDocRef).then((docSnap) => {
                    if (docSnap.exists()) {
                        dispatch(profileViewActions.setIsFollowing(true));
                    } else {
                        dispatch(profileViewActions.setIsFollowing(false));
                    }
                });
            } else {
                dispatch(profileViewActions.setIsFollowing(false));
            }
        }
    }, [slug!]);

    return (
        <div className="ProfileView">{isLoading ? <Loading/> : <Body/>}</div>
    );
}

function Body() {
    return (
        <div className="ProfileView-Body">
            <Header/>
            <Sidebar/>
            <Title/>
            <Main/>
            <RightSidebar/>
        </div>
    );
}

function Title() {
    const nickname = useAppSelector((state) => state.profileView.nickname);

    let title;
    if (nickname !== "") {
        title = `${nickname}의 프로필`;
    } else {
        title = "이름없음의 프로필";
    }

    const navigate = useNavigate();

    const onClick = () => {
        navigate(-1);
    };

    return (
        <div className="ProfileView-Title">
            <img src={back} onClick={onClick}/>
            <h1>{title}</h1>
        </div>
    );
}

function Main() {
    return (
        <div className="ProfileView-Main">
            <ProfileMain/>
            <Details/>
        </div>
    );
}

function ProfileMain() {
    return (
        <div className="ProfileView-ProfileMain">
            <ProfileHeader/>
            <RankingAndToken/>
            <Bio/>
            <FollowOrUnfollow/>
            <Tab/>
        </div>
    );
}

function ProfileHeader() {
    return (
        <div className="ProfileView-ProfileHeader">
            <Avatar/>
            <Names/>
        </div>
    );
}

function Avatar() {
    const avatarUrl = useAppSelector((state) => state.profileView.avatarUrl);

    if (avatarUrl !== "") {
        return (
            <div className="ProfileView-Avatar">
                <img src={avatarUrl}/>
            </div>
        );
    } else {
        return (
            <div className="ProfileView-Avatar">
                <img src={defaultAvatar}/>
            </div>
        );
    }
}

function Names() {
    return (
        <div className="ProfileView-Names">
            <Nickname/>
            <Name/>
            <Status/>
        </div>
    );
}

function Nickname() {
    const nickname = useAppSelector((state) => state.profileView.nickname);

    if (nickname !== "") {
        return (
            <div className="ProfileView-Nickname">
                <p>{nickname}</p>
            </div>
        );
    } else {
        return (
            <div className="ProfileView-Nickname">
                <p>닉네임 없음</p>
            </div>
        );
    }
}

function Name() {
    const name = useAppSelector((state) => state.profileView.name);

    if (name !== "") {
        return (
            <div className="ProfileView-Name">
                <p>{"@" + name}</p>
            </div>
        );
    } else {
        return (
            <div className="ProfileView-Name">
                <p>닉네임 없음</p>
            </div>
        );
    }
}

function Status() {
    return (
        <div className="ProfileView-Status">
            <NumFollowers/>
            <NumFollowings/>
            <NumPosts/>
        </div>
    );
}

function NumFollowers() {
    const numFollowers = useAppSelector(
        (state) => state.profileView.numFollowers,
    );

    return (
        <div className="ProfileView-NumFollowers">
            <h1>팔로워</h1>
            <p>{numFollowers}</p>
        </div>
    );
}

function NumFollowings() {
    const numFollowings = useAppSelector(
        (state) => state.profileView.numFollowings,
    );

    return (
        <div className="ProfileView-NumFollowings">
            <h1>팔로잉</h1>
            <p>{numFollowings}</p>
        </div>
    );
}

function NumPosts() {
    const numPosts = useAppSelector((state) => state.profileView.numPosts);

    return (
        <div className="ProfileView-NumPosts">
            <h1>게시글</h1>
            <p>{numPosts}</p>
        </div>
    );
}

function Bio() {
    const bio = useAppSelector((state) => state.profileView.bio);

    if (bio !== "") {
        return (
            <div className="ProfileView-Bio">
                <p>{bio}</p>
            </div>
        );
    } else {
        return (
            <div className="ProfileView-Bio">
                <p>자기소개 없음</p>
            </div>
        );
    }
}

function RankingAndToken() {
    return (
        <div className="ProfileView-RankingAndToken">
            <Ranking/>
            <VerticalLine/>
            <Token/>
        </div>
    );
}

function VerticalLine() {
    return (
        <div className="ProfileView-VerticalLine">
            <img src={verticalLine}/>
        </div>
    );
}

function Ranking() {
    return (
        <div className="ProfileView-Ranking">
            <GRanking/>
            <TotalRanking/>
            <DocRanking/>
            <CommentRanking/>
        </div>
    );
}

function GRanking() {
    return (
        <div className="ProfileView-GRanking">
            <img src={gRanking}/>
        </div>
    );
}

function DocRanking() {
    const docPoint = useAppSelector((state) => state.profileView.docPoint);
    const docRanking = useAppSelector((state) => state.profileView.docRanking);

    if (docRanking !== 0) {
        return (
            <div className="ProfileView-DocRanking">
                <h1>글</h1>
                <p>
                    {docRanking}위 ({docPoint} P)
                </p>
            </div>
        );
    } else {
        return (
            <div className="ProfileView-DocRanking">
                <h1>글</h1>
                <p>-</p>
            </div>
        );
    }
}

function CommentRanking() {
    const commentPoint = useAppSelector(
        (state) => state.profileView.commentPoint,
    );
    const commentRanking = useAppSelector(
        (state) => state.profileView.commentRanking,
    );

    if (commentRanking !== 0) {
        return (
            <div className="ProfileView-CommentRanking">
                <h1>댓글</h1>
                <p>
                    {commentRanking}위 ({commentPoint} P)
                </p>
            </div>
        );
    } else {
        return (
            <div className="ProfileView-CommentRanking">
                <h1>댓글</h1>
                <p>-</p>
            </div>
        );
    }
}

function TotalRanking() {
    const totalPoint = useAppSelector((state) => state.profileView.totalPoint);
    const totalRanking = useAppSelector(
        (state) => state.profileView.totalRanking,
    );

    if (totalRanking !== 0) {
        return (
            <div className="ProfileView-TotalRanking">
                <h1>전체</h1>
                <p>
                    {totalRanking}위 ({totalPoint} P)
                </p>
            </div>
        );
    } else {
        return (
            <div className="ProfileView-TotalRanking">
                <h1>전체</h1>
                <p>-</p>
            </div>
        );
    }
}

function Token() {
    return (
        <div className="ProfileView-Token">
            <GToken/>
            <BigComingSoon/>
        </div>
    );
}

function GToken() {
    return (
        <div className="ProfileView-GToken">
            <img src={gToken}/>
        </div>
    );
}

function BigComingSoon() {
    return (
        <div className="ProfileView-BigComingSoon">
            <img src={comingSoon}/>
        </div>
    );
}

function FollowOrUnfollow() {
    const uid = useAppSelector((state) => state.profileView.uid);
    const numFollowers = useAppSelector(
        (state) => state.profileView.numFollowers,
    );
    const isFollowing = useAppSelector((state) => state.profileView.isFollowing);

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            if (auth.currentUser!.uid === uid) {
                alert("나 자신을 팔로우 할 수 없습니다");
            } else {
                dispatch(coreActions.setIsLoading(true));

                if (isFollowing) {
                    const followingRef = doc(
                        firestore,
                        "user",
                        auth.currentUser!.uid,
                        "following",
                        uid,
                    );

                    const followerRef = doc(
                        firestore,
                        "user",
                        uid,
                        "follower",
                        auth.currentUser!.uid,
                    );

                    await deleteDoc(followingRef);
                    await deleteDoc(followerRef);

                    dispatch(profileViewActions.setNumFollowers(numFollowers - 1));
                    dispatch(profileViewActions.setIsFollowing(false));
                } else {
                    const followingRef = doc(
                        firestore,
                        "user",
                        auth.currentUser!.uid,
                        "following",
                        uid,
                    );

                    const followerRef = doc(
                        firestore,
                        "user",
                        uid,
                        "follower",
                        auth.currentUser!.uid,
                    );

                    const timestamp = Date.now();

                    await setDoc(followingRef, {
                        uid: uid,
                        timestamp: timestamp,
                    });

                    await setDoc(followerRef, {
                        uid: auth.currentUser!.uid,
                        timestamp: timestamp,
                    });

                    dispatch(profileViewActions.setNumFollowers(numFollowers + 1));
                    dispatch(profileViewActions.setIsFollowing(true));
                }

                dispatch(coreActions.setIsLoading(false));
            }
        } else {
            navigate("/login");
        }
    };

    if (isFollowing) {
        return (
            <div className="ProfileView-FollowOrUnfollow">
                <button onClick={onClick}>팔로우 중단</button>
            </div>
        );
    } else {
        return (
            <div className="ProfileView-FollowOrUnfollow">
                <button onClick={onClick}>팔로우</button>
            </div>
        );
    }
}

function Tab() {
    return (
        <div className="ProfileView-Tab">
            <TabFollowers/>
            <TabFollowings/>
            <TabPosts/>
        </div>
    );
}

function TabFollowers() {
    const tab = useAppSelector((state) => state.profileView.tab);

    const dispatch = useAppDispatch();

    const onClick = () => {
        dispatch(profileViewActions.setTab("followers"));
    };

    if (tab === "followers") {
        return (
            <div className="ProfileView-TabFollowers-Focus">
                <button onClick={onClick}>팔로워</button>
                <img src={underscore}/>
            </div>
        );
    } else {
        return (
            <div className="ProfileView-TabFollowers">
                <button onClick={onClick}>팔로워</button>
            </div>
        );
    }
}

function TabFollowings() {
    const tab = useAppSelector((state) => state.profileView.tab);

    const dispatch = useAppDispatch();

    const onClick = () => {
        dispatch(profileViewActions.setTab("followings"));
    };

    if (tab === "followings") {
        return (
            <div className="ProfileView-TabFollowings-Focus">
                <button onClick={onClick}>팔로잉</button>
                <img src={underscore}/>
            </div>
        );
    } else {
        return (
            <div className="ProfileView-TabFollowings">
                <button onClick={onClick}>팔로잉</button>
            </div>
        );
    }
}

function TabPosts() {
    const tab = useAppSelector((state) => state.profileView.tab);

    const dispatch = useAppDispatch();

    const onClick = () => {
        dispatch(profileViewActions.setTab("posts"));
    };

    if (tab === "posts") {
        return (
            <div className="ProfileView-TabPosts-Focus">
                <button onClick={onClick}>게시글</button>
                <img src={underscore}/>
            </div>
        );
    } else {
        return (
            <div className="ProfileView-TabPosts">
                <button onClick={onClick}>게시글</button>
            </div>
        );
    }
}

function Details() {
    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const [timestamp, setTimestamp] = useState(Date.now());

    const [followerLastVisible, setFollowerLastVisible] =
        useState<any>(undefined);
    const [followingLastVisible, setFollowingLastVisible] =
        useState<any>(undefined);
    const [postLastVisible, setPostLastVisible] = useState<any>(undefined);
    const [bookmarkLastVisible, setBookmarkLastVisible] =
        useState<any>(undefined);

    const uid = useAppSelector((state) => state.profileView.uid);
    const tab = useAppSelector((state) => state.profileView.tab);

    const followers = useAppSelector((state) => state.profileView.followers);
    const followings = useAppSelector((state) => state.profileView.followings);
    const posts = useAppSelector((state) => state.profileView.posts);

    const followerFetching = useAppSelector(
        (state) => state.profileView.followerFetching,
    );
    const followerFetched = useAppSelector(
        (state) => state.profileView.followerFetched,
    );

    const followingFetching = useAppSelector(
        (state) => state.profileView.followingFetching,
    );
    const followingFetched = useAppSelector(
        (state) => state.profileView.followingFetched,
    );

    const postFetching = useAppSelector(
        (state) => state.profileView.postFetching,
    );
    const postFetched = useAppSelector((state) => state.profileView.postFetched);

    const [images, setImages] = useState(new Map());

    const getFollowers = () => {
        if (uid !== "") {
            let q;
            if (followerLastVisible === -1) {
                return;
            } else if (followerLastVisible !== undefined) {
                q = query(
                    collection(firestore, "user", uid, "follower"),
                    orderBy("timestamp", "desc"),
                    limit(10),
                    startAfter(followerLastVisible),
                );
            } else {
                dispatch(profileViewActions.resetFollowerBlocked());
                dispatch(profileViewActions.resetFollowers());

                dispatch(profileViewActions.resetFollowerFetching());
                dispatch(profileViewActions.resetFollowerFetched());

                q = query(
                    collection(firestore, "user", uid, "follower"),
                    orderBy("timestamp", "desc"),
                    limit(10),
                );
            }

            onSnapshot(q, async (snapshot) => {
                const newFollowers = snapshot.docs.map((elem) => elem.data());

                dispatch(
                    profileViewActions.incrementFollowerFetching(newFollowers.length),
                );

                for (const elem of newFollowers) {
                    getFollower(elem);
                }

                if (snapshot.docs.length === 0) {
                    setFollowerLastVisible(-1);
                } else {
                    setFollowerLastVisible(snapshot.docs[snapshot.docs.length - 1]);
                }
            });
        }
    };

    const getFollower = async (elem: any) => {
        let avatarUrl = "";
        let name = "";
        let nickname = "";
        let isFollowing = false;

        const userDocRef = doc(firestore, "user", elem.uid);

        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
            const data = docSnap.data();
            avatarUrl = data.avatarUrl || "";
            name = data.name || "";
            nickname = data.nickname || "";
        } else {
            dispatch(profileViewActions.appendFollowerBlocked(elem.uid));
            dispatch(profileViewActions.incrementFollowerFetched(1));
            return;
        }

        if (auth.currentUser !== null) {
            const followingDocRef = doc(
                firestore,
                "user",
                auth.currentUser!.uid,
                "following",
                elem.uid,
            );

            await getDoc(followingDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    isFollowing = true;
                }
            });
        }

        const follower: UserState = {
            uid: elem.uid,
            timestamp: elem.timestamp,
            avatarUrl: avatarUrl,
            name: name,
            nickname: nickname,
            isFollowing: isFollowing,
            docPoint: 0,
            docRanking: 0,
            commentPoint: 0,
            commentRanking: 0,
            totalPoint: 0,
            totalRanking: 0,
        };

        if (elem.timestamp < timestamp) {
            dispatch(profileViewActions.appendFollower(follower));
        }

        dispatch(profileViewActions.incrementFollowerFetched(1));
    };

    const getFollowings = () => {
        if (uid !== "") {
            let q;
            if (followingLastVisible === -1) {
                return;
            } else if (followingLastVisible !== undefined) {
                q = query(
                    collection(firestore, "user", uid, "following"),
                    orderBy("timestamp", "desc"),
                    limit(10),
                    startAfter(followingLastVisible),
                );
            } else {
                dispatch(profileViewActions.resetFollowingBlocked());
                dispatch(profileViewActions.resetFollowings());

                dispatch(profileViewActions.resetFollowingFetching());
                dispatch(profileViewActions.resetFollowingFetched());

                q = query(
                    collection(firestore, "user", uid, "following"),
                    orderBy("timestamp", "desc"),
                    limit(10),
                );
            }

            onSnapshot(q, async (snapshot) => {
                const newFollowings = snapshot.docs.map((elem) => elem.data());

                dispatch(
                    profileViewActions.incrementFollowingFetching(newFollowings.length),
                );

                for (const elem of newFollowings) {
                    getFollowing(elem);
                }

                if (snapshot.docs.length === 0) {
                    setFollowingLastVisible(-1);
                } else {
                    setFollowingLastVisible(snapshot.docs[snapshot.docs.length - 1]);
                }
            });
        }
    };

    const getFollowing = async (elem: any) => {
        let avatarUrl = "";
        let name = "";
        let nickname = "";
        let isFollowing = false;

        const userDocRef = doc(firestore, "user", elem.uid);

        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
            const data = docSnap.data();
            avatarUrl = data.avatarUrl || "";
            name = data.name || "";
            nickname = data.nickname || "";
        } else {
            dispatch(profileViewActions.appendFollowingBlocked(elem.uid));
            dispatch(profileViewActions.incrementFollowingFetched(1));
            return;
        }

        if (auth.currentUser !== null) {
            const followingDocRef = doc(
                firestore,
                "user",
                elem.uid,
                "following",
                auth.currentUser!.uid,
            );

            await getDoc(followingDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    isFollowing = true;
                }
            });
        }

        const following: UserState = {
            uid: elem.uid,
            timestamp: elem.timestamp,
            avatarUrl: avatarUrl,
            name: name,
            nickname: nickname,
            isFollowing: isFollowing,
            docPoint: 0,
            docRanking: 0,
            commentPoint: 0,
            commentRanking: 0,
            totalPoint: 0,
            totalRanking: 0,
        };

        if (elem.timestamp < timestamp) {
            dispatch(profileViewActions.appendFollowing(following));
        }

        dispatch(profileViewActions.incrementFollowingFetched(1));
    };

    async function reactiveLength(content: any) {
        if (Array.isArray(content) === true) {
            let length = 0;
            for (let child of content) {
                if (Array.isArray(child.props.children)) {
                    length += await reactiveLength(child);
                } else {
                    if (child.props.children && child.props.children.type === "img") {
                        let img;
                        if (images.has(child.props.children.props.src)) {
                            img = images.get(child.props.children.props.src);
                        } else {
                            img = new Image();

                            img.src = child.props.children.props.src;
                            await img.decode();

                            images.set(child.props.children.props.src, img);
                        }

                        if (img.naturalHeight) {
                            length += img.naturalHeight;
                        } else {
                            length += 1;
                        }
                    } else if (child.props.children && child.props.children.length) {
                        length += child.props.children.length;
                    } else {
                        length += 1;
                    }
                }
            }

            setImages(images);
            return length;
        } else if (
            content.hasOwnProperty("props") &&
            content.props.hasOwnProperty("children") &&
            Array.isArray(content.props.children)
        ) {
            let length = 0;
            for (let child of content.props.children) {
                if (
                    child.hasOwnProperty("props") &&
                    child.props.hasOwnProperty("children") &&
                    Array.isArray(child.props.children)
                ) {
                    length += await reactiveLength(child);
                } else {
                    if (child.type === "img") {
                        let img;
                        if (images.has(child.props.src)) {
                            img = images.get(child.props.src);
                        } else {
                            img = new Image();

                            img.src = child.props.src;
                            await img.decode();

                            images.set(child.props.src, img);
                        }

                        if (img.naturalHeight) {
                            length += img.naturalHeight;
                        } else {
                            length += 1;
                        }
                    }

                    length += 1;
                }
            }

            setImages(images);
            return length;
        } else if (
            content.hasOwnProperty("props") &&
            content.props.hasOwnProperty("children") &&
            content.props.children &&
            content.props.children.type === "img"
        ) {
            let img;
            if (images.has(content.props.children.props.src)) {
                img = images.get(content.props.children.props.src);
            } else {
                img = new Image();

                img.src = content.props.children.props.src;
                await img.decode();

                images.set(content.props.children.props.src, img);
                setImages(images);
            }

            if (img.naturalHeight) {
                return img.naturalHeight;
            } else {
                return 1;
            }
        } else {
            return 1;
        }
    }

    async function extractContent(
        content: any,
        pos: number,
        accum: number,
    ): Promise<any> {
        if (Array.isArray(content) === true) {
            for (let i = 0; i < content.length; i++) {
                const child = content[i];

                if (accum <= pos && accum + (await reactiveLength(child)) > pos) {
                    return extractContent(child, pos, accum);
                } else if (accum > pos) {
                    return;
                } else {
                    accum += await reactiveLength(child);
                }
            }
        } else if (
            content.hasOwnProperty("props") &&
            content.props.hasOwnProperty("children") &&
            Array.isArray(content.props.children) === true
        ) {
            for (let i = 0; i < content.props.children.length; i++) {
                const child = content.props.children[i];

                if (accum <= pos && accum + (await reactiveLength(child)) > pos) {
                    return extractContent(child, pos, accum);
                } else if (accum > pos) {
                    return;
                } else {
                    accum += await reactiveLength(child);
                }
            }
        } else {
            if (pos === accum) {
                return content;
            }
        }
    }

    const getPosts = async () => {
        if (uid !== "") {
            let q;
            if (postLastVisible === -1) {
                return;
            } else if (postLastVisible !== undefined) {
                q = query(
                    collection(firestore, "user", uid, "post"),
                    orderBy("timestamp", "desc"),
                    limit(10),
                    startAfter(postLastVisible),
                );
            } else {
                dispatch(profileViewActions.resetPostBlocked());
                dispatch(profileViewActions.resetPosts());

                dispatch(profileViewActions.resetPostFetching());
                dispatch(profileViewActions.resetPostFetched());

                q = query(
                    collection(firestore, "user", uid, "post"),
                    orderBy("timestamp", "desc"),
                    limit(10),
                );
            }

            onSnapshot(q, async (snapshot) => {
                const newPosts = snapshot.docs.map((elem) => elem.data());

                dispatch(profileViewActions.incrementPostFetching(newPosts.length));

                for (const elem of newPosts) {
                    getPost(elem.documentId);
                }

                if (snapshot.docs.length === 0) {
                    setPostLastVisible(-1);
                } else {
                    setPostLastVisible(snapshot.docs[snapshot.docs.length - 1]);
                }
            });
        }
    };

    const getPost = async (documentId: string) => {
        const boardDocRef = doc(firestore, "board", documentId);

        getDoc(boardDocRef).then(async (boardDocSnap) => {
            if (boardDocSnap.exists()) {
                let authorAvatarUrl = "";
                let authorNickname = "";

                const boardData = boardDocSnap.data()!;

                const authorUid = boardData.authorUid;

                if (auth.currentUser !== null) {
                    const blockDocRef = doc(
                        firestore,
                        "user",
                        auth.currentUser.uid,
                        "block",
                        authorUid,
                    );
                    const blockDocSnap = await getDoc(blockDocRef);
                    if (blockDocSnap.exists()) {
                        dispatch(profileViewActions.appendPostBlocked(documentId));
                        dispatch(profileViewActions.incrementPostFetched(1));
                        return;
                    }
                }

                const userDocRef = doc(firestore, "user", authorUid);

                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();
                    authorAvatarUrl = userData.avatarUrl || "";
                    authorNickname = userData.nickname || "";
                } else {
                    dispatch(profileViewActions.appendPostBlocked(documentId));
                    dispatch(profileViewActions.incrementPostFetched(1));
                    return;
                }

                const parsedContent = parse(boardData.content);

                const lengthLimit = 700;
                const length = await reactiveLength(parsedContent);

                let thumbnailContent: any[] = [];
                let thumbnailImageSizes: any[] = [];

                if (length >= 100) {
                    let index = 0;
                    let extractLength = 0;

                    let batchedContent = [];

                    while (index < 5 && extractLength < lengthLimit) {
                        let ec = await extractContent(parsedContent, index, 0);

                        if (
                            ec &&
                            ec.props !== undefined &&
                            ec.props.children !== undefined
                        ) {
                            if (ec.props.children.type === "img") {
                                let img;
                                if (images.has(ec.props.children.props.src)) {
                                    img = images.get(ec.props.children.props.src);
                                } else {
                                    img = new Image();

                                    img.src = ec.props.children.props.src;
                                    await img.decode();

                                    images.set(ec.props.children.props.src, img);
                                    setImages(images);
                                }

                                if (img.naturalHeight) {
                                    if (extractLength + img.naturalHeight > lengthLimit) {
                                        thumbnailImageSizes = [
                                            ...thumbnailImageSizes,
                                            {
                                                src: ec.props.children.props.src,
                                                width: img.naturalWidth,
                                                height: lengthLimit - extractLength,
                                            },
                                        ];

                                        extractLength += lengthLimit - extractLength;
                                    } else {
                                        thumbnailImageSizes = [
                                            ...thumbnailImageSizes,
                                            {
                                                src: ec.props.children.props.src,
                                                width: img.naturalWidth,
                                                height: img.naturalHeight,
                                            },
                                        ];

                                        extractLength += img.naturalHeight;
                                    }
                                } else {
                                    extractLength += 1;
                                }
                            } else if (ec.props.children.length) {
                                extractLength += ec.props.children.length;
                            } else {
                                extractLength += 1;
                            }

                            batchedContent.push(ec);
                        } else if (ec && ec.type === "img") {
                            let img;
                            if (images.has(ec.props!.src)) {
                                img = images.get(ec.props.src);
                            } else {
                                img = new Image();

                                img.src = ec.props.src;
                                await img.decode();

                                images.set(ec.props.src, img);
                                setImages(images);
                            }

                            if (img.naturalHeight) {
                                if (extractLength + img.naturalHeight > lengthLimit) {
                                    thumbnailImageSizes = [
                                        ...thumbnailImageSizes,
                                        {
                                            src: ec.props.src,
                                            width: img.naturalWidth,
                                            height: lengthLimit - extractLength,
                                        },
                                    ];

                                    extractLength += lengthLimit - extractLength;
                                } else {
                                    thumbnailImageSizes = [
                                        ...thumbnailImageSizes,
                                        {
                                            src: ec.props.src,
                                            width: img.naturalWidth,
                                            height: img.naturalHeight,
                                        },
                                    ];

                                    extractLength += img.naturalHeight;
                                }
                            } else {
                                extractLength += 1;
                            }

                            batchedContent.push(ec);
                        } else if (ec) {
                            extractLength += 1;

                            batchedContent.push(ec);
                        } else {
                            extractLength += 1;
                        }

                        index += 1;
                    }

                    thumbnailContent = batchedContent;
                } else {
                    thumbnailContent = [parsedContent];
                }

                const upRef = doc(firestore, "metadata", documentId, "up", "summary");
                const downRef = doc(
                    firestore,
                    "metadata",
                    documentId,
                    "down",
                    "summary",
                );
                const commentRef = doc(
                    firestore,
                    "board",
                    documentId,
                    "comment",
                    "summary",
                );

                const upSnap = await getDoc(upRef);
                const downSnap = await getDoc(downRef);
                const commentSnap = await getDoc(commentRef);

                let numUps = 0;
                let numDowns = 0;
                let numComments = 0;

                if (upSnap.exists()) {
                    numUps = upSnap.data().numUps || 0;
                }

                if (downSnap.exists()) {
                    numDowns = downSnap.data().numDowns || 0;
                }

                if (commentSnap.exists()) {
                    numComments = commentSnap.data().numComments || 0;
                }

                let clickUp = false;
                let clickDown = false;
                let clickBookmark = false;

                if (auth.currentUser !== null) {
                    const upDocRef = doc(
                        firestore,
                        "metadata",
                        documentId,
                        "up",
                        auth.currentUser.uid,
                    );

                    const downDocRef = doc(
                        firestore,
                        "metadata",
                        documentId,
                        "down",
                        auth.currentUser.uid,
                    );

                    const bookmarkDocRef = doc(
                        firestore,
                        "user",
                        auth.currentUser.uid,
                        "bookmark",
                        documentId,
                    );

                    await getDoc(upDocRef).then((docSnap) => {
                        if (docSnap.exists()) {
                            clickUp = true;
                        }
                    });

                    await getDoc(downDocRef).then((docSnap) => {
                        if (docSnap.exists()) {
                            clickDown = true;
                        }
                    });

                    await getDoc(bookmarkDocRef).then((docSnap) => {
                        if (docSnap.exists()) {
                            clickBookmark = true;
                        }
                    });
                }

                const document: DocumentState = {
                    documentId: boardData.documentId,
                    authorUid: boardData.authorUid,
                    authorAvatarUrl: authorAvatarUrl,
                    authorNickname: authorNickname,
                    timestamp: boardData.timestamp,
                    content: boardData.content,
                    thumbnailContent: thumbnailContent,
                    thumbnailImageSizes: thumbnailImageSizes,
                    hashtags: boardData.hashtags.split(","),
                    numUps: numUps,
                    numDowns: numDowns,
                    numComments: numComments,
                    numTokens: 0,
                    clickUp: clickUp,
                    clickDown: clickDown,
                    clickBookmark: clickBookmark,
                };

                if (boardData.timestamp < timestamp) {
                    dispatch(profileViewActions.appendPost(document));
                }

                dispatch(profileViewActions.incrementPostFetched(1));
            } else {
                dispatch(profileViewActions.appendPostBlocked(documentId));
                dispatch(profileViewActions.incrementPostFetched(1));
            }
        });
    };

    const getItems = () => {
        if (tab === "followers") {
            getFollowers();
        } else if (tab === "followings") {
            getFollowings();
        } else if (tab === "posts") {
            getPosts();
        }
    };

    useEffect(() => {
        getItems();
    }, [tab, uid]);

    useEffect(() => {
        const handleScroll = () => {
            if (
                tab === "following" &&
                followingFetched === followingFetching &&
                window.scrollY > window.outerHeight / 3
            ) {
                getItems();
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [followingFetched, followingFetching]);

    useEffect(() => {
        const handleScroll = () => {
            if (
                tab === "follower" &&
                followingFetched === followingFetching &&
                window.scrollY > window.outerHeight / 3
            ) {
                getItems();
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [followerFetched, followerFetching]);

    useEffect(() => {
        const handleScroll = () => {
            if (
                tab === "post" &&
                followingFetched === followingFetching &&
                window.scrollY > window.outerHeight / 3
            ) {
                getItems();
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [postFetched, postFetching]);

    useBottomScrollListener(getItems);

    if (tab === "followers") {
        if (followerFetched < followerFetching) {
            return (
                <div className="ProfileView-Details">
                    <LoadingBar/>
                    <Followers/>
                </div>
            );
        } else {
            if (followers.length > 0) {
                return (
                    <div className="ProfileView-Details">
                        <Followers/>
                    </div>
                );
            } else {
                return (
                    <div className="ProfileView-Followers-Void">
                        <p>팔로워가 없습니다.</p>
                    </div>
                );
            }
        }
    } else if (tab === "followings") {
        if (followingFetched < followingFetching) {
            return (
                <div className="ProfileView-Details">
                    <LoadingBar/>
                    <Followings/>
                </div>
            );
        } else {
            if (followings.length > 0) {
                return (
                    <div className="ProfileView-Details">
                        <Followings/>
                    </div>
                );
            } else {
                return (
                    <div className="ProfileView-Followings-Void">
                        <p>팔로잉이 없습니다.</p>
                    </div>
                );
            }
        }
    } else {
        if (postFetched < postFetching) {
            return (
                <div className="ProfileView-Details">
                    <LoadingBar/>
                    <Posts/>
                </div>
            );
        } else {
            if (posts.length > 0) {
                return (
                    <div className="ProfileView-Details">
                        <Posts/>
                    </div>
                );
            } else {
                return (
                    <div className="ProfileView-Posts-Void">
                        <p>게시글이 없습니다.</p>
                    </div>
                );
            }
        }
    }
}

function LoadingBar() {
    return (
        <div className="ProfileView-LoadingBar">
            <LinearProgress/>
        </div>
    );
}

function Followers() {
    const followers = useAppSelector((state) => state.profileView.followers);

    const f = followers.map((item, _) => {
        return <Follower follower={item}/>;
    });

    return <div className="ProfileView-Followers">{f}</div>;
}

function Follower(props: { follower: UserState }) {
    return (
        <div className="ProfileView-Follower">
            <UserHeader user={props.follower}/>
        </div>
    );
}

function Followings() {
    const followings = useAppSelector((state) => state.profileView.followings);

    const f = followings.map((item, _) => {
        return <Following following={item}/>;
    });

    return <div className="ProfileView-Followings">{f}</div>;
}

function Following(props: { following: UserState }) {
    return (
        <div className="ProfileView-Following">
            <UserHeader user={props.following}/>
        </div>
    );
}

function UserHeader(props: { user: UserState }) {
    return (
        <div className="ProfileView-UserHeader">
            <UserAvatar user={props.user}/>
            <UserNames user={props.user}/>
            <UserFollowOrUnfollow user={props.user}/>
        </div>
    );
}

function UserAvatar(props: { user: UserState }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/profile_view/${props.user.uid}`);
    };

    if (props.user.avatarUrl !== "") {
        return (
            <div className="ProfileView-UserAvatar">
                <img src={props.user.avatarUrl} onClick={onClick}/>
            </div>
        );
    } else {
        return (
            <div className="ProfileView-UserAvatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        );
    }
}

function UserNames(props: { user: UserState }) {
    return (
        <div className="ProfileView-UserNames">
            <UserName user={props.user}/>
            <UserNickname user={props.user}/>
        </div>
    );
}

function UserName(props: { user: UserState }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/profile_view/${props.user.uid}`);
    };

    if (props.user.name !== "") {
        return (
            <div className="ProfileView-UserName">
                <button onClick={onClick}>{props.user.name}</button>
            </div>
        );
    } else {
        return (
            <div className="ProfileView-UserName">
                <button onClick={onClick}>이름 없음</button>
            </div>
        );
    }
}

function UserNickname(props: { user: UserState }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/profile_view/${props.user.uid}`);
    };

    if (props.user.nickname !== "") {
        return (
            <div className="ProfileView-UserNickname">
                <button onClick={onClick}>{props.user.nickname}</button>
            </div>
        );
    } else {
        return (
            <div className="ProfileView-UserNickname">
                <button onClick={onClick}>닉네임 없음</button>
            </div>
        );
    }
}

function UserFollowOrUnfollow(props: { user: UserState }) {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            if (auth.currentUser!.uid === props.user.uid) {
                alert("나 자신을 팔로우 할 수 없습니다");
            } else {
                dispatch(coreActions.setIsLoading(true));

                if (props.user.isFollowing) {
                    const followingRef = doc(
                        firestore,
                        "user",
                        auth.currentUser!.uid,
                        "following",
                        props.user.uid,
                    );

                    const followerRef = doc(
                        firestore,
                        "user",
                        props.user.uid,
                        "follower",
                        auth.currentUser!.uid,
                    );

                    await deleteDoc(followingRef);
                    await deleteDoc(followerRef);

                    dispatch(profileViewActions.unsetFollowersFollowing(props.user));
                } else {
                    const followingRef = doc(
                        firestore,
                        "user",
                        auth.currentUser!.uid,
                        "following",
                        props.user.uid,
                    );

                    const followerRef = doc(
                        firestore,
                        "user",
                        props.user.uid,
                        "follower",
                        auth.currentUser!.uid,
                    );

                    const timestamp = Date.now();

                    await setDoc(followingRef, {
                        uid: props.user.uid,
                        timestamp: timestamp,
                    });

                    await setDoc(followerRef, {
                        uid: auth.currentUser!.uid,
                        timestamp: timestamp,
                    });

                    dispatch(profileViewActions.setFollowersFollowing(props.user));
                }

                dispatch(coreActions.setIsLoading(false));
            }
        } else {
            navigate("/login");
        }
    };

    if (props.user.isFollowing) {
        return (
            <div className="ProfileView-UserFollowOrUnfollow">
                <button onClick={onClick}>팔로잉 중단</button>
            </div>
        );
    } else {
        return (
            <div className="ProfileView-UserFollowOrUnfollow">
                <button onClick={onClick}>팔로잉</button>
            </div>
        );
    }
}

function Posts() {
    const posts = useAppSelector((state) => state.profileView.posts);

    return (
        <div className="ProfileView-Posts">
            {posts.map((item, _) => {
                return <Document document={item}/>;
            })}
        </div>
    );
}

function Document(props: { document: DocumentState }) {
    if (props.document.hashtags.length > 0 && props.document.hashtags[0] !== "") {
        return (
            <div className="ProfileView-Document">
                <DocumentHeader document={props.document}/>
                <ThumbnailContent document={props.document}/>
                <Hashtags document={props.document}/>
                <ReadMore document={props.document}/>
                <Summary document={props.document}/>
            </div>
        );
    } else {
        return (
            <div className="ProfileView-Document">
                <DocumentHeader document={props.document}/>
                <ThumbnailContent document={props.document}/>
                <ReadMore document={props.document}/>
                <Summary document={props.document}/>
            </div>
        );
    }
}

function DocumentHeader(props: { document: DocumentState }) {
    return (
        <div className="ProfileView-DocumentHeader">
            <DocumentAvatar document={props.document}/>
            <DocumentNickname document={props.document}/>
            <Time document={props.document}/>
            <Bookmark document={props.document}/>
            <Misc document={props.document}/>
        </div>
    );
}

function DocumentAvatar(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/profile_view/${props.document.authorUid}`);
    };

    if (props.document.authorAvatarUrl !== "") {
        return (
            <div className="ProfileView-DocumentAvatar">
                <img src={props.document.authorAvatarUrl} onClick={onClick}/>
            </div>
        );
    } else {
        return (
            <div className="ProfileView-DocumentAvatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        );
    }
}

function DocumentNickname(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/profile_view/${props.document.authorUid}`);
    };

    if (props.document.authorNickname !== "") {
        return (
            <div className="ProfileView-DocumentNickname">
                <button onClick={onClick}>{props.document.authorNickname}</button>
            </div>
        );
    } else {
        return (
            <div className="ProfileView-DocumentNickname">
                <button onClick={onClick}>닉네임 없음</button>
            </div>
        );
    }
}

function Time(props: { document: DocumentState }) {
    const endTimestamp = Date.now();

    const diff = endTimestamp - props.document.timestamp;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 1) {
        return (
            <div className="ProfileView-Time">
                <p>방금 전</p>
            </div>
        );
    } else if (hours < 1) {
        return (
            <div className="ProfileView-Time">
                <p>{minutes} 분 전</p>
            </div>
        );
    } else if (days < 1) {
        return (
            <div className="ProfileView-Time">
                <p>{hours} 시간 전</p>
            </div>
        );
    } else {
        const date = moment(props.document.timestamp);

        return (
            <div className="ProfileView-Time">
                <p>{date.format("YYYY-MM-DD")}</p>
            </div>
        );
    }
}

function Bookmark(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            const bookmarkDocRef = doc(
                firestore,
                "user",
                auth.currentUser.uid,
                "bookmark",
                props.document.documentId,
            );

            if (props.document.clickBookmark) {
                dispatch(
                    profileViewActions.unsetDocumentClickBookmark(
                        props.document.documentId,
                    ),
                );

                await deleteDoc(bookmarkDocRef);
            } else {
                dispatch(
                    profileViewActions.setDocumentClickBookmark(
                        props.document.documentId,
                    ),
                );

                const timestamp = Date.now();

                await setDoc(bookmarkDocRef, {
                    documentId: props.document.documentId,
                    uid: auth.currentUser!.uid,
                    timestamp: timestamp,
                });
            }
        } else {
            navigate("/login");
        }
    };

    if (auth.currentUser !== null) {
        if (props.document.clickBookmark) {
            return (
                <div className="ProfileView-Bookmark">
                    <button onClick={onClick}>
                        <img src={bookmarkFocus}/>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="ProfileView-Bookmark">
                    <button onClick={onClick}>
                        <img src={bookmark}/>
                    </button>
                </div>
            );
        }
    } else {
        return (
            <div className="ProfileView-Bookmark">
                <button onClick={onClick}>
                    <img src={bookmark}/>
                </button>
            </div>
        );
    }
}

function Misc(props: { document: DocumentState }) {
    const isAdmin = useAppSelector((state) => state.core.isAdmin);

    const auth = getAuth();

    const [click, setClick] = useState(false);

    const onClick = () => {
        if (click) {
            setClick(false);
        } else {
            setClick(true);
        }
    };

    if (click) {
        if (
            auth.currentUser !== null &&
            (props.document.authorUid === auth.currentUser.uid || isAdmin)
        ) {
            return (
                <div className="ProfileView-Misc">
                    <button onClick={onClick}>
                        <img src={threeDots}/>
                    </button>
                    <div className="ProfileView-MiscFocus-Mine">
                        <Delete document={props.document}/>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="ProfileView-Misc">
                    <button onClick={onClick}>
                        <img src={threeDots}/>
                    </button>
                    <div className="ProfileView-MiscFocus">
                        <Block document={props.document}/>
                        <Report document={props.document}/>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className="ProfileView-Misc">
                <button onClick={onClick}>
                    <img src={threeDots}/>
                </button>
            </div>
        );
    }
}

function Block(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            if (auth.currentUser.uid !== props.document.authorNickname) {
                const answer = window.confirm(
                    `${props.document.authorNickname}님의 게시글을 모두 차단하겠습니까?`,
                );

                if (answer) {
                    const timestamp = Date.now();

                    const docRef = doc(
                        firestore,
                        "user",
                        auth.currentUser.uid,
                        "block",
                        props.document.authorUid,
                    );

                    await setDoc(docRef, {
                        uid: props.document.authorUid,
                        timestamp: timestamp,
                    });

                    navigate(0);
                }
            } else {
                alert("본인을 차단할 수는 없습니다.");
            }
        } else {
            navigate("/login");
        }
    };

    return (
        <div className="ProfileView-Block">
            <button onClick={onClick}>
                <img src={block}/>
                <p>차단하기</p>
            </button>
        </div>
    );
}

function Report(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const auth = getAuth();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            navigate("/report", {
                state: {
                    documentId: props.document.documentId,
                    commentId: "",
                    replyId: "",
                },
            });
        } else {
            navigate("/login");
        }
    };

    return (
        <div className="ProfileView-Report">
            <button onClick={onClick}>
                <img src={notify}/>
                <p>신고하기</p>
            </button>
        </div>
    );
}

function Delete(props: { document: DocumentState }) {
    const auth = getAuth();
    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    const onClick = async () => {
        const answer = window.confirm("정말 삭제하시겠습니까?");

        if (answer) {
            dispatch(coreActions.setIsLoading(true));

            await deleteDoc(doc(firestore, "metadata", props.document.documentId));
            await deleteDoc(
                doc(
                    firestore,
                    "user",
                    props.document.authorUid,
                    "post",
                    props.document.documentId,
                ),
            );

            for (const hashtag of props.document.hashtags) {
                if (hashtag !== "") {
                    await deleteDoc(
                        doc(
                            firestore,
                            "hashtag",
                            hashtag,
                            "post",
                            props.document.documentId,
                        ),
                    );
                }
            }

            await deleteDoc(doc(firestore, "board", props.document.documentId));

            dispatch(profileViewActions.removeDocument(props.document));

            dispatch(coreActions.setIsLoading(false));
        }
    };

    return (
        <div className="ProfileView-Delete">
            <button onClick={onClick}>
                <img src={trash}/>
                <p>삭제하기</p>
            </button>
        </div>
    );
}

function ThumbnailContent(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions(),
    );

    const d: DocumentState = {
        documentId: props.document.documentId,
        authorUid: props.document.authorUid,
        authorAvatarUrl: props.document.authorAvatarUrl,
        authorNickname: props.document.authorNickname,
        timestamp: props.document.timestamp,
        content: props.document.content,
        thumbnailContent: [],
        thumbnailImageSizes: [],
        hashtags: props.document.hashtags,
        numUps: props.document.numUps,
        numDowns: props.document.numDowns,
        numComments: props.document.numComments,
        numTokens: props.document.numTokens,
        clickUp: props.document.clickUp,
        clickDown: props.document.clickDown,
        clickBookmark: props.document.clickBookmark,
    };

    const onClick = () => {
        navigate(`/read/${props.document.documentId}`, {
            state: {
                document: d,
            },
        });
        navigate(0);
    };

    function getWindowDimensions() {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height,
        };
    }

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className="ProfileView-ThumbnailContent">
            <button onClick={onClick}>
                {props.document.thumbnailContent.map((item, _) => {
                    if (item.props !== undefined && item.props.children !== undefined) {
                        if (item.props.children.type === "img") {
                            for (let imageSize of props.document.thumbnailImageSizes) {
                                if (imageSize.src === item.props.children.props.src) {
                                    if (
                                        0.9 * 0.44 * windowDimensions.width - 20 <
                                        imageSize.width
                                    ) {
                                        return (
                                            <img
                                                style={{
                                                    width: 0.85 * 0.44 * windowDimensions.width - 20,
                                                    maxHeight: imageSize.height,
                                                    objectFit: "cover",
                                                    objectPosition: "0% 0%",
                                                    backgroundColor: "transparent",
                                                }}
                                                src={item.props.children.props.src}
                                            />
                                        );
                                    } else {
                                        return (
                                            <img
                                                style={{
                                                    width: imageSize.width,
                                                    maxHeight: imageSize.height,
                                                    objectFit: "cover",
                                                    objectPosition: "0% 0%",
                                                    backgroundColor: "transparent",
                                                }}
                                                src={item.props.children.props.src}
                                            />
                                        );
                                    }
                                }
                            }

                            return item;
                        } else {
                            return item;
                        }
                    } else if (item.type === "img" && item.props !== undefined) {
                        for (let imageSize of props.document.thumbnailImageSizes) {
                            if (imageSize.src === item.props.src) {
                                if (
                                    0.9 * 0.44 * windowDimensions.width - 20 <
                                    imageSize.width
                                ) {
                                    return (
                                        <img
                                            style={{
                                                width: 0.85 * 0.44 * windowDimensions.width - 20,
                                                maxHeight: imageSize.height,
                                                objectFit: "cover",
                                                objectPosition: "0% 0%",
                                                backgroundColor: "transparent",
                                            }}
                                            src={item.props.src}
                                        />
                                    );
                                } else {
                                    return (
                                        <img
                                            style={{
                                                width: imageSize.width,
                                                maxHeight: imageSize.height,
                                                objectFit: "cover",
                                                objectPosition: "0% 0%",
                                                backgroundColor: "transparent",
                                            }}
                                            src={item.props.src}
                                        />
                                    );
                                }
                            }
                        }

                        return item;
                    } else {
                        return item;
                    }
                })}
            </button>
        </div>
    );
}

function Hashtags(props: { document: DocumentState }) {
    return (
        <div className="ProfileView-Hashtags">
            {
                <ul>
                    {props.document.hashtags.map((hashtag, index) => (
                        <li key={index}>
                            <Hashtag hashtag={hashtag}/>
                        </li>
                    ))}
                </ul>
            }
        </div>
    );
}

function Hashtag(props: { hashtag: string }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/search_query/${props.hashtag.slice(1)}`);
    };

    return (
        <div className="ProfileView-Hashtag">
            <img src={hashtag}/>
            <button className="ProfileView-Hashtag-tag" onClick={onClick}>
                {props.hashtag.slice(1)}
            </button>
        </div>
    );
}

function ReadMore(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const d: DocumentState = {
        documentId: props.document.documentId,
        authorUid: props.document.authorUid,
        authorAvatarUrl: props.document.authorAvatarUrl,
        authorNickname: props.document.authorNickname,
        timestamp: props.document.timestamp,
        content: props.document.content,
        thumbnailContent: [],
        thumbnailImageSizes: [],
        hashtags: props.document.hashtags,
        numUps: props.document.numUps,
        numDowns: props.document.numDowns,
        numComments: props.document.numComments,
        numTokens: props.document.numTokens,
        clickUp: props.document.clickUp,
        clickDown: props.document.clickDown,
        clickBookmark: props.document.clickBookmark,
    };

    const onClick = () => {
        navigate(`/read/${props.document.documentId}`, {
            state: {
                document: d,
            },
        });
        navigate(0);
    };

    return (
        <div className="ProfileView-ReadMore">
            <button onClick={onClick}>
                <img src={more}/>
            </button>
        </div>
    );
}

function Summary(props: { document: DocumentState }) {
    return (
        <div className="ProfileView-Summary">
            <NumUps document={props.document}/>
            <NumDowns document={props.document}/>
            <NumComments document={props.document}/>
            <NumTokens document={props.document}/>
        </div>
    );
}

function NumUps(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            const upDocRef = doc(
                firestore,
                "metadata",
                props.document.documentId,
                "up",
                auth.currentUser.uid,
            );

            if (props.document.clickUp) {
                dispatch(
                    profileViewActions.unsetDocumentClickUp(props.document.documentId),
                );

                await deleteDoc(upDocRef);
            } else {
                dispatch(
                    profileViewActions.setDocumentClickUp(props.document.documentId),
                );

                const upTimestamp = Date.now();

                await setDoc(upDocRef, {
                    uid: auth.currentUser!.uid,
                    timestamp: upTimestamp,
                });
            }
        } else {
            navigate("/login");
        }
    };

    if (auth.currentUser !== null) {
        if (props.document.clickUp) {
            return (
                <div className="ProfileView-NumUps-Focus">
                    <button onClick={onClick}>
                        <img src={upFocus}/>
                        <p>{props.document.numUps}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="ProfileView-NumUps">
                    <button onClick={onClick}>
                        <img src={up}/>
                        <p>{props.document.numUps}</p>
                    </button>
                </div>
            );
        }
    } else {
        return (
            <div className="ProfileView-NumUps">
                <button onClick={onClick}>
                    <img src={up}/>
                    <p>{props.document.numUps}</p>
                </button>
            </div>
        );
    }
}

function NumDowns(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            const downDocRef = doc(
                firestore,
                "metadata",
                props.document.documentId,
                "down",
                auth.currentUser.uid,
            );

            if (props.document.clickDown) {
                dispatch(
                    profileViewActions.unsetDocumentClickDown(props.document.documentId),
                );

                await deleteDoc(downDocRef);
            } else {
                dispatch(
                    profileViewActions.setDocumentClickDown(props.document.documentId),
                );

                const downTimestamp = Date.now();

                await setDoc(downDocRef, {
                    uid: auth.currentUser!.uid,
                    timestamp: downTimestamp,
                });
            }
        } else {
            navigate("/login");
        }
    };

    if (auth.currentUser !== null) {
        if (props.document.clickDown) {
            return (
                <div className="ProfileView-NumDowns-Focus">
                    <button onClick={onClick}>
                        <img src={downFocus}/>
                        <p>{props.document.numDowns}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="ProfileView-NumDowns">
                    <button onClick={onClick}>
                        <img src={down}/>
                        <p>{props.document.numDowns}</p>
                    </button>
                </div>
            );
        }
    } else {
        return (
            <div className="ProfileView-NumDowns">
                <button onClick={onClick}>
                    <img src={down}/>
                    <p>{props.document.numDowns}</p>
                </button>
            </div>
        );
    }
}

function NumComments(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const d: DocumentState = {
        documentId: props.document.documentId,
        authorUid: props.document.authorUid,
        authorAvatarUrl: props.document.authorAvatarUrl,
        authorNickname: props.document.authorNickname,
        timestamp: props.document.timestamp,
        content: props.document.content,
        thumbnailContent: [],
        thumbnailImageSizes: [],
        hashtags: props.document.hashtags,
        numUps: props.document.numUps,
        numDowns: props.document.numDowns,
        numComments: props.document.numComments,
        numTokens: props.document.numTokens,
        clickUp: props.document.clickUp,
        clickDown: props.document.clickDown,
        clickBookmark: props.document.clickBookmark,
    };

    const onClick = () => {
        navigate(`/read/${props.document.documentId}`, {
            state: {
                document: d,
            },
        });
        navigate(0);
    };

    return (
        <div className="ProfileView-NumComments">
            <button onClick={onClick}>
                <img src={comment}/>
                <p>{props.document.numComments}</p>
            </button>
        </div>
    );
}

function NumTokens(props: { document: DocumentState }) {
    const [numTokens, setNumTokens] = useState(0);

    return (
        <div className="ProfileView-NumTokens">
            <button>
                <p>TOKEN</p>
                <ComingSoon/>
            </button>
        </div>
    );
}

function ComingSoon() {
    return (
        <div className="ProfileView-ComingSoon">
            <button>
                <img src={comingSoon}/>
            </button>
        </div>
    );
}
