import sidebarBase from "../asset/image/mobile_sidebar_base.png";
import sidebarHome from "../asset/image/sidebar_home.png";
import sidebarSearch from "../asset/image/sidebar_search.png";
import sidebarPlus from "../asset/image/sidebar_plus.png";
import sidebarBell from "../asset/image/sidebar_bell.png";
import sidebarProfile from "../asset/image/sidebar_profile.png";
import sidebarHomeFocus from "../asset/image/sidebar_home_focus.png";
import sidebarSearchFocus from "../asset/image/sidebar_search_focus.png";
import sidebarBellFocus from "../asset/image/sidebar_bell_focus.png";
import sidebarProfileFocus from "../asset/image/sidebar_profile_focus.png";
import sidebarBellFocusRed from "../asset/image/sidebar_bell_focus_red.png";
import sidebarBellRed from "../asset/image/sidebar_bell_red.png";
import "./mobile_sidebar.css";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../module/hook";
import {coreActions} from "../module/core";
import React, {useEffect, useState} from "react";
import {getAuth} from "firebase/auth";
import {collection, getDocs, getFirestore} from "firebase/firestore";

export function MobileSidebar() {
    return (
        <div className="MobileSidebar">
            <MobileSidebarBase/>
            <MobileSidebarHome/>
            <MobileSidebarSearch/>
            <MobileSidebarPlus/>
            <MobileSidebarBell/>
            <MobileSidebarProfile/>
        </div>
    );
}

function MobileSidebarBase() {
    if (window.innerWidth <= 768) {
        return (
            <div className="MobileSidebar-Base-Phone">
                <img src={sidebarBase}/>
            </div>
        );
    } else {
        return (
            <div className="MobileSidebar-Base-Tablet">
                <img src={sidebarBase}/>
            </div>
        );
    }
}

function MobileSidebarHome() {
    const navigate = useNavigate();

    const focus = useAppSelector((state) => state.core.focus);

    const dispatch = useAppDispatch();

    const onClick = () => {
        dispatch(coreActions.setFocus("recommendation"));

        navigate("/");
    };

    if (focus === "recommendation") {
        if (window.innerWidth <= 768) {
            return (
                <div className="MobileSidebar-Home-Phone">
                    <img src={sidebarHomeFocus} onClick={onClick}/>
                </div>
            );
        } else {
            return (
                <div className="MobileSidebar-Home-Tablet">
                    <img src={sidebarHomeFocus} onClick={onClick}/>
                </div>
            );
        }
    } else {
        if (window.innerWidth <= 768) {
            return (
                <div className="MobileSidebar-Home-Phone">
                    <img src={sidebarHome} onClick={onClick}/>
                </div>
            );
        } else {
            return (
                <div className="MobileSidebar-Home-Tablet">
                    <img src={sidebarHome} onClick={onClick}/>
                </div>
            );
        }
    }
}

function MobileSidebarSearch() {
    const navigate = useNavigate();

    const focus = useAppSelector((state) => state.core.focus);

    const dispatch = useAppDispatch();

    const onClick = () => {
        dispatch(coreActions.setFocus("search"));

        navigate("/search");
        navigate(0);
    };

    if (focus === "search") {
        if (window.innerWidth <= 768) {
            return (
                <div className="MobileSidebar-Search-Phone">
                    <img src={sidebarSearchFocus} onClick={onClick}/>
                </div>
            );
        } else {
            return (
                <div className="MobileSidebar-Search-Tablet">
                    <img src={sidebarSearchFocus} onClick={onClick}/>
                </div>
            );
        }
    } else {
        if (window.innerWidth <= 768) {
            return (
                <div className="MobileSidebar-Search-Phone">
                    <img src={sidebarSearch} onClick={onClick}/>
                </div>
            );
        } else {
            return (
                <div className="MobileSidebar-Search-Tablet">
                    <img src={sidebarSearch} onClick={onClick}/>
                </div>
            );
        }
    }
}

function MobileSidebarPlus() {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const onClick = () => {
        dispatch(coreActions.setFocus("write"));

        navigate("/write");
    };

    if (window.innerWidth <= 768) {
        return (
            <div className="MobileSidebar-Plus-Phone">
                <img src={sidebarPlus} onClick={onClick}/>
            </div>
        );
    } else {
        return (
            <div className="MobileSidebar-Plus-Tablet">
                <img src={sidebarPlus} onClick={onClick}/>
            </div>
        );
    }
}

function MobileSidebarBell() {
    const navigate = useNavigate();

    const focus = useAppSelector((state) => state.core.focus);
    const [hasNotifications, setHasNotifications] = useState(false);

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    useEffect(() => {
        if (auth.currentUser !== null) {
            const notificationCollectionRef = collection(
                firestore,
                "user",
                auth.currentUser!.uid,
                "notification",
            );

            getDocs(notificationCollectionRef).then(
                (notificationCollectionSnapshot) => {
                    if (notificationCollectionSnapshot.docs.length !== 0) {
                        setHasNotifications(true);
                    }
                },
            );
        }
    }, [auth.currentUser]);

    const onClick = () => {
        dispatch(coreActions.setFocus("notification"));

        navigate("/notification");
    };

    if (focus === "notification") {
        if (hasNotifications) {
            if (window.innerWidth <= 768) {
                return (
                    <div className="MobileSidebar-Bell-Phone">
                        <img src={sidebarBellFocusRed} onClick={onClick}/>
                    </div>
                );
            } else {
                return (
                    <div className="MobileSidebar-Bell-Tablet">
                        <img src={sidebarBellFocusRed} onClick={onClick}/>
                    </div>
                );
            }
        } else {
            if (window.innerWidth <= 768) {
                return (
                    <div className="MobileSidebar-Bell-Phone">
                        <img src={sidebarBellFocus} onClick={onClick}/>
                    </div>
                );
            } else {
                return (
                    <div className="MobileSidebar-Bell-Tablet">
                        <img src={sidebarBellFocus} onClick={onClick}/>
                    </div>
                );
            }
        }
    } else {
        if (hasNotifications) {
            if (window.innerWidth <= 768) {
                return (
                    <div className="MobileSidebar-Bell-Phone">
                        <img src={sidebarBellRed} onClick={onClick}/>
                    </div>
                );
            } else {
                return (
                    <div className="MobileSidebar-Bell-Tablet">
                        <img src={sidebarBellRed} onClick={onClick}/>
                    </div>
                );
            }
        } else {
            if (window.innerWidth <= 768) {
                return (
                    <div className="MobileSidebar-Bell-Phone">
                        <img src={sidebarBell} onClick={onClick}/>
                    </div>
                );
            } else {
                return (
                    <div className="MobileSidebar-Bell-Tablet">
                        <img src={sidebarBell} onClick={onClick}/>
                    </div>
                );
            }
        }
    }
}

function MobileSidebarProfile() {
    const navigate = useNavigate();

    const focus = useAppSelector((state) => state.core.focus);

    const dispatch = useAppDispatch();

    const onClick = () => {
        dispatch(coreActions.setFocus("profile"));

        navigate("/profile");
    };

    if (focus === "profile") {
        if (window.innerWidth <= 768) {
            return (
                <div className="MobileSidebar-Profile-Phone">
                    <img src={sidebarProfileFocus} onClick={onClick}/>
                </div>
            );
        } else {
            return (
                <div className="MobileSidebar-Profile-Tablet">
                    <img src={sidebarProfileFocus} onClick={onClick}/>
                </div>
            );
        }
    } else {
        if (window.innerWidth <= 768) {
            return (
                <div className="MobileSidebar-Profile-Phone">
                    <img src={sidebarProfile} onClick={onClick}/>
                </div>
            );
        } else {
            return (
                <div className="MobileSidebar-Profile-Tablet">
                    <img src={sidebarProfile} onClick={onClick}/>
                </div>
            );
        }
    }
}
