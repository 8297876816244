import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
} from "firebase/firestore";
import {Sidebar} from "../sidebar";
import {getAuth} from "firebase/auth";
import "./read.css";
import {useBottomScrollListener} from "react-bottom-scroll-listener";
import moment from "moment/moment";
import parse from "html-react-parser";
import {DocumentState} from "../../module/document";
import {readActions} from "../../module/read";
import {CommentState} from "../../module/comment";
import {useAppDispatch, useAppSelector} from "../../module/hook";
import {Header} from "../header";
import defaultAvatar from "../../asset/image/default_avatar.png";
import {Loading} from "../loading/loading";
import {coreActions} from "../../module/core";
import uuid from "react-uuid";
import threeDots from "../../asset/image/three_dots.png";
import comment from "../../asset/image/comment.png";
import bookmark from "../../asset/image/bookmark.png";
import bookmarkFocus from "../../asset/image/bookmark_focus.png";
import reply from "../../asset/image/reply.png";
import {ReplyState} from "../../module/reply";
import back from "../../asset/image/back.png";
import hashtag from "../../asset/image/hashtag.png";
import horizontalLine from "../../asset/image/horizontal_line.png";
import upFocus from "../../asset/image/up_focus.png";
import up from "../../asset/image/up.png";
import down from "../../asset/image/down.png";
import downFocus from "../../asset/image/down_focus.png";
import comingSoon from "../../asset/image/coming_soon.png";
import more from "../../asset/image/more.png";
import best from "../../asset/image/best.png";
import LinearProgress from "@mui/material/LinearProgress";
import block from "../../asset/image/block.png";
import notify from "../../asset/image/notify.png";
import trash from "../../asset/image/trash.png";
import {homeActions} from "../../module/home";
import {RightSidebar} from "../right_sidebar";

export function Read() {
    const {slug} = useParams();

    const location = useLocation();

    const auth = getAuth();
    const firestore = getFirestore();

    const isLoading = useAppSelector((state) => state.core.isLoading);
    const documentsFetched = useAppSelector(
        (state) => state.read.documentsFetched,
    );
    const documentsFetching = useAppSelector(
        (state) => state.read.documentsFetching,
    );
    const document = useAppSelector((state) => state.read.document);
    const comments = useAppSelector((state) => state.read.comments);
    const documents = useAppSelector((state) => state.read.documents);

    const dispatch = useAppDispatch();

    const [commentsLastVisible, setCommentsLastVisible] =
        useState<any>(undefined);
    const [commentsTimestamp, setCommentsTimestamp] = useState(Date.now());
    const [documentsLastVisible, setDocumentsLastVisible] =
        useState<any>(undefined);
    const [documentImages, setDocumentImages] = useState(new Map());

    const getMainDocument = async () => {
        if (slug! !== "") {
            dispatch(coreActions.setIsLoading(true));

            const docRef = doc(firestore, "board", slug!);
            const docSnap = await getDoc(docRef);
            const boardData = docSnap.data();

            let authorAvatarUrl = "";
            let authorNickname = "";

            const userDocRef = doc(firestore, "user", boardData!.authorUid);

            const userDocSnap = await getDoc(userDocRef);
            if (docSnap.exists()) {
                const data = userDocSnap.data();
                authorAvatarUrl = data!.avatarUrl || "";
                authorNickname = data!.nickname || "";
            } else {
                return;
            }

            const upRef = doc(
                firestore,
                "metadata",
                boardData!.documentId,
                "up",
                "summary",
            );
            const downRef = doc(
                firestore,
                "metadata",
                boardData!.documentId,
                "down",
                "summary",
            );
            const commentRef = doc(
                firestore,
                "board",
                boardData!.documentId,
                "comment",
                "summary",
            );

            const upSnap = await getDoc(upRef);
            const downSnap = await getDoc(downRef);
            const commentSnap = await getDoc(commentRef);

            let numUps = 0;
            let numDowns = 0;
            let numComments = 0;

            if (upSnap.exists()) {
                numUps = upSnap.data().numUps || 0;
            }

            if (downSnap.exists()) {
                numDowns = downSnap.data().numDowns || 0;
            }

            if (commentSnap.exists()) {
                numComments = commentSnap.data().numComments || 0;
            }

            let clickUp = false;
            let clickDown = false;
            let clickBookmark = false;

            if (auth.currentUser !== null) {
                const upDocRef = doc(
                    firestore,
                    "metadata",
                    boardData!.documentId,
                    "up",
                    auth.currentUser.uid,
                );

                const downDocRef = doc(
                    firestore,
                    "metadata",
                    boardData!.documentId,
                    "down",
                    auth.currentUser.uid,
                );

                const bookmarkDocRef = doc(
                    firestore,
                    "user",
                    auth.currentUser.uid,
                    "bookmark",
                    boardData!.documentId,
                );

                await getDoc(upDocRef).then((docSnap) => {
                    if (docSnap.exists()) {
                        clickUp = true;
                    }
                });

                await getDoc(downDocRef).then((docSnap) => {
                    if (docSnap.exists()) {
                        clickDown = true;
                    }
                });

                await getDoc(bookmarkDocRef).then((docSnap) => {
                    if (docSnap.exists()) {
                        clickBookmark = true;
                    }
                });
            }

            const document: DocumentState = {
                documentId: boardData!.documentId,
                authorUid: boardData!.authorUid,
                authorAvatarUrl: authorAvatarUrl,
                authorNickname: authorNickname,
                timestamp: boardData!.timestamp,
                content: boardData!.content,
                thumbnailContent: [],
                thumbnailImageSizes: [],
                hashtags: boardData!.hashtags.split(","),
                numUps: numUps,
                numDowns: numDowns,
                numComments: numComments,
                numTokens: 0,
                clickUp: clickUp,
                clickDown: clickDown,
                clickBookmark: clickBookmark,
            };

            dispatch(readActions.setDocument(document));

            dispatch(coreActions.setIsLoading(false));
        }
    };

    const getComments = () => {
        if (slug! !== "") {
            let q;
            if (commentsLastVisible === -1) {
                return;
            } else if (commentsLastVisible !== undefined) {
                q = query(
                    collection(firestore, "board", slug!, "comment"),
                    orderBy("timestamp", "desc"),
                    limit(10),
                    startAfter(commentsLastVisible),
                );
            } else {
                dispatch(readActions.resetCommentsFetching());
                dispatch(readActions.resetCommentsFetched());

                q = query(
                    collection(firestore, "board", slug!, "comment"),
                    orderBy("timestamp", "desc"),
                    limit(10),
                );
            }

            onSnapshot(q, async (snapshot) => {
                const newComments = snapshot.docs.map((elem) => elem.data());

                dispatch(readActions.incrementCommentsFetching(newComments.length));

                for (const elem of newComments) {
                    if (!elem.numComments) {
                        getComment(elem);
                    }
                }

                if (snapshot.docs.length === 0) {
                    setCommentsLastVisible(-1);
                } else {
                    setCommentsLastVisible(snapshot.docs[snapshot.docs.length - 1]);
                }
            });
        }
    };

    const getComment = async (elem: any) => {
        if (auth.currentUser !== null) {
            const blockDocRef = doc(
                firestore,
                "user",
                auth.currentUser.uid,
                "block",
                elem.authorUid,
            );
            const blockDocSnap = await getDoc(blockDocRef);
            if (blockDocSnap.exists()) {
                dispatch(readActions.appendCommentsBlocked(elem.commentId));
                dispatch(readActions.incrementCommentsFetched(1));
                return;
            }
        }

        let authorAvatarUrl = "";
        let authorNickname = "";

        const userDocRef = doc(firestore, "user", elem.authorUid);

        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
            const data = docSnap.data();
            authorAvatarUrl = data.avatarUrl || "";
            authorNickname = data.nickname || "";
        } else {
            dispatch(readActions.appendCommentsBlocked(elem.commentId));
            dispatch(readActions.incrementCommentsFetched(1));
            return;
        }

        const upRef = doc(
            firestore,
            "metadata",
            slug!,
            "comment",
            elem.commentId,
            "up",
            "summary",
        );
        const downRef = doc(
            firestore,
            "metadata",
            slug!,
            "comment",
            elem.commentId,
            "down",
            "summary",
        );
        const replyRef = doc(
            firestore,
            "board",
            slug!,
            "comment",
            elem.commentId,
            "reply",
            "summary",
        );

        const upSnap = await getDoc(upRef);
        const downSnap = await getDoc(downRef);
        const replySnap = await getDoc(replyRef);

        let numUps = 0;
        let numDowns = 0;
        let numReplies = 0;

        if (upSnap.exists()) {
            numUps = upSnap.data().numUps || 0;
        }

        if (downSnap.exists()) {
            numDowns = downSnap.data().numDowns || 0;
        }

        if (replySnap.exists()) {
            numReplies = replySnap.data().numReplies || 0;
        }

        let clickUp = false;
        let clickDown = false;

        if (auth.currentUser !== null) {
            const upDocRef = doc(
                firestore,
                "metadata",
                slug!,
                "comment",
                elem.commentId,
                "up",
                auth.currentUser.uid,
            );

            const downDocRef = doc(
                firestore,
                "metadata",
                slug!,
                "comment",
                elem.commentId,
                "down",
                auth.currentUser.uid,
            );

            await getDoc(upDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    clickUp = true;
                }
            });

            await getDoc(downDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    clickDown = true;
                }
            });
        }

        const comment: CommentState = {
            commentId: elem.commentId,
            authorUid: elem.authorUid,
            authorAvatarUrl: authorAvatarUrl,
            authorNickname: authorNickname,
            timestamp: elem.timestamp,
            content: elem.content,
            numUps: numUps,
            numDowns: numDowns,
            numReplies: numReplies,
            numTokens: 0,
            clickUp: clickUp,
            clickDown: clickDown,
        };

        if (elem.timestamp < commentsTimestamp) {
            dispatch(readActions.appendComment(comment));
        }

        dispatch(readActions.incrementCommentsFetched(1));
    };

    const getBestComments = () => {
        getBestComment("first").then((comment) => {
            if (comment) {
                dispatch(readActions.appendBestComment(comment));
            }
        });

        getBestComment("second").then((comment) => {
            if (comment) {
                dispatch(readActions.appendBestComment(comment));
            }
        });

        getBestComment("third").then((comment) => {
            if (comment) {
                dispatch(readActions.appendBestComment(comment));
            }
        });
    };

    const getBestComment = async (docName: string) => {
        const ref = doc(firestore, "metadata", slug!, "comment", docName);

        const snap = await getDoc(ref);

        let commentId = "";

        if (snap.exists()) {
            const data = snap.data();
            commentId = data.commentId || "";
        }

        if (commentId !== "") {
            const commentRef = doc(firestore, "board", slug!, "comment", commentId);

            const commentSnap = await getDoc(commentRef);

            if (commentSnap.exists()) {
                const data = commentSnap.data();

                if (auth.currentUser !== null) {
                    const blockDocRef = doc(
                        firestore,
                        "user",
                        auth.currentUser.uid,
                        "block",
                        data.authorUid,
                    );
                    const blockDocSnap = await getDoc(blockDocRef);
                    if (blockDocSnap.exists()) {
                        dispatch(readActions.appendCommentsBlocked(commentId));
                        dispatch(readActions.incrementCommentsFetched(1));
                        return;
                    }
                }

                let authorAvatarUrl = "";
                let authorNickname = "";

                const userDocRef = doc(firestore, "user", data.authorUid);

                const docSnap = await getDoc(userDocRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    authorAvatarUrl = data.avatarUrl || "";
                    authorNickname = data.nickname || "";
                } else {
                    dispatch(readActions.appendCommentsBlocked(commentId));
                    dispatch(readActions.incrementCommentsFetched(1));
                    return;
                }

                const upRef = doc(
                    firestore,
                    "metadata",
                    slug!,
                    "comment",
                    commentId,
                    "up",
                    "summary",
                );
                const downRef = doc(
                    firestore,
                    "metadata",
                    slug!,
                    "comment",
                    commentId,
                    "down",
                    "summary",
                );
                const replyRef = doc(
                    firestore,
                    "board",
                    slug!,
                    "comment",
                    commentId,
                    "reply",
                    "summary",
                );

                const upSnap = await getDoc(upRef);
                const downSnap = await getDoc(downRef);
                const replySnap = await getDoc(replyRef);

                let numUps = 0;
                let numDowns = 0;
                let numReplies = 0;

                if (upSnap.exists()) {
                    numUps = upSnap.data().numUps || 0;
                }

                if (downSnap.exists()) {
                    numDowns = downSnap.data().numDowns || 0;
                }

                if (replySnap.exists()) {
                    numReplies = replySnap.data().numReplies || 0;
                }

                let clickUp = false;
                let clickDown = false;

                if (auth.currentUser !== null) {
                    const upDocRef = doc(
                        firestore,
                        "metadata",
                        slug!,
                        "comment",
                        commentId,
                        "up",
                        auth.currentUser.uid,
                    );

                    const downDocRef = doc(
                        firestore,
                        "metadata",
                        slug!,
                        "comment",
                        commentId,
                        "down",
                        auth.currentUser.uid,
                    );

                    await getDoc(upDocRef).then((docSnap) => {
                        if (docSnap.exists()) {
                            clickUp = true;
                        }
                    });

                    await getDoc(downDocRef).then((docSnap) => {
                        if (docSnap.exists()) {
                            clickDown = true;
                        }
                    });
                }

                const comment: CommentState = {
                    commentId: commentId,
                    authorUid: data.authorUid,
                    authorAvatarUrl: authorAvatarUrl,
                    authorNickname: authorNickname,
                    timestamp: data.timestamp,
                    content: data.content,
                    numUps: numUps,
                    numDowns: numDowns,
                    numReplies: numReplies,
                    numTokens: 0,
                    clickUp: clickUp,
                    clickDown: clickDown,
                };

                return comment;
            }
        }
    };

    async function reactiveLength(content: any) {
        if (Array.isArray(content) === true) {
            let length = 0;
            for (let child of content) {
                if (Array.isArray(child.props.children)) {
                    length += await reactiveLength(child);
                } else {
                    if (child.props.children && child.props.children.type === "img") {
                        let img;
                        if (documentImages.has(child.props.children.props.src)) {
                            img = documentImages.get(child.props.children.props.src);
                        } else {
                            img = new Image();

                            img.src = child.props.children.props.src;
                            await img.decode();

                            documentImages.set(child.props.children.props.src, img);
                        }

                        if (img.naturalHeight) {
                            length += img.naturalHeight;
                        } else {
                            length += 1;
                        }
                    } else if (child.props.children && child.props.children.length) {
                        length += child.props.children.length;
                    } else {
                        length += 1;
                    }
                }
            }

            setDocumentImages(documentImages);
            return length;
        } else if (
            content.hasOwnProperty("props") &&
            content.props.hasOwnProperty("children") &&
            Array.isArray(content.props.children)
        ) {
            let length = 0;
            for (let child of content.props.children) {
                if (
                    child.hasOwnProperty("props") &&
                    child.props.hasOwnProperty("children") &&
                    Array.isArray(child.props.children)
                ) {
                    length += await reactiveLength(child);
                } else {
                    if (child.type === "img") {
                        let img;
                        if (documentImages.has(child.props.src)) {
                            img = documentImages.get(child.props.src);
                        } else {
                            img = new Image();

                            img.src = child.props.src;
                            await img.decode();

                            documentImages.set(child.props.src, img);
                        }

                        if (img.naturalHeight) {
                            length += img.naturalHeight;
                        } else {
                            length += 1;
                        }
                    }

                    length += 1;
                }
            }

            setDocumentImages(documentImages);
            return length;
        } else if (
            content.hasOwnProperty("props") &&
            content.props.hasOwnProperty("children") &&
            content.props.children &&
            content.props.children.type === "img"
        ) {
            let img;
            if (documentImages.has(content.props.children.props.src)) {
                img = documentImages.get(content.props.children.props.src);
            } else {
                img = new Image();

                img.src = content.props.children.props.src;
                await img.decode();

                documentImages.set(content.props.children.props.src, img);
                setDocumentImages(documentImages);
            }

            if (img.naturalHeight) {
                return img.naturalHeight;
            } else {
                return 1;
            }
        } else {
            return 1;
        }
    }

    async function extractContent(
        content: any,
        pos: number,
        accum: number,
    ): Promise<any> {
        if (Array.isArray(content) === true) {
            for (let i = 0; i < content.length; i++) {
                const child = content[i];

                if (accum <= pos && accum + (await reactiveLength(child)) > pos) {
                    return extractContent(child, pos, accum);
                } else if (accum > pos) {
                    return;
                } else {
                    accum += await reactiveLength(child);
                }
            }
        } else if (
            content.hasOwnProperty("props") &&
            content.props.hasOwnProperty("children") &&
            Array.isArray(content.props.children) === true
        ) {
            for (let i = 0; i < content.props.children.length; i++) {
                const child = content.props.children[i];

                if (accum <= pos && accum + (await reactiveLength(child)) > pos) {
                    return extractContent(child, pos, accum);
                } else if (accum > pos) {
                    return;
                } else {
                    accum += await reactiveLength(child);
                }
            }
        } else {
            if (pos === accum) {
                return content;
            }
        }
    }

    const getDocuments = async () => {
        let q;
        if (documentsLastVisible === -1) {
            return;
        } else if (documentsLastVisible !== undefined) {
            q = query(
                collection(firestore, "board"),
                orderBy("timestamp", "desc"),
                limit(10),
                startAfter(documentsLastVisible),
            );
        } else {
            dispatch(readActions.resetDocumentsFetching());
            dispatch(readActions.resetDocumentsFetched());

            q = query(
                collection(firestore, "board"),
                orderBy("timestamp", "desc"),
                limit(10),
            );
        }

        onSnapshot(q, async (snapshot) => {
            const newDocuments = snapshot.docs.map((elem) => elem.data());

            dispatch(readActions.incrementDocumentsFetching(newDocuments.length));

            for (const elem of newDocuments) {
                getDocument(elem);
            }

            if (snapshot.docs.length === 0) {
                setDocumentsLastVisible(-1);
            } else {
                setDocumentsLastVisible(snapshot.docs[snapshot.docs.length - 1]);
            }
        });
    };

    const getDocument = async (elem: any) => {
        if (auth.currentUser !== null) {
            const blockDocRef = doc(
                firestore,
                "user",
                auth.currentUser.uid,
                "block",
                elem.authorUid,
            );
            const blockDocSnap = await getDoc(blockDocRef);
            if (blockDocSnap.exists()) {
                dispatch(homeActions.appendBlocked(elem.documentId));
                dispatch(homeActions.incrementFetched(1));
                return;
            }
        }

        let authorAvatarUrl = "";
        let authorNickname = "";

        const userDocRef = doc(firestore, "user", elem.authorUid);

        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
            const data = docSnap.data();
            authorAvatarUrl = data.avatarUrl || "";
            authorNickname = data.nickname || "";
        } else {
            dispatch(readActions.appendDocumentsBlocked(elem.documentId));
            dispatch(readActions.incrementDocumentsFetched(1));
            return;
        }

        const parsedContent = parse(elem.content);

        const lengthLimit = 700;
        const length = await reactiveLength(parsedContent);

        let thumbnailContent: any[] = [];
        let thumbnailImageSizes: any[] = [];

        if (length >= 100) {
            let index = 0;
            let extractLength = 0;

            let batchedContent = [];

            while (index < 5 && extractLength < lengthLimit) {
                let ec = await extractContent(parsedContent, index, 0);

                if (ec && ec.props !== undefined && ec.props.children !== undefined) {
                    if (ec.props.children.type === "img") {
                        let img;
                        if (documentImages.has(ec.props.children.props.src)) {
                            img = documentImages.get(ec.props.children.props.src);
                        } else {
                            img = new Image();

                            img.src = ec.props.children.props.src;
                            await img.decode();

                            documentImages.set(ec.props.children.props.src, img);
                            setDocumentImages(documentImages);
                        }

                        if (img.naturalHeight) {
                            if (extractLength + img.naturalHeight > lengthLimit) {
                                thumbnailImageSizes = [
                                    ...thumbnailImageSizes,
                                    {
                                        src: ec.props.children.props.src,
                                        width: img.naturalWidth,
                                        height: lengthLimit - extractLength,
                                    },
                                ];

                                extractLength += lengthLimit - extractLength;
                            } else {
                                thumbnailImageSizes = [
                                    ...thumbnailImageSizes,
                                    {
                                        src: ec.props.children.props.src,
                                        width: img.naturalWidth,
                                        height: img.naturalHeight,
                                    },
                                ];

                                extractLength += img.naturalHeight;
                            }
                        } else {
                            extractLength += 1;
                        }
                    } else if (ec.props.children.length) {
                        extractLength += ec.props.children.length;
                    } else {
                        extractLength += 1;
                    }

                    batchedContent.push(ec);
                } else if (ec && ec.type === "img") {
                    let img;
                    if (documentImages.has(ec.props!.src)) {
                        img = documentImages.get(ec.props.src);
                    } else {
                        img = new Image();

                        img.src = ec.props.src;
                        await img.decode();

                        documentImages.set(ec.props.src, img);
                        setDocumentImages(documentImages);
                    }

                    if (img.naturalHeight) {
                        if (extractLength + img.naturalHeight > lengthLimit) {
                            thumbnailImageSizes = [
                                ...thumbnailImageSizes,
                                {
                                    src: ec.props.src,
                                    width: img.naturalWidth,
                                    height: lengthLimit - extractLength,
                                },
                            ];

                            extractLength += lengthLimit - extractLength;
                        } else {
                            thumbnailImageSizes = [
                                ...thumbnailImageSizes,
                                {
                                    src: ec.props.src,
                                    width: img.naturalWidth,
                                    height: img.naturalHeight,
                                },
                            ];

                            extractLength += img.naturalHeight;
                        }
                    } else {
                        extractLength += 1;
                    }

                    batchedContent.push(ec);
                } else if (ec) {
                    extractLength += 1;

                    batchedContent.push(ec);
                } else {
                    extractLength += 1;
                }

                index += 1;
            }

            thumbnailContent = batchedContent;
        } else {
            thumbnailContent = [parsedContent];
        }

        const upRef = doc(firestore, "metadata", elem.documentId, "up", "summary");
        const downRef = doc(
            firestore,
            "metadata",
            elem.documentId,
            "down",
            "summary",
        );
        const commentRef = doc(
            firestore,
            "board",
            elem.documentId,
            "comment",
            "summary",
        );

        const upSnap = await getDoc(upRef);
        const downSnap = await getDoc(downRef);
        const commentSnap = await getDoc(commentRef);

        let numUps = 0;
        let numDowns = 0;
        let numComments = 0;

        if (upSnap.exists()) {
            numUps = upSnap.data().numUps || 0;
        }

        if (downSnap.exists()) {
            numDowns = downSnap.data().numDowns || 0;
        }

        if (commentSnap.exists()) {
            numComments = commentSnap.data().numComments || 0;
        }

        let clickUp = false;
        let clickDown = false;
        let clickBookmark = false;

        if (auth.currentUser !== null) {
            const upDocRef = doc(
                firestore,
                "metadata",
                elem.documentId,
                "up",
                auth.currentUser.uid,
            );

            const downDocRef = doc(
                firestore,
                "metadata",
                elem.documentId,
                "down",
                auth.currentUser.uid,
            );

            const bookmarkDocRef = doc(
                firestore,
                "user",
                auth.currentUser.uid,
                "bookmark",
                elem.documentId,
            );

            await getDoc(upDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    clickUp = true;
                }
            });

            await getDoc(downDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    clickDown = true;
                }
            });

            await getDoc(bookmarkDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    clickBookmark = true;
                }
            });
        }

        const document: DocumentState = {
            documentId: elem.documentId,
            authorUid: elem.authorUid,
            authorAvatarUrl: authorAvatarUrl,
            authorNickname: authorNickname,
            timestamp: elem.timestamp,
            content: elem.content,
            thumbnailContent: thumbnailContent,
            thumbnailImageSizes: thumbnailImageSizes,
            hashtags: elem.hashtags.split(","),
            numUps: numUps,
            numDowns: numDowns,
            numComments: numComments,
            numTokens: 0,
            clickUp: clickUp,
            clickDown: clickDown,
            clickBookmark: clickBookmark,
        };

        dispatch(readActions.appendDocument(document));

        dispatch(readActions.incrementDocumentsFetched(1));
    };

    useEffect(() => {
        const handleScroll = () => {
            if (
                documentsFetched === documentsFetching &&
                window.scrollY > window.outerHeight / 3
            ) {
                getDocuments();
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [documentsFetched, documentsFetching]);

    useBottomScrollListener(getDocuments);

    useEffect(() => {
        dispatch(coreActions.setFocus("read"));
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        dispatch(readActions.resetDocument());

        dispatch(readActions.resetCommentsBlocked());
        dispatch(readActions.resetBestComments());
        dispatch(readActions.resetComments());
        dispatch(readActions.unsetClickedComment());
        dispatch(readActions.resetDocumentsBlocked());
        dispatch(readActions.resetDocuments());

        setDocumentsLastVisible(undefined);
        setCommentsLastVisible(undefined);

        if (location.state && location.state.document) {
            dispatch(readActions.setDocument(location.state.document));
        } else {
            getMainDocument();
        }

        getComments();
        getBestComments();
        getDocuments();
    }, [slug!]);

    return (
        <div className="Read">
            {isLoading ? (
                <Loading/>
            ) : (
                <Main
                    documents={documents}
                    getComments={getComments}
                    commentsLastVisible={commentsLastVisible}
                />
            )}
        </div>
    );
}

function Main(props: {
    documents: DocumentState[];
    getComments: any;
    commentsLastVisible: any;
}) {
    return (
        <div className="Read-Main">
            <Header/>
            <Sidebar/>
            <Title/>
            <Body
                getComments={props.getComments}
                commentsLastVisible={props.commentsLastVisible}
            />
            <Split/>
            <NextDocuments documents={props.documents}/>
            <RightSidebar/>
        </div>
    );
}

function Title() {
    const navigate = useNavigate();

    const onClick = () => {
        navigate("/");
    };

    return (
        <div className="Read-Title">
            <img src={back} onClick={onClick}/>
            <h1>게시물</h1>
        </div>
    );
}

function Body(props: { getComments: any; commentsLastVisible: any }) {
    const documentId = useAppSelector((state) => state.read.document.documentId);

    if (documentId !== "") {
        return (
            <div className="Read-Body">
                <Document/>
                <CommentInput/>
                <Comments
                    getComments={props.getComments}
                    commentsLastVisible={props.commentsLastVisible}
                />
            </div>
        );
    } else {
        return (
            <div className="Read-Body-Void">
                <p>존재하지 않는 게시물입니다.</p>
            </div>
        );
    }
}

function Document() {
    const document = useAppSelector((state) => state.read.document);

    if (document.hashtags.length > 0 && document.hashtags[0] !== "") {
        return (
            <div className="Read-Document">
                <DocumentHeader/>
                <Content/>
                <Hashtags/>
                <Summary/>
            </div>
        );
    } else {
        return (
            <div className="Read-Document">
                <DocumentHeader/>
                <Content/>
                <Summary/>
            </div>
        );
    }
}

function DocumentHeader() {
    return (
        <div className="Read-DocumentHeader">
            <Avatar/>
            <Nickname/>
            <Time/>
            <Bookmark/>
            <Misc/>
        </div>
    );
}

function Avatar() {
    const navigate = useNavigate();

    const authorUid = useAppSelector((state) => state.read.document.authorUid);
    const authorAvatarUrl = useAppSelector(
        (state) => state.read.document.authorAvatarUrl,
    );

    const onClick = () => {
        if (authorUid !== "") {
            navigate(`/profile_view/${authorUid}`);
        }
    };

    if (authorAvatarUrl !== "") {
        return (
            <div className="Read-Avatar">
                <img src={authorAvatarUrl} onClick={onClick}/>
            </div>
        );
    } else {
        return (
            <div className="Read-Avatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        );
    }
}

function Nickname() {
    const navigate = useNavigate();

    const authorUid = useAppSelector((state) => state.read.document.authorUid);
    const authorNickname = useAppSelector(
        (state) => state.read.document.authorNickname,
    );

    const onClick = () => {
        if (authorUid !== "") {
            navigate(`/profile_view/${authorUid}`);
        }
    };

    if (authorNickname !== "") {
        return (
            <div className="Read-Nickname">
                <button onClick={onClick}>{authorNickname}</button>
            </div>
        );
    } else {
        return (
            <div className="Read-Nickname">
                <button onClick={onClick}>닉네임 없음</button>
            </div>
        );
    }
}

function Time() {
    const timestamp = useAppSelector((state) => state.read.document.timestamp);

    const endTimestamp = Date.now();

    const diff = endTimestamp - timestamp;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 1) {
        return (
            <div className="Read-Time">
                <p>방금 전</p>
            </div>
        );
    } else if (hours < 1) {
        return (
            <div className="Read-Time">
                <p>{minutes} 분 전</p>
            </div>
        );
    } else if (days < 1) {
        return (
            <div className="Read-Time">
                <p>{hours} 시간 전</p>
            </div>
        );
    } else {
        const date = moment(timestamp);

        return (
            <div className="Read-Time">
                <p>{date.format("YYYY-MM-DD")}</p>
            </div>
        );
    }
}

function Bookmark() {
    const documentId = useAppSelector((state) => state.read.document.documentId);
    const clickBookmark = useAppSelector(
        (state) => state.read.document.clickBookmark,
    );

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            const bookmarkDocRef = doc(
                firestore,
                "user",
                auth.currentUser.uid,
                "bookmark",
                documentId,
            );

            if (clickBookmark) {
                dispatch(readActions.setClickBookmark(false));

                await deleteDoc(bookmarkDocRef);
            } else {
                dispatch(readActions.setClickBookmark(true));

                const timestamp = Date.now();

                await setDoc(bookmarkDocRef, {
                    documentId: documentId,
                    uid: auth.currentUser!.uid,
                    timestamp: timestamp,
                });
            }
        } else {
            navigate("/login");
        }
    };

    if (auth.currentUser !== null) {
        if (clickBookmark) {
            return (
                <div className="Read-Bookmark">
                    <button onClick={onClick}>
                        <img src={bookmarkFocus}/>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Read-Bookmark">
                    <button onClick={onClick}>
                        <img src={bookmark}/>
                    </button>
                </div>
            );
        }
    } else {
        return (
            <div className="Read-Bookmark">
                <button onClick={onClick}>
                    <img src={bookmark}/>
                </button>
            </div>
        );
    }
}

function Misc() {
    const isAdmin = useAppSelector((state) => state.core.isAdmin);
    const document = useAppSelector((state) => state.read.document);

    const auth = getAuth();

    const [click, setClick] = useState(false);

    const onClick = () => {
        if (click) {
            setClick(false);
        } else {
            setClick(true);
        }
    };

    if (click) {
        if (
            auth.currentUser !== null &&
            (document.authorUid === auth.currentUser.uid || isAdmin)
        ) {
            return (
                <div className="Read-Misc">
                    <button onClick={onClick}>
                        <img src={threeDots}/>
                    </button>
                    <div className="Read-MiscFocus-Mine">
                        <Delete/>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="Read-Misc">
                    <button onClick={onClick}>
                        <img src={threeDots}/>
                    </button>
                    <div className="Read-MiscFocus">
                        <Block/>
                        <Report/>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className="Read-Misc">
                <button onClick={onClick}>
                    <img src={threeDots}/>
                </button>
            </div>
        );
    }
}

function Block() {
    const document = useAppSelector((state) => state.read.document);

    const navigate = useNavigate();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            if (auth.currentUser.uid !== document.authorNickname) {
                const answer = window.confirm(
                    `${document.authorNickname}님의 게시글을 모두 차단하겠습니까?`,
                );

                if (answer) {
                    const timestamp = Date.now();

                    const docRef = doc(
                        firestore,
                        "user",
                        auth.currentUser.uid,
                        "block",
                        document.authorUid,
                    );

                    await setDoc(docRef, {
                        uid: document.authorUid,
                        timestamp: timestamp,
                    });

                    navigate(0);
                }
            } else {
                alert("본인을 차단할 수는 없습니다.");
            }
        } else {
            navigate("/login");
        }
    };

    return (
        <div className="Read-Block">
            <button onClick={onClick}>
                <img src={block}/>
                <p>차단하기</p>
            </button>
        </div>
    );
}

function Report() {
    const document = useAppSelector((state) => state.read.document);

    const navigate = useNavigate();

    const auth = getAuth();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            navigate("/report", {
                state: {
                    documentId: document.documentId,
                    commentId: "",
                    replyId: "",
                },
            });
        } else {
            navigate("/login");
        }
    };

    return (
        <div className="Read-Report">
            <button onClick={onClick}>
                <img src={notify}/>
                <p>신고하기</p>
            </button>
        </div>
    );
}

function Delete() {
    const document = useAppSelector((state) => state.read.document);

    const navigate = useNavigate();

    const auth = getAuth();
    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    const onClick = async () => {
        const answer = window.confirm("정말 삭제하시겠습니까?");

        if (answer) {
            dispatch(coreActions.setIsLoading(true));

            await deleteDoc(doc(firestore, "metadata", document.documentId));
            await deleteDoc(
                doc(firestore, "user", document.authorUid, "post", document.documentId),
            );

            for (const hashtag of document.hashtags) {
                if (hashtag !== "") {
                    await deleteDoc(
                        doc(firestore, "hashtag", hashtag, "post", document.documentId),
                    );
                }
            }

            await deleteDoc(doc(firestore, "board", document.documentId));

            dispatch(coreActions.setIsLoading(false));

            navigate("/");
        }
    };

    return (
        <div className="Read-Delete">
            <button onClick={onClick}>
                <img src={trash}/>
                <p>삭제하기</p>
            </button>
        </div>
    );
}

function Content() {
    const content = useAppSelector((state) => state.read.document.content);

    const parsedContent = parse(content);

    return <div className="Read-Content">{parsedContent}</div>;
}

function Hashtags() {
    const hashtags = useAppSelector((state) => state.read.document.hashtags);

    return (
        <div className="Read-Hashtags">
            {
                <ul>
                    {hashtags.map((hashtag, index) => (
                        <li key={index}>
                            <Hashtag hashtag={hashtag}/>
                        </li>
                    ))}
                </ul>
            }
        </div>
    );
}

function Hashtag(props: { hashtag: string }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/search_query/${props.hashtag.slice(1)}`);
    };

    return (
        <div className="Read-Hashtag">
            <img src={hashtag}/>
            <button className="Read-Hashtag-tag" onClick={onClick}>
                {props.hashtag.slice(1)}
            </button>
        </div>
    );
}

function Summary() {
    return (
        <div className="Read-Summary">
            <NumUps/>
            <NumDowns/>
            <NumComments/>
            <NumTokens/>
        </div>
    );
}

function NumUps() {
    const navigate = useNavigate();

    const documentId = useAppSelector((state) => state.read.document.documentId);
    const numUps = useAppSelector((state) => state.read.document.numUps);
    const clickUp = useAppSelector((state) => state.read.document.clickUp);

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            const upDocRef = doc(
                firestore,
                "metadata",
                documentId,
                "up",
                auth.currentUser.uid,
            );

            if (clickUp) {
                dispatch(readActions.setClickUp(false));

                await deleteDoc(upDocRef);
            } else {
                dispatch(readActions.setClickUp(true));

                const upTimestamp = Date.now();

                await setDoc(upDocRef, {
                    uid: auth.currentUser!.uid,
                    timestamp: upTimestamp,
                });
            }
        } else {
            navigate("/login");
        }
    };

    if (documentId !== "" && auth.currentUser !== null) {
        if (clickUp) {
            return (
                <div className="Read-NumUps-Focus">
                    <button onClick={onClick}>
                        <img src={upFocus}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Read-NumUps">
                    <button onClick={onClick}>
                        <img src={up}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            );
        }
    } else {
        return (
            <div className="Read-NumUps">
                <button onClick={onClick}>
                    <img src={up}/>
                    <p>{numUps}</p>
                </button>
            </div>
        );
    }
}

function NumDowns() {
    const navigate = useNavigate();

    const documentId = useAppSelector((state) => state.read.document.documentId);
    const numDowns = useAppSelector((state) => state.read.document.numDowns);
    const clickDown = useAppSelector((state) => state.read.document.clickDown);

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            const downDocRef = doc(
                firestore,
                "metadata",
                documentId,
                "down",
                auth.currentUser.uid,
            );

            if (clickDown) {
                dispatch(readActions.setClickDown(false));

                await deleteDoc(downDocRef);
            } else {
                dispatch(readActions.setClickDown(true));

                const downTimestamp = Date.now();

                await setDoc(downDocRef, {
                    uid: auth.currentUser!.uid,
                    timestamp: downTimestamp,
                });
            }
        } else {
            navigate("/login");
        }
    };

    if (documentId !== "" && auth.currentUser !== null) {
        if (clickDown) {
            return (
                <div className="Read-NumDowns-Focus">
                    <button onClick={onClick}>
                        <img src={downFocus}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Read-NumDowns">
                    <button onClick={onClick}>
                        <img src={down}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            );
        }
    } else {
        return (
            <div className="Read-NumDowns">
                <button onClick={onClick}>
                    <img src={down}/>
                    <p>{numDowns}</p>
                </button>
            </div>
        );
    }
}

function NumComments() {
    const numComments = useAppSelector(
        (state) => state.read.document.numComments,
    );

    return (
        <div className="Read-NumComments">
            <button>
                <img src={comment}/>
                <p>{numComments}</p>
            </button>
        </div>
    );
}

function NumTokens() {
    return (
        <div className="Read-NumTokens">
            <button>
                <p>TOKEN</p>
                <ComingSoon/>
            </button>
        </div>
    );
}

function ComingSoon() {
    return (
        <div className="Read-ComingSoon">
            <button>
                <img src={comingSoon}/>
            </button>
        </div>
    );
}

function CommentInput() {
    return (
        <div className="Read-CommentInput">
            <CommentInputAvatar/>
            <CommentInputBody/>
        </div>
    );
}

function CommentInputAvatar() {
    const [avatarUrl, setAvatarUrl] = useState("");

    const auth = getAuth();
    const firestore = getFirestore();

    useEffect(() => {
        if (auth.currentUser !== null) {
            const docRef = doc(firestore, "user", auth.currentUser.uid);

            getDoc(docRef).then((docSnap) => {
                if (docSnap.exists()) {
                    const data = docSnap.data();

                    setAvatarUrl(data.avatarUrl || "");
                }
            });
        }
    }, []);

    if (avatarUrl !== "") {
        return (
            <div className="Read-CommentInputAvatar">
                <img src={avatarUrl}/>
            </div>
        );
    } else {
        return (
            <div className="Read-CommentInputAvatar">
                <img src={defaultAvatar}/>
            </div>
        );
    }
}

function CommentInputBody() {
    const navigate = useNavigate();

    const documentId = useAppSelector((state) => state.read.document.documentId);
    const authorUid = useAppSelector((state) => state.read.document.authorUid);
    const numComments = useAppSelector(
        (state) => state.read.document.numComments,
    );

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const [content, setContent] = useState("");

    const onChange = (e: any) => {
        if (e.target.value.length < 200) {
            setContent(e.target.value);
        } else {
            alert("댓글 글자수는 200자로 제한됩니다.");
        }
    };

    const onClick = async () => {
        if (content !== "") {
            dispatch(coreActions.setIsLoading(true));

            const timestamp = Date.now();

            const id = uuid();

            const commentId = timestamp.toString() + "-" + id;

            if (auth.currentUser !== null) {
                await setDoc(
                    doc(firestore, "board", documentId, "comment", commentId),
                    {
                        documentId: documentId,
                        commentId: commentId,
                        authorUid: auth.currentUser.uid,
                        content: content,
                        timestamp: timestamp,
                    },
                );

                await setDoc(
                    doc(firestore, "metadata", documentId, "comment", commentId),
                    {
                        documentId: documentId,
                        commentId: commentId,
                        authorUid: auth.currentUser.uid,
                        timestamp: timestamp,
                    },
                );

                await setDoc(
                    doc(firestore, "user", authorUid, "notification", commentId),
                    {
                        messageType: "comment",
                        documentId: documentId,
                        commentId: commentId,
                        replyId: "",
                        authorUid: auth.currentUser!.uid,
                        timestamp: timestamp,
                    },
                );

                let authorAvatarUrl = "";
                let authorNickname = "";

                const userDocRef = doc(firestore, "user", auth.currentUser!.uid);

                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    const data = userDocSnap.data();
                    authorAvatarUrl = data.avatarUrl || "";
                    authorNickname = data.nickname || "";
                }

                const c: CommentState = {
                    commentId: commentId,
                    authorUid: authorUid,
                    authorAvatarUrl: authorAvatarUrl,
                    authorNickname: authorNickname,
                    timestamp: timestamp,
                    content: content,
                    numUps: 0,
                    numDowns: 0,
                    numReplies: 0,
                    numTokens: 0,
                    clickUp: false,
                    clickDown: false,
                };

                setContent("");

                dispatch(readActions.setNumComments(numComments + 1));
                dispatch(readActions.appendBestComment(c));
                dispatch(readActions.appendComment(c));

                dispatch(coreActions.setIsLoading(false));
            } else {
                alert("댓글 쓰기는 로그인하여야 가능합니다.");

                navigate("/login");

                dispatch(coreActions.setIsLoading(false));
            }
        }
    };

    return (
        <div className="Read-CommentInputBody">
            <form>
        <textarea
            placeholder={"댓글을 남겨주세요!"}
            wrap="soft"
            value={content}
            onChange={onChange}
        />
            </form>
            <button onClick={onClick}>등록</button>
        </div>
    );
}

function Comments(props: { getComments: any; commentsLastVisible: any }) {
    const bestComments = useAppSelector((state) => state.read.bestComments);
    const comments = useAppSelector((state) => state.read.comments);
    const commentsFetching = useAppSelector(
        (state) => state.read.commentsFetching,
    );
    const commentsFetched = useAppSelector((state) => state.read.commentsFetched);

    const dispatch = useAppDispatch();

    const bc = bestComments.map((item, i) => {
        if (i < 3) {
            return <BestComment comment={item}/>;
        }
    });

    const c = comments.map((item, i) => {
        return <Comment comment={item}/>;
    });

    const onClick = () => {
        props.getComments();
    };

    if (commentsFetched < commentsFetching) {
        return (
            <div className="Read-Comments">
                <LoadingBar/>
                {bc}
                {c}
                <div className="Read-ReadMoreComments">
                    <button onClick={onClick}>댓글 더보기</button>
                </div>
            </div>
        );
    } else {
        if (props.commentsLastVisible !== -1) {
            return (
                <div className="Read-Comments">
                    {bc}
                    {c}
                    <div className="Read-ReadMoreComments">
                        <button onClick={onClick}>댓글 더보기</button>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="Read-Comments">
                    {bc}
                    {c}
                </div>
            );
        }
    }
}

function LoadingBar() {
    return (
        <div className="Read-LoadingBar">
            <LinearProgress/>
        </div>
    );
}

function BestComment(props: { comment: CommentState }) {
    const clickedComment = useAppSelector((state) => state.read.clickedComment);

    const [isClicked, setIsClicked] = useState(false);

    useEffect(() => {
        if (clickedComment === props.comment.commentId) {
            setIsClicked(true);
        } else {
            setIsClicked(false);
        }
    }, [clickedComment]);

    if (isClicked) {
        return (
            <div className="Read-Comment">
                <BestCommentHeader comment={props.comment}/>
                <CommentBody comment={props.comment}/>
                <CommentSummary comment={props.comment}/>
                <ReplyInput comment={props.comment}/>
                <Replies/>
            </div>
        );
    } else {
        return (
            <div className="Read-Comment">
                <BestCommentHeader comment={props.comment}/>
                <CommentBody comment={props.comment}/>
                <CommentSummary comment={props.comment}/>
            </div>
        );
    }
}

function Comment(props: { comment: CommentState }) {
    const clickedComment = useAppSelector((state) => state.read.clickedComment);

    const [isClicked, setIsClicked] = useState(false);

    useEffect(() => {
        if (clickedComment === props.comment.commentId) {
            setIsClicked(true);
        } else {
            setIsClicked(false);
        }
    }, [clickedComment]);

    if (isClicked) {
        return (
            <div className="Read-Comment">
                <CommentHeader comment={props.comment}/>
                <CommentBody comment={props.comment}/>
                <CommentSummary comment={props.comment}/>
                <ReplyInput comment={props.comment}/>
                <Replies/>
            </div>
        );
    } else {
        return (
            <div className="Read-Comment">
                <CommentHeader comment={props.comment}/>
                <CommentBody comment={props.comment}/>
                <CommentSummary comment={props.comment}/>
            </div>
        );
    }
}

function BestCommentHeader(props: { comment: CommentState }) {
    return (
        <div className="Read-CommentHeader">
            <CommentAvatar comment={props.comment}/>
            <CommentNickname comment={props.comment}/>
            <CommentTime comment={props.comment}/>
            <CommentMisc comment={props.comment}/>
            <Best/>
        </div>
    );
}

function Best() {
    return (
        <div className="Read-Best">
            <img src={best}/>
        </div>
    );
}

function CommentHeader(props: { comment: CommentState }) {
    return (
        <div className="Read-CommentHeader">
            <CommentAvatar comment={props.comment}/>
            <CommentNickname comment={props.comment}/>
            <CommentTime comment={props.comment}/>
            <CommentMisc comment={props.comment}/>
        </div>
    );
}

function CommentAvatar(props: { comment: CommentState }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/profile_view/${props.comment.authorUid}`);
    };

    if (props.comment.authorAvatarUrl !== "") {
        return (
            <div className="Read-CommentAvatar">
                <img src={props.comment.authorAvatarUrl} onClick={onClick}/>
            </div>
        );
    } else {
        return (
            <div className="Read-CommentAvatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        );
    }
}

function CommentTime(props: { comment: CommentState }) {
    const endTimestamp = Date.now();

    const diff = endTimestamp - props.comment.timestamp;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 1) {
        return (
            <div className="Read-CommentTime">
                <p>방금 전</p>
            </div>
        );
    } else if (hours < 1) {
        return (
            <div className="Read-CommentTime">
                <p>{minutes} 분 전</p>
            </div>
        );
    } else if (days < 1) {
        return (
            <div className="Read-CommentTime">
                <p>{hours} 시간 전</p>
            </div>
        );
    } else {
        const date = moment(props.comment.timestamp);

        return (
            <div className="Read-CommentTime">
                <p>{date.format("YYYY-MM-DD")}</p>
            </div>
        );
    }
}

function CommentNickname(props: { comment: CommentState }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/profile_view/${props.comment.authorUid}`);
    };

    if (props.comment.authorNickname !== "") {
        return (
            <div className="Read-CommentNickname">
                <button onClick={onClick}>{props.comment.authorNickname}</button>
            </div>
        );
    } else {
        return (
            <div className="Read-CommentNickname">
                <button onClick={onClick}>닉네임 없음</button>
            </div>
        );
    }
}

function CommentMisc(props: { comment: CommentState }) {
    const isAdmin = useAppSelector((state) => state.core.isAdmin);

    const auth = getAuth();

    const [click, setClick] = useState(false);

    const onClick = () => {
        if (click) {
            setClick(false);
        } else {
            setClick(true);
        }
    };

    if (click) {
        if (
            auth.currentUser !== null &&
            (props.comment.authorUid === auth.currentUser.uid || isAdmin)
        ) {
            return (
                <div className="Read-CommentMisc">
                    <button onClick={onClick}>
                        <img src={threeDots}/>
                    </button>
                    <div className="Read-CommentMiscFocus-Mine">
                        <CommentDelete comment={props.comment}/>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="Read-CommentMisc">
                    <button onClick={onClick}>
                        <img src={threeDots}/>
                    </button>
                    <div className="Read-CommentMiscFocus">
                        <CommentBlock comment={props.comment}/>
                        <CommentReport comment={props.comment}/>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className="Read-CommentMisc">
                <button onClick={onClick}>
                    <img src={threeDots}/>
                </button>
            </div>
        );
    }
}

function CommentBlock(props: { comment: CommentState }) {
    const navigate = useNavigate();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            if (auth.currentUser.uid !== props.comment.authorNickname) {
                const answer = window.confirm(
                    `${props.comment.authorNickname}님의 게시글을 모두 차단하겠습니까?`,
                );

                if (answer) {
                    const timestamp = Date.now();

                    const docRef = doc(
                        firestore,
                        "user",
                        auth.currentUser.uid,
                        "block",
                        props.comment.authorUid,
                    );

                    await setDoc(docRef, {
                        uid: props.comment.authorUid,
                        timestamp: timestamp,
                    });

                    navigate(0);
                }
            } else {
                alert("본인을 차단할 수는 없습니다.");
            }
        } else {
            navigate("/login");
        }
    };

    return (
        <div className="Read-CommentBlock">
            <button onClick={onClick}>
                <img src={block}/>
                <p>차단하기</p>
            </button>
        </div>
    );
}

function CommentReport(props: { comment: CommentState }) {
    const document = useAppSelector((state) => state.read.document);

    const navigate = useNavigate();

    const auth = getAuth();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            navigate("/report", {
                state: {
                    documentId: document.documentId,
                    commentId: props.comment.commentId,
                    replyId: "",
                },
            });
        } else {
            navigate("/login");
        }
    };

    return (
        <div className="Read-CommentReport">
            <button onClick={onClick}>
                <img src={notify}/>
                <p>신고하기</p>
            </button>
        </div>
    );
}

function CommentDelete(props: { comment: CommentState }) {
    const document = useAppSelector((state) => state.read.document);

    const auth = getAuth();
    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    const onClick = async () => {
        const answer = window.confirm("정말 삭제하시겠습니까?");

        if (answer) {
            dispatch(coreActions.setIsLoading(true));

            await deleteDoc(
                doc(
                    firestore,
                    "metadata",
                    document.documentId,
                    "comment",
                    props.comment.commentId,
                ),
            );

            await deleteDoc(
                doc(
                    firestore,
                    "board",
                    document.documentId,
                    "comment",
                    props.comment.commentId,
                ),
            );

            dispatch(readActions.removeComment(props.comment));

            dispatch(coreActions.setIsLoading(false));
        }
    };

    return (
        <div className="Read-CommentDelete">
            <button onClick={onClick}>
                <img src={trash}/>
                <p>삭제하기</p>
            </button>
        </div>
    );
}

function CommentBody(props: { comment: CommentState }) {
    return (
        <div className="Read-CommentBody">
            <p>{props.comment.content}</p>
        </div>
    );
}

function CommentSummary(props: { comment: CommentState }) {
    return (
        <div className="Read-CommentSummary">
            <CommentNumUps comment={props.comment}/>
            <CommentNumDowns comment={props.comment}/>
            <CommentNumReplies comment={props.comment}/>
            <CommentNumTokens comment={props.comment}/>
        </div>
    );
}

function CommentNumUps(props: { comment: CommentState }) {
    const navigate = useNavigate();

    const documentId = useAppSelector((state) => state.read.document.documentId);

    const auth = getAuth();
    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            const upDocRef = doc(
                firestore,
                "metadata",
                documentId,
                "comment",
                props.comment.commentId,
                "up",
                auth.currentUser.uid,
            );

            if (props.comment.clickUp) {
                dispatch(readActions.unsetCommentClickUp(props.comment.commentId));

                await deleteDoc(upDocRef);
            } else {
                dispatch(readActions.setCommentClickUp(props.comment.commentId));

                const upTimestamp = Date.now();

                await setDoc(upDocRef, {
                    uid: auth.currentUser!.uid,
                    timestamp: upTimestamp,
                });
            }
        } else {
            navigate("/login");
        }
    };

    if (auth.currentUser !== null) {
        if (props.comment.clickUp) {
            return (
                <div className="Read-CommentNumUps-Focus">
                    <button onClick={onClick}>
                        <img src={upFocus}/>
                        <p>{props.comment.numUps}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Read-CommentNumUps">
                    <button onClick={onClick}>
                        <img src={up}/>
                        <p>{props.comment.numUps}</p>
                    </button>
                </div>
            );
        }
    } else {
        return (
            <div className="Read-CommentNumUps">
                <button onClick={onClick}>
                    <img src={up}/>
                    <p>{props.comment.numUps}</p>
                </button>
            </div>
        );
    }
}

function CommentNumDowns(props: { comment: CommentState }) {
    const navigate = useNavigate();

    const documentId = useAppSelector((state) => state.read.document.documentId);

    const auth = getAuth();
    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            const downDocRef = doc(
                firestore,
                "metadata",
                documentId,
                "comment",
                props.comment.commentId,
                "down",
                auth.currentUser.uid,
            );

            if (props.comment.clickDown) {
                dispatch(readActions.unsetCommentClickDown(props.comment.commentId));

                await deleteDoc(downDocRef);
            } else {
                dispatch(readActions.setCommentClickDown(props.comment.commentId));

                const downTimestamp = Date.now();

                await setDoc(downDocRef, {
                    uid: auth.currentUser!.uid,
                    timestamp: downTimestamp,
                });
            }
        } else {
            navigate("/login");
        }
    };

    if (auth.currentUser !== null) {
        if (props.comment.clickDown) {
            return (
                <div className="Read-CommentNumDowns-Focus">
                    <button onClick={onClick}>
                        <img src={downFocus}/>
                        <p>{props.comment.numDowns}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Read-CommentNumDowns">
                    <button onClick={onClick}>
                        <img src={down}/>
                        <p>{props.comment.numDowns}</p>
                    </button>
                </div>
            );
        }
    } else {
        return (
            <div className="Read-CommentNumDowns">
                <button onClick={onClick}>
                    <img src={down}/>
                    <p>{props.comment.numDowns}</p>
                </button>
            </div>
        );
    }
}

function CommentNumReplies(props: { comment: CommentState }) {
    const dispatch = useAppDispatch();

    const [click, setClick] = useState(false);

    const onClick = () => {
        if (click) {
            setClick(false);

            dispatch(readActions.unsetClickedComment());
        } else {
            setClick(true);

            dispatch(readActions.setClickedComment(props.comment.commentId));
        }
    };

    return (
        <div className="Read-CommentNumReplies">
            <button onClick={onClick}>
                <img src={comment}/>
                <p>{props.comment.numReplies}</p>
            </button>
        </div>
    );
}

function CommentNumTokens(props: { comment: CommentState }) {
    return (
        <div className="Read-CommentNumTokens">
            <button>
                <p>TOKEN</p>
                <ComingSoon/>
            </button>
        </div>
    );
}

function ReplyInput(props: { comment: CommentState }) {
    return (
        <div className="Read-ReplyInput">
            <ReplyImage/>
            <ReplyInputAvatar/>
            <ReplyInputBody comment={props.comment}/>
        </div>
    );
}

function ReplyImage() {
    return (
        <div className="Read-ReplyImage">
            <img src={reply}/>
        </div>
    );
}

function ReplyInputAvatar() {
    const [avatarUrl, setAvatarUrl] = useState("");

    const auth = getAuth();
    const firestore = getFirestore();

    useEffect(() => {
        if (auth.currentUser !== null) {
            const userDocRef = doc(firestore, "user", auth.currentUser.uid);

            getDoc(userDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    const data = docSnap.data();

                    setAvatarUrl(data.avatarUrl || "");
                }
            });
        }
    }, []);

    if (avatarUrl !== "") {
        return (
            <div className="Read-ReplyInputAvatar">
                <img src={avatarUrl}/>
            </div>
        );
    } else {
        return (
            <div className="Read-ReplyInputAvatar">
                <img src={defaultAvatar}/>
            </div>
        );
    }
}

function ReplyInputBody(props: { comment: CommentState }) {
    const navigate = useNavigate();

    const documentId = useAppSelector((state) => state.read.document.documentId);

    const dispatch = useAppDispatch();

    const [content, setContent] = useState("");

    const auth = getAuth();
    const firestore = getFirestore();

    const onChange = (e: any) => {
        if (e.target.value.length < 200) {
            setContent(e.target.value);
        } else {
            alert("답글 글자수는 200자로 제한됩니다.");
        }
    };

    const onClick = async () => {
        if (content !== "") {
            if (content !== "") {
                dispatch(coreActions.setIsLoading(true));

                const timestamp = Date.now();

                const id = uuid();

                const replyId = timestamp.toString() + "-" + id;

                if (auth.currentUser !== null) {
                    await setDoc(
                        doc(
                            firestore,
                            "board",
                            documentId,
                            "comment",
                            props.comment.commentId,
                            "reply",
                            replyId,
                        ),
                        {
                            documentId: documentId,
                            commentId: props.comment.commentId,
                            replyId: replyId,
                            authorUid: auth.currentUser.uid,
                            content: content,
                            timestamp: timestamp,
                        },
                    );

                    await setDoc(
                        doc(
                            firestore,
                            "metadata",
                            documentId,
                            "comment",
                            props.comment.commentId,
                            "reply",
                            replyId,
                        ),
                        {
                            documentId: documentId,
                            commentId: props.comment.commentId,
                            replyId: replyId,
                            authorUid: auth.currentUser.uid,
                            timestamp: timestamp,
                        },
                    );

                    await setDoc(
                        doc(
                            firestore,
                            "user",
                            props.comment.authorUid,
                            "notification",
                            replyId,
                        ),
                        {
                            messageType: "reply",
                            documentId: documentId,
                            commentId: props.comment.commentId,
                            replyId: replyId,
                            authorUid: auth.currentUser!.uid,
                            timestamp: timestamp,
                        },
                    );

                    setContent("");
                    dispatch(readActions.incrementNumReplies(1));
                    dispatch(coreActions.setIsLoading(false));
                } else {
                    alert("답글 쓰기는 로그인하여야 가능합니다.");

                    navigate("/login");

                    dispatch(coreActions.setIsLoading(false));
                }
            }
        }
    };

    return (
        <div className="Read-ReplyInputBody">
            <form>
        <textarea
            placeholder={"답글을 남겨주세요!"}
            wrap="soft"
            value={content}
            onChange={onChange}
        />
            </form>
            <button onClick={onClick}>등록</button>
        </div>
    );
}

function Replies() {
    const {slug} = useParams();

    const dispatch = useAppDispatch();

    const documentId = useAppSelector((state) => state.read.document.documentId);
    const clickedComment = useAppSelector((state) => state.read.clickedComment);
    const replies = useAppSelector((state) => state.read.replies);
    const repliesFetching = useAppSelector((state) => state.read.repliesFetching);
    const repliesFetched = useAppSelector((state) => state.read.repliesFetched);

    const auth = getAuth();
    const firestore = getFirestore();

    const [repliesLastVisible, setRepliesLastVisible] = useState<any>(undefined);
    const [repliesTimestamp, setRepliesTimestamp] = useState(Date.now());

    const getReplies = () => {
        if (slug! !== "" && clickedComment !== null) {
            let q;
            if (repliesLastVisible === -1) {
                return;
            } else if (repliesLastVisible !== undefined) {
                q = query(
                    collection(
                        firestore,
                        "board",
                        slug!,
                        "comment",
                        clickedComment,
                        "reply",
                    ),
                    orderBy("timestamp", "desc"),
                    limit(10),
                    startAfter(repliesLastVisible),
                );
            } else {
                dispatch(readActions.resetRepliesFetching());
                dispatch(readActions.resetRepliesFetched());

                q = query(
                    collection(
                        firestore,
                        "board",
                        slug!,
                        "comment",
                        clickedComment,
                        "reply",
                    ),
                    orderBy("timestamp", "desc"),
                    limit(10),
                );
            }

            onSnapshot(q, async (snapshot) => {
                const newReplies = snapshot.docs.map((elem) => elem.data());

                dispatch(readActions.incrementRepliesFetching(newReplies.length));

                for (const elem of newReplies) {
                    if (!elem.numReplies) {
                        getReply(elem);
                    }
                }

                if (snapshot.docs.length === 0) {
                    setRepliesLastVisible(-1);
                } else {
                    setRepliesLastVisible(snapshot.docs[snapshot.docs.length - 1]);
                }
            });
        }
    };

    const getReply = async (elem: any) => {
        if (auth.currentUser !== null) {
            const blockDocRef = doc(
                firestore,
                "user",
                auth.currentUser.uid,
                "block",
                elem.authorUid,
            );
            const blockDocSnap = await getDoc(blockDocRef);
            if (blockDocSnap.exists()) {
                dispatch(readActions.appendRepliesBlocked(elem.replyId));
                dispatch(readActions.incrementRepliesFetched(1));
                return;
            }
        }

        let authorAvatarUrl = "";
        let authorNickname = "";

        const userDocRef = doc(firestore, "user", elem.authorUid);

        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
            const data = docSnap.data();
            authorAvatarUrl = data.avatarUrl || "";
            authorNickname = data.nickname || "";
        } else {
            dispatch(readActions.appendRepliesBlocked(elem.replyId));
            dispatch(readActions.incrementRepliesFetched(1));
            return;
        }

        const upRef = doc(
            firestore,
            "metadata",
            documentId,
            "comment",
            clickedComment!,
            "reply",
            elem.replyId,
            "up",
            "summary",
        );
        const downRef = doc(
            firestore,
            "metadata",
            documentId,
            "comment",
            clickedComment!,
            "reply",
            elem.replyId,
            "down",
            "summary",
        );

        const upSnap = await getDoc(upRef);
        const downSnap = await getDoc(downRef);

        let numUps = 0;
        let numDowns = 0;

        if (upSnap.exists()) {
            numUps = upSnap.data().numUps || 0;
        }

        if (downSnap.exists()) {
            numDowns = downSnap.data().numDowns || 0;
        }

        let clickUp = false;
        let clickDown = false;

        if (auth.currentUser !== null) {
            const upDocRef = doc(
                firestore,
                "metadata",
                documentId,
                "comment",
                clickedComment!,
                "reply",
                elem.replyId,
                "up",
                auth.currentUser.uid,
            );

            const downDocRef = doc(
                firestore,
                "metadata",
                documentId,
                "comment",
                clickedComment!,
                "reply",
                elem.replyId,
                "down",
                auth.currentUser.uid,
            );

            await getDoc(upDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    clickUp = true;
                }
            });

            await getDoc(downDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    clickDown = true;
                }
            });
        }

        const reply: ReplyState = {
            replyId: elem.replyId,
            authorUid: elem.authorUid,
            authorAvatarUrl: authorAvatarUrl,
            authorNickname: authorNickname,
            timestamp: elem.timestamp,
            content: elem.content,
            numUps: numUps,
            numDowns: numDowns,
            clickUp: clickUp,
            clickDown: clickDown,
        };

        if (elem.timestamp < repliesTimestamp) {
            dispatch(readActions.appendReply(reply));
        }

        dispatch(readActions.incrementRepliesFetched(1));
    };

    useEffect(() => {
        dispatch(readActions.resetRepliesBlocked());
        dispatch(readActions.resetReplies());

        getReplies();
    }, [slug!, documentId, clickedComment]);

    const onClick = () => {
        getReplies();
    };

    if (repliesFetched < repliesFetching) {
        return (
            <div className="Read-Replies">
                <LoadingBar/>
                {replies.map((item, i) => {
                    return <Reply reply={item}/>;
                })}
                <div className="Read-ReadMoreReplies">
                    <button onClick={onClick}>답글 더보기</button>
                </div>
            </div>
        );
    } else {
        if (repliesLastVisible !== -1) {
            return (
                <div className="Read-Replies">
                    {replies.map((item, i) => {
                        return <Reply reply={item}/>;
                    })}
                    <div className="Read-ReadMoreReplies">
                        <button onClick={onClick}>답글 더보기</button>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="Read-Replies">
                    {replies.map((item, i) => {
                        return <Reply reply={item}/>;
                    })}
                </div>
            );
        }
    }
}

function Reply(props: { reply: ReplyState }) {
    return (
        <div className="Read-Reply">
            <ReplyHeader reply={props.reply}/>
            <ReplyBody reply={props.reply}/>
            <ReplySummary reply={props.reply}/>
        </div>
    );
}

function ReplyHeader(props: { reply: ReplyState }) {
    return (
        <div className="Read-ReplyHeader">
            <ReplyImage/>
            <ReplyAvatar reply={props.reply}/>
            <ReplyNickname reply={props.reply}/>
            <ReplyTime reply={props.reply}/>
            <ReplyMisc reply={props.reply}/>
        </div>
    );
}

function ReplyAvatar(props: { reply: ReplyState }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/profile_view/${props.reply.authorUid}`);
    };

    if (props.reply.authorAvatarUrl !== "") {
        return (
            <div className="Read-ReplyAvatar">
                <img src={props.reply.authorAvatarUrl} onClick={onClick}/>
            </div>
        );
    } else {
        return (
            <div className="Read-ReplyAvatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        );
    }
}

function ReplyTime(props: { reply: ReplyState }) {
    const endTimestamp = Date.now();

    const diff = endTimestamp - props.reply.timestamp;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 1) {
        return (
            <div className="Read-ReplyTime">
                <p>방금 전</p>
            </div>
        );
    } else if (hours < 1) {
        return (
            <div className="Read-ReplyTime">
                <p>{minutes} 분 전</p>
            </div>
        );
    } else if (days < 1) {
        return (
            <div className="Read-ReplyTime">
                <p>{hours} 시간 전</p>
            </div>
        );
    } else {
        const date = moment(props.reply.timestamp);

        return (
            <div className="Read-ReplyTime">
                <p>{date.format("YYYY-MM-DD")}</p>
            </div>
        );
    }
}

function ReplyNickname(props: { reply: ReplyState }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/profile_view/${props.reply.authorUid}`);
    };

    if (props.reply.authorNickname !== "") {
        return (
            <div className="Read-ReplyNickname">
                <button onClick={onClick}>{props.reply.authorNickname}</button>
            </div>
        );
    } else {
        return (
            <div className="Read-ReplyNickname">
                <button onClick={onClick}>닉네임 없음</button>
            </div>
        );
    }
}

function ReplyMisc(props: { reply: ReplyState }) {
    const isAdmin = useAppSelector((state) => state.core.isAdmin);

    const auth = getAuth();

    const [click, setClick] = useState(false);

    const onClick = () => {
        if (click) {
            setClick(false);
        } else {
            setClick(true);
        }
    };

    if (click) {
        if (
            auth.currentUser !== null &&
            (props.reply.authorUid === auth.currentUser.uid || isAdmin)
        ) {
            return (
                <div className="Read-ReplyMisc">
                    <button onClick={onClick}>
                        <img src={threeDots}/>
                    </button>
                    <div className="Read-ReplyMiscFocus-Mine">
                        <ReplyDelete reply={props.reply}/>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="Read-ReplyMisc">
                    <button onClick={onClick}>
                        <img src={threeDots}/>
                    </button>
                    <div className="Read-ReplyMiscFocus">
                        <ReplyBlock reply={props.reply}/>
                        <ReplyReport reply={props.reply}/>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className="Read-ReplyMisc">
                <button onClick={onClick}>
                    <img src={threeDots}/>
                </button>
            </div>
        );
    }
}

function ReplyBlock(props: { reply: ReplyState }) {
    const navigate = useNavigate();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            if (auth.currentUser.uid !== props.reply.authorNickname) {
                const answer = window.confirm(
                    `${props.reply.authorNickname}님의 게시글을 모두 차단하겠습니까?`,
                );

                if (answer) {
                    const timestamp = Date.now();

                    const docRef = doc(
                        firestore,
                        "user",
                        auth.currentUser.uid,
                        "block",
                        props.reply.authorUid,
                    );

                    await setDoc(docRef, {
                        uid: props.reply.authorUid,
                        timestamp: timestamp,
                    });

                    navigate(0);
                }
            } else {
                alert("본인을 차단할 수는 없습니다.");
            }
        } else {
            navigate("/login");
        }
    };

    return (
        <div className="Read-ReplyBlock">
            <button onClick={onClick}>
                <img src={block}/>
                <p>차단하기</p>
            </button>
        </div>
    );
}

function ReplyReport(props: { reply: ReplyState }) {
    const documentId = useAppSelector((state) => state.read.document.documentId);
    const clickedComment = useAppSelector((state) => state.read.clickedComment);

    const navigate = useNavigate();

    const auth = getAuth();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            navigate("/report", {
                state: {
                    documentId: documentId,
                    commentId: clickedComment!,
                    replyId: props.reply.replyId,
                },
            });
        } else {
            navigate("/login");
        }
    };

    return (
        <div className="Read-ReplyReport">
            <button onClick={onClick}>
                <img src={notify}/>
                <p>신고하기</p>
            </button>
        </div>
    );
}

function ReplyDelete(props: { reply: ReplyState }) {
    const documentId = useAppSelector((state) => state.read.document.documentId);
    const clickedComment = useAppSelector((state) => state.read.clickedComment);

    const auth = getAuth();
    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    const onClick = async () => {
        const answer = window.confirm("정말 삭제하시겠습니까?");

        if (answer) {
            dispatch(coreActions.setIsLoading(true));

            await deleteDoc(
                doc(
                    firestore,
                    "metadata",
                    documentId,
                    "comment",
                    clickedComment!,
                    "reply",
                    props.reply.replyId,
                ),
            );

            await deleteDoc(
                doc(
                    firestore,
                    "board",
                    documentId,
                    "comment",
                    clickedComment!,
                    "reply",
                    props.reply.replyId,
                ),
            );

            dispatch(readActions.removeReply(props.reply));
            dispatch(readActions.incrementNumReplies(-1));

            dispatch(coreActions.setIsLoading(false));
        }
    };

    return (
        <div className="Read-ReplyDelete">
            <button onClick={onClick}>
                <img src={trash}/>
                <p>삭제하기</p>
            </button>
        </div>
    );
}

function ReplyBody(props: { reply: ReplyState }) {
    return (
        <div className="Read-ReplyBody">
            <p>{props.reply.content}</p>
        </div>
    );
}

function ReplySummary(props: { reply: ReplyState }) {
    return (
        <div className="Read-ReplySummary">
            <ReplyNumUps reply={props.reply}/>
            <ReplyNumDowns reply={props.reply}/>
        </div>
    );
}

function ReplyNumUps(props: { reply: ReplyState }) {
    const documentId = useAppSelector((state) => state.read.document.documentId);
    const clickedComment = useAppSelector((state) => state.read.clickedComment);

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            const upDocRef = doc(
                firestore,
                "metadata",
                documentId,
                "comment",
                clickedComment!,
                "reply",
                props.reply.replyId,
                "up",
                auth.currentUser.uid,
            );

            if (props.reply.clickUp) {
                dispatch(readActions.unsetReplyClickUp(props.reply.replyId));

                await deleteDoc(upDocRef);
            } else {
                dispatch(readActions.setReplyClickUp(props.reply.replyId));

                const upTimestamp = Date.now();

                await setDoc(upDocRef, {
                    uid: auth.currentUser!.uid,
                    timestamp: upTimestamp,
                });
            }
        } else {
            navigate("/login");
        }
    };

    if (
        documentId !== "" &&
        clickedComment !== null &&
        auth.currentUser !== null
    ) {
        if (props.reply.clickUp) {
            return (
                <div className="Read-ReplyNumUps-Focus">
                    <button onClick={onClick}>
                        <img src={upFocus}/>
                        <p>{props.reply.numUps}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Read-ReplyNumUps">
                    <button onClick={onClick}>
                        <img src={up}/>
                        <p>{props.reply.numUps}</p>
                    </button>
                </div>
            );
        }
    } else {
        return (
            <div className="Read-ReplyNumUps">
                <button onClick={onClick}>
                    <img src={up}/>
                    <p>{props.reply.numUps}</p>
                </button>
            </div>
        );
    }
}

function ReplyNumDowns(props: { reply: ReplyState }) {
    const documentId = useAppSelector((state) => state.read.document.documentId);
    const clickedComment = useAppSelector((state) => state.read.clickedComment);

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            const downDocRef = doc(
                firestore,
                "metadata",
                documentId,
                "comment",
                clickedComment!,
                "reply",
                props.reply.replyId,
                "down",
                auth.currentUser.uid,
            );

            if (props.reply.clickDown) {
                dispatch(readActions.unsetReplyClickDown(props.reply.replyId));

                await deleteDoc(downDocRef);
            } else {
                dispatch(readActions.setReplyClickDown(props.reply.replyId));

                const downTimestamp = Date.now();

                await setDoc(downDocRef, {
                    uid: auth.currentUser!.uid,
                    timestamp: downTimestamp,
                });
            }
        } else {
            navigate("/login");
        }
    };

    if (
        documentId !== "" &&
        clickedComment !== null &&
        auth.currentUser !== null
    ) {
        if (props.reply.clickDown) {
            return (
                <div className="Read-ReplyNumDowns-Focus">
                    <button onClick={onClick}>
                        <img src={downFocus}/>
                        <p>{props.reply.numDowns}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Read-ReplyNumDowns">
                    <button onClick={onClick}>
                        <img src={down}/>
                        <p>{props.reply.numDowns}</p>
                    </button>
                </div>
            );
        }
    } else {
        return (
            <div className="Read-ReplyNumDowns">
                <button onClick={onClick}>
                    <img src={down}/>
                    <p>{props.reply.numDowns}</p>
                </button>
            </div>
        );
    }
}

function Split() {
    return (
        <div className="Read-Split">
            <img src={horizontalLine}/>
        </div>
    );
}

function NextDocuments(props: { documents: DocumentState[] }) {
    const document = useAppSelector((state) => state.read.document);

    const documents = props.documents.map((item, _) => {
        if (item.timestamp < document.timestamp) {
            return <NextDocument document={item}/>;
        }
    });

    return <div className="Read-NextDocuments">{documents}</div>;
}

function NextDocument(props: { document: DocumentState }) {
    if (props.document.hashtags.length > 0 && props.document.hashtags[0] !== "") {
        return (
            <div className="Read-NextDocument">
                <NextDocumentHeader document={props.document}/>
                <NextThumbnailContent document={props.document}/>
                <NextHashtags document={props.document}/>
                <NextReadMore document={props.document}/>
                <NextSummary document={props.document}/>
            </div>
        );
    } else {
        return (
            <div className="Read-NextDocument">
                <NextDocumentHeader document={props.document}/>
                <NextThumbnailContent document={props.document}/>
                <NextReadMore document={props.document}/>
                <NextSummary document={props.document}/>
            </div>
        );
    }
}

function NextDocumentHeader(props: { document: DocumentState }) {
    return (
        <div className="Read-NextDocumentHeader">
            <NextAvatar document={props.document}/>
            <NextNickname document={props.document}/>
            <NextTime document={props.document}/>
            <NextBookmark document={props.document}/>
            <NextMisc document={props.document}/>
        </div>
    );
}

function NextAvatar(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/profile_view/${props.document.authorUid}`);
    };

    if (props.document.authorAvatarUrl !== "") {
        return (
            <div className="Read-NextAvatar">
                <img src={props.document.authorAvatarUrl} onClick={onClick}/>
            </div>
        );
    } else {
        return (
            <div className="Read-NextAvatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        );
    }
}

function NextNickname(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/profile_view/${props.document.authorUid}`);
    };

    if (props.document.authorNickname !== "") {
        return (
            <div className="Read-NextNickname">
                <button onClick={onClick}>{props.document.authorNickname}</button>
            </div>
        );
    } else {
        return (
            <div className="Read-NextNickname">
                <button onClick={onClick}>닉네임 없음</button>
            </div>
        );
    }
}

function NextTime(props: { document: DocumentState }) {
    const endTimestamp = Date.now();

    const diff = endTimestamp - props.document.timestamp;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 1) {
        return (
            <div className="Read-NextTime">
                <p>방금 전</p>
            </div>
        );
    } else if (hours < 1) {
        return (
            <div className="Read-NextTime">
                <p>{minutes} 분 전</p>
            </div>
        );
    } else if (days < 1) {
        return (
            <div className="Read-NextTime">
                <p>{hours} 시간 전</p>
            </div>
        );
    } else {
        const date = moment(props.document.timestamp);

        return (
            <div className="Read-NextTime">
                <p>{date.format("YYYY-MM-DD")}</p>
            </div>
        );
    }
}

function NextBookmark(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            const bookmarkDocRef = doc(
                firestore,
                "user",
                auth.currentUser.uid,
                "bookmark",
                props.document.documentId,
            );

            if (props.document.clickBookmark) {
                dispatch(
                    readActions.unsetDocumentClickBookmark(props.document.documentId),
                );

                await deleteDoc(bookmarkDocRef);
            } else {
                dispatch(
                    readActions.setDocumentClickBookmark(props.document.documentId),
                );

                const timestamp = Date.now();

                await setDoc(bookmarkDocRef, {
                    documentId: props.document.documentId,
                    uid: auth.currentUser!.uid,
                    timestamp: timestamp,
                });
            }
        } else {
            navigate("/login");
        }
    };

    if (auth.currentUser !== null) {
        if (props.document.clickBookmark) {
            return (
                <div className="Read-NextBookmark">
                    <button onClick={onClick}>
                        <img src={bookmarkFocus}/>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Read-NextBookmark">
                    <button onClick={onClick}>
                        <img src={bookmark}/>
                    </button>
                </div>
            );
        }
    } else {
        return (
            <div className="Read-NextBookmark">
                <button onClick={onClick}>
                    <img src={bookmark}/>
                </button>
            </div>
        );
    }
}

function NextMisc(props: { document: DocumentState }) {
    const isAdmin = useAppSelector((state) => state.core.isAdmin);

    const auth = getAuth();

    const [click, setClick] = useState(false);

    const onClick = () => {
        if (click) {
            setClick(false);
        } else {
            setClick(true);
        }
    };

    if (click) {
        if (
            auth.currentUser !== null &&
            (props.document.authorUid === auth.currentUser.uid || isAdmin)
        ) {
            return (
                <div className="Read-NextMisc">
                    <button onClick={onClick}>
                        <img src={threeDots}/>
                    </button>
                    <div className="Read-NextMiscFocus-Mine">
                        <NextDelete document={props.document}/>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="Read-NextMisc">
                    <button onClick={onClick}>
                        <img src={threeDots}/>
                    </button>
                    <div className="Read-NextMiscFocus">
                        <NextBlock document={props.document}/>
                        <NextReport document={props.document}/>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className="Read-NextMisc">
                <button onClick={onClick}>
                    <img src={threeDots}/>
                </button>
            </div>
        );
    }
}

function NextBlock(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            if (auth.currentUser.uid !== props.document.authorNickname) {
                const answer = window.confirm(
                    `${props.document.authorNickname}님의 게시글을 모두 차단하겠습니까?`,
                );

                if (answer) {
                    const timestamp = Date.now();

                    const docRef = doc(
                        firestore,
                        "user",
                        auth.currentUser.uid,
                        "block",
                        props.document.authorUid,
                    );

                    await setDoc(docRef, {
                        uid: props.document.authorUid,
                        timestamp: timestamp,
                    });

                    navigate(0);
                }
            } else {
                alert("본인을 차단할 수는 없습니다.");
            }
        } else {
            navigate("/login");
        }
    };

    return (
        <div className="Read-NextBlock">
            <button onClick={onClick}>
                <img src={block}/>
                <p>차단하기</p>
            </button>
        </div>
    );
}

function NextReport(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const auth = getAuth();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            navigate("/report", {
                state: {
                    documentId: props.document.documentId,
                    commentId: "",
                    replyId: "",
                },
            });
        } else {
            navigate("/login");
        }
    };

    return (
        <div className="Read-NextReport">
            <button onClick={onClick}>
                <img src={notify}/>
                <p>신고하기</p>
            </button>
        </div>
    );
}

function NextDelete(props: { document: DocumentState }) {
    const auth = getAuth();
    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    const onClick = async () => {
        const answer = window.confirm("정말 삭제하시겠습니까?");

        if (answer) {
            dispatch(coreActions.setIsLoading(true));

            await deleteDoc(doc(firestore, "metadata", props.document.documentId));
            await deleteDoc(
                doc(
                    firestore,
                    "user",
                    auth.currentUser!.uid,
                    "post",
                    props.document.documentId,
                ),
            );

            for (const hashtag of props.document.hashtags) {
                if (hashtag !== "") {
                    await deleteDoc(
                        doc(
                            firestore,
                            "hashtag",
                            hashtag,
                            "post",
                            props.document.documentId,
                        ),
                    );
                }
            }

            await deleteDoc(doc(firestore, "board", props.document.documentId));

            dispatch(readActions.removeDocument(props.document));

            dispatch(coreActions.setIsLoading(false));
        }
    };

    return (
        <div className="Read-NextDelete">
            <button onClick={onClick}>
                <img src={trash}/>
                <p>삭제하기</p>
            </button>
        </div>
    );
}

function NextThumbnailContent(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions(),
    );

    const d: DocumentState = {
        documentId: props.document.documentId,
        authorUid: props.document.authorUid,
        authorAvatarUrl: props.document.authorAvatarUrl,
        authorNickname: props.document.authorNickname,
        timestamp: props.document.timestamp,
        content: props.document.content,
        thumbnailContent: [],
        thumbnailImageSizes: [],
        hashtags: props.document.hashtags,
        numUps: props.document.numUps,
        numDowns: props.document.numDowns,
        numComments: props.document.numComments,
        numTokens: props.document.numTokens,
        clickUp: props.document.clickUp,
        clickDown: props.document.clickDown,
        clickBookmark: props.document.clickBookmark,
    };

    const onClick = () => {
        navigate(`/read/${props.document.documentId}`, {
            state: {
                document: d,
            },
        });
        navigate(0);
    };

    function getWindowDimensions() {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height,
        };
    }

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className="Read-NextThumbnailContent">
            <button onClick={onClick}>
                {props.document.thumbnailContent.map((item, _) => {
                    if (item.props !== undefined && item.props.children !== undefined) {
                        if (item.props.children.type === "img") {
                            for (let imageSize of props.document.thumbnailImageSizes) {
                                if (imageSize.src === item.props.children.props.src) {
                                    if (
                                        0.99 * 0.44 * windowDimensions.width - 20 <
                                        imageSize.width
                                    ) {
                                        return (
                                            <img
                                                style={{
                                                    width: 0.85 * 0.44 * windowDimensions.width - 20,
                                                    maxHeight: imageSize.height,
                                                    objectFit: "cover",
                                                    objectPosition: "0% 0%",
                                                    backgroundColor: "transparent",
                                                }}
                                                src={item.props.children.props.src}
                                            />
                                        );
                                    } else {
                                        return (
                                            <img
                                                style={{
                                                    width: imageSize.width,
                                                    maxHeight: imageSize.height,
                                                    objectFit: "cover",
                                                    objectPosition: "0% 0%",
                                                    backgroundColor: "transparent",
                                                }}
                                                src={item.props.children.props.src}
                                            />
                                        );
                                    }
                                }
                            }

                            return item;
                        } else {
                            return item;
                        }
                    } else if (item.type === "img" && item.props !== undefined) {
                        for (let imageSize of props.document.thumbnailImageSizes) {
                            if (imageSize.src === item.props.src) {
                                if (
                                    0.99 * 0.44 * windowDimensions.width - 20 <
                                    imageSize.width
                                ) {
                                    return (
                                        <img
                                            style={{
                                                width: 0.85 * 0.44 * windowDimensions.width - 20,
                                                maxHeight: imageSize.height,
                                                objectFit: "cover",
                                                objectPosition: "0% 0%",
                                                backgroundColor: "transparent",
                                            }}
                                            src={item.props.src}
                                        />
                                    );
                                } else {
                                    return (
                                        <img
                                            style={{
                                                width: imageSize.width,
                                                maxHeight: imageSize.height,
                                                objectFit: "cover",
                                                objectPosition: "0% 0%",
                                                backgroundColor: "transparent",
                                            }}
                                            src={item.props.src}
                                        />
                                    );
                                }
                            }
                        }

                        return item;
                    } else {
                        return item;
                    }
                })}
            </button>
        </div>
    );
}

function NextHashtags(props: { document: DocumentState }) {
    return (
        <div className="Read-NextHashtags">
            {
                <ul>
                    {props.document.hashtags.map((hashtag, index) => (
                        <li key={index}>
                            <NextHashtag hashtag={hashtag}/>
                        </li>
                    ))}
                </ul>
            }
        </div>
    );
}

function NextHashtag(props: { hashtag: string }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/search_query/${props.hashtag.slice(1)}`);
    };

    return (
        <div className="Read-NextHashtag">
            <img src={hashtag}/>
            <button className="Read-NextHashtag-tag" onClick={onClick}>
                {props.hashtag.slice(1)}
            </button>
        </div>
    );
}

function NextReadMore(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const d: DocumentState = {
        documentId: props.document.documentId,
        authorUid: props.document.authorUid,
        authorAvatarUrl: props.document.authorAvatarUrl,
        authorNickname: props.document.authorNickname,
        timestamp: props.document.timestamp,
        content: props.document.content,
        thumbnailContent: [],
        thumbnailImageSizes: [],
        hashtags: props.document.hashtags,
        numUps: props.document.numUps,
        numDowns: props.document.numDowns,
        numComments: props.document.numComments,
        numTokens: props.document.numTokens,
        clickUp: props.document.clickUp,
        clickDown: props.document.clickDown,
        clickBookmark: props.document.clickBookmark,
    };

    const onClick = () => {
        navigate(`/read/${props.document.documentId}`, {
            state: {
                document: d,
            },
        });
        navigate(0);
    };

    return (
        <div className="Read-NextReadMore">
            <button onClick={onClick}>
                <img src={more}/>
            </button>
        </div>
    );
}

function NextSummary(props: { document: DocumentState }) {
    return (
        <div className="Read-NextSummary">
            <NextNumUps document={props.document}/>
            <NextNumDowns document={props.document}/>
            <NextNumComments document={props.document}/>
            <NextNumTokens document={props.document}/>
        </div>
    );
}

function NextNumUps(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            const upDocRef = doc(
                firestore,
                "metadata",
                props.document.documentId,
                "up",
                auth.currentUser.uid,
            );

            if (props.document.clickUp) {
                dispatch(readActions.unsetDocumentClickUp(props.document.documentId));

                await deleteDoc(upDocRef);
            } else {
                dispatch(readActions.setDocumentClickUp(props.document.documentId));

                const upTimestamp = Date.now();

                await setDoc(upDocRef, {
                    uid: auth.currentUser!.uid,
                    timestamp: upTimestamp,
                });
            }
        } else {
            navigate("/login");
        }
    };

    if (auth.currentUser !== null) {
        if (props.document.clickUp) {
            return (
                <div className="Read-NextNumUps-Focus">
                    <button onClick={onClick}>
                        <img src={upFocus}/>
                        <p>{props.document.numUps}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Read-NextNumUps">
                    <button onClick={onClick}>
                        <img src={up}/>
                        <p>{props.document.numUps}</p>
                    </button>
                </div>
            );
        }
    } else {
        return (
            <div className="Read-NextNumUps">
                <button onClick={onClick}>
                    <img src={up}/>
                    <p>{props.document.numUps}</p>
                </button>
            </div>
        );
    }
}

function NextNumDowns(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            const downDocRef = doc(
                firestore,
                "metadata",
                props.document.documentId,
                "down",
                auth.currentUser.uid,
            );

            if (props.document.clickDown) {
                dispatch(readActions.unsetDocumentClickDown(props.document.documentId));

                await deleteDoc(downDocRef);
            } else {
                dispatch(readActions.setDocumentClickDown(props.document.documentId));

                const downTimestamp = Date.now();

                await setDoc(downDocRef, {
                    uid: auth.currentUser!.uid,
                    timestamp: downTimestamp,
                });
            }
        } else {
            navigate("/login");
        }
    };

    if (auth.currentUser !== null) {
        if (props.document.clickDown) {
            return (
                <div className="Read-NextNumDowns-Focus">
                    <button onClick={onClick}>
                        <img src={downFocus}/>
                        <p>{props.document.numDowns}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Read-NextNumDowns">
                    <button onClick={onClick}>
                        <img src={down}/>
                        <p>{props.document.numDowns}</p>
                    </button>
                </div>
            );
        }
    } else {
        return (
            <div className="Read-NextNumDowns">
                <button onClick={onClick}>
                    <img src={down}/>
                    <p>{props.document.numDowns}</p>
                </button>
            </div>
        );
    }
}

function NextNumComments(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/read/${props.document.documentId}`);
        navigate(0);
    };

    return (
        <div className="Read-NextNumComments">
            <button onClick={onClick}>
                <img src={comment}/>
                <p>{props.document.numComments}</p>
            </button>
        </div>
    );
}

function NextNumTokens(props: { document: DocumentState }) {
    return (
        <div className="Read-NextNumTokens">
            <button>
                <p>TOKEN</p>
                <ComingSoon/>
            </button>
        </div>
    );
}
