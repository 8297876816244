import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ReplyState} from "./reply";
import {DocumentState} from "./document";
import {CommentState} from "./comment";

export interface ReportViewState {
    reportId: string;
    documentId: string;
    commentId: string;
    replyId: string;
    document: DocumentState | null;
    comment: CommentState | null;
    reply: ReplyState | null;
}

const initialState: ReportViewState = {
    reportId: "",
    documentId: "",
    commentId: "",
    replyId: "",
    document: null,
    comment: null,
    reply: null,
};

export const reportView = createSlice({
    name: "reportView",
    initialState,
    reducers: {
        setReportId(state, action: PayloadAction<string>) {
            state.reportId = action.payload;
        },

        setDocumentId(state, action: PayloadAction<string>) {
            state.documentId = action.payload;
        },

        setCommentId(state, action: PayloadAction<string>) {
            state.commentId = action.payload;
        },

        setReplyId(state, action: PayloadAction<string>) {
            state.replyId = action.payload;
        },

        setDocument(state, action: PayloadAction<DocumentState>) {
            state.document = action.payload;
        },

        setComment(state, action: PayloadAction<CommentState>) {
            state.comment = action.payload;
        },

        setReply(state, action: PayloadAction<ReplyState>) {
            state.reply = action.payload;
        },
    },
});

export const reportViewActions = reportView.actions;
export default reportView.reducer;
