import React from "react";
import "./App.css";
import {isMobile} from "react-device-detect";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Home} from "./desktop/home/home";
import {Write} from "./desktop/write/write";
import {NaverLogin} from "./desktop/naver_login/naver_login";
import {Provider} from "react-redux";
import {store} from "./module";
import "./firebase";
import {Login} from "./desktop/login/login";
import {Profile} from "./desktop/profile/profile";
import {ProfileView} from "./desktop/profile_view/profile_view";
import {MobileLogin} from "./mobile/login/mobile_login";
import {Eula} from "./desktop/eula/eula";
import {MobileEula} from "./mobile/eula/mobile_eula";
import {Read} from "./desktop/read/read";
import {SignUp} from "./desktop/sign_up/sign_up";
import {PasswordReset} from "./desktop/password_reset/password_reset";
import {EmailVerification} from "./desktop/email_verification/email_verification";
import {Following} from "./desktop/following/following";
import {Bookmark} from "./desktop/bookmark/bookmark";
import {Search} from "./desktop/search/search";
import {SearchQuery} from "./desktop/search_query/search_query";
import {Notification} from "./desktop/notification/notification";
import {NotificationView} from "./desktop/notification_view/notification_view";
import {Block} from "./desktop/block/block";
import {Report} from "./desktop/report/report";
import {Admin} from "./desktop/admin/admin";
import {ReportView} from "./desktop/report_view/report_view";
import {Event} from "./desktop/event/event";
import {EventWrite} from "./desktop/event_write/event_write";
import {Notice} from "./desktop/notice/notice";
import {NoticeWrite} from "./desktop/notice_write/notice_write";
import {Ranking} from "./desktop/ranking/ranking";
import {MobileSignUp} from "./mobile/sign_up/mobile_sign_up";
import {MobileEmailVerification} from "./mobile/email_verification/mobile_email_verification";
import {MobilePasswordReset} from "./mobile/password_reset/mobile_password_reset";
import {MobileHome} from "./mobile/home/mobile_home";
import {MobileFollowing} from "./mobile/following/mobile_following";
import {MobileBookmark} from "./mobile/bookmark/mobile_bookmark";
import {MobileSearch} from "./mobile/search/mobile_search";
import {MobileSearchQuery} from "./mobile/search_query/mobile_search_query";
import {MobileProfile} from "./mobile/profile/mobile_profile";
import {MobileProfileView} from "./mobile/profile_view/mobile_profile_view";
import {MobileRead} from "./mobile/read/mobile_read";
import {EasySignUp} from "./desktop/easy_sign_up/easy_sign_up";
import {MobileEasySignUp} from "./mobile/easy_sign_up/mobile_easy_sign_up";
import {MobileBlock} from "./mobile/block/mobile_block";
import {MobileEvent} from "./mobile/event/mobile_event";
import {MobileNotice} from "./mobile/notice/mobile_notice";
import {MobileNotification} from "./mobile/notification/mobile_notification";
import {MobileNotificationView} from "./mobile/notification_view/mobile_notification_view";
import {MobileRanking} from "./mobile/ranking/mobile_ranking";
import {MobileReport} from "./mobile/report/mobile_report";
import {MobileWrite} from "./mobile/write/mobile_write";

function App() {
    if (!isMobile) {
        return (
            <BrowserRouter>
                <div className="App">
                    <Provider store={store}>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/following" element={<Following/>}/>
                            <Route path="/bookmark" element={<Bookmark/>}/>
                            <Route path="/read/:slug" element={<Read/>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/eula" element={<Eula/>}/>
                            <Route path="/sign_up" element={<SignUp/>}/>
                            <Route path="/easy_sign_up" element={<EasySignUp/>}/>
                            <Route path="/password_reset" element={<PasswordReset/>}/>
                            <Route
                                path="/email_verification"
                                element={<EmailVerification/>}
                            />
                            <Route path="/profile" element={<Profile/>}/>
                            <Route path="/profile_view/:slug" element={<ProfileView/>}/>
                            <Route path="/write" element={<Write/>}/>
                            <Route path="/search" element={<Search/>}/>
                            <Route path="/search_query/:slug" element={<SearchQuery/>}/>
                            <Route path="/notification" element={<Notification/>}/>
                            <Route path="/notification_view" element={<NotificationView/>}/>
                            <Route path="/block" element={<Block/>}/>
                            <Route path="/report" element={<Report/>}/>
                            <Route path="/admin" element={<Admin/>}/>
                            <Route path="/report_view" element={<ReportView/>}/>
                            <Route path="/event" element={<Event/>}/>
                            <Route path="/event_write" element={<EventWrite/>}/>
                            <Route path="/notice" element={<Notice/>}/>
                            <Route path="/notice_write" element={<NoticeWrite/>}/>
                            <Route path="/ranking" element={<Ranking/>}/>
                            <Route path="/naver_login" element={<NaverLogin/>}/>
                        </Routes>
                    </Provider>
                </div>
            </BrowserRouter>
        );
    } else {
        return (
            <BrowserRouter>
                <div className="App">
                    <Provider store={store}>
                        <Routes>
                            <Route path="/" element={<MobileHome/>}/>
                            <Route path="/following" element={<MobileFollowing/>}/>
                            <Route path="/bookmark" element={<MobileBookmark/>}/>
                            <Route path="/search" element={<MobileSearch/>}/>
                            <Route
                                path="/search_query/:slug"
                                element={<MobileSearchQuery/>}
                            />
                            <Route path="/read/:slug" element={<MobileRead/>}/>
                            <Route path="/login" element={<MobileLogin/>}/>
                            <Route path="/profile" element={<MobileProfile/>}/>
                            <Route
                                path="/profile_view/:slug"
                                element={<MobileProfileView/>}
                            />
                            <Route path="/eula" element={<MobileEula/>}/>
                            <Route path="/sign_up" element={<MobileSignUp/>}/>
                            <Route path="/easy_sign_up" element={<MobileEasySignUp/>}/>
                            <Route path="/password_reset" element={<MobilePasswordReset/>}/>
                            <Route
                                path="/email_verification"
                                element={<MobileEmailVerification/>}
                            />
                            <Route path="/block" element={<MobileBlock/>}/>
                            <Route path="/event" element={<MobileEvent/>}/>
                            <Route path="/notice" element={<MobileNotice/>}/>
                            <Route path="/notification" element={<MobileNotification/>}/>
                            <Route
                                path="/notification_view"
                                element={<MobileNotificationView/>}
                            />
                            <Route path="/ranking" element={<MobileRanking/>}/>
                            <Route path="/report" element={<MobileReport/>}/>
                            <Route path="/write" element={<MobileWrite/>}/>
                        </Routes>
                    </Provider>
                </div>
            </BrowserRouter>
        );
    }
}

export default App;
