import {configureStore} from "@reduxjs/toolkit";
import coreReducer from "./core";
import loginReducer from "./login";
import eulaReducer from "./eula";
import signUpReducer from "./sign_up";
import homeReducer from "./home";
import readReducer from "./read";
import documentReducer from "./document";
import writeReducer from "./write";
import profileReducer from "./profile";
import profileViewReducer from "./profile_view";
import passwordResetReducer from "./password_reset";
import emailVerificationReducer from "./email_verification";
import followingReducer from "./following";
import bookmarkReducer from "./bookmark";
import searchReducer from "./search";
import searchQueryReducer from "./search_query";
import notificationReducer from "./notification";
import notificationViewReducer from "./notification_view";
import blockReducer from "./block";
import reportReducer from "./report";
import adminReducer from "./admin";
import reportViewReducer from "./report_view";
import eventReducer from "./event";
import eventWriteReducer from "./event_write";
import noticeReducer from "./notice";
import noticeWriteReducer from "./notice_write";
import rankingReducer from "./ranking";
import rightSidebarReducer from "./right_sidebar";

export const store = configureStore({
    reducer: {
        core: coreReducer,
        login: loginReducer,
        eula: eulaReducer,
        signUp: signUpReducer,
        passwordReset: passwordResetReducer,
        emailVerification: emailVerificationReducer,
        home: homeReducer,
        following: followingReducer,
        bookmark: bookmarkReducer,
        read: readReducer,
        profile: profileReducer,
        profileView: profileViewReducer,
        document: documentReducer,
        write: writeReducer,
        search: searchReducer,
        searchQuery: searchQueryReducer,
        notification: notificationReducer,
        notificationView: notificationViewReducer,
        block: blockReducer,
        report: reportReducer,
        admin: adminReducer,
        reportView: reportViewReducer,
        event: eventReducer,
        eventWrite: eventWriteReducer,
        notice: noticeReducer,
        noticeWrite: noticeWriteReducer,
        ranking: rankingReducer,
        rightSidebar: rightSidebarReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
