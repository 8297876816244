import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface CoreState {
    isAdmin: boolean;
    isLoading: boolean;
    focus: string;
}

const initialState: CoreState = {
    isAdmin: false,
    isLoading: false,
    focus: "recommendation",
};

export const core = createSlice({
    name: "core",
    initialState,
    reducers: {
        setIsAdmin(state, action: PayloadAction<boolean>) {
            state.isAdmin = action.payload;
        },

        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },

        setFocus(state, action: PayloadAction<string>) {
            state.focus = action.payload;
        },
    },
});

export const coreActions = core.actions;
export default core.reducer;
