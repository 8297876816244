import {DocumentState} from "../../module/document";
import {useAppDispatch, useAppSelector} from "../../module/hook";
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getFirestore,
    limit,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    startAfter,
} from "firebase/firestore";
import {useNavigate} from "react-router-dom";
import defaultAvatar from "../../asset/image/default_avatar.png";
import {useBottomScrollListener} from "react-bottom-scroll-listener";
import React, {useEffect, useState} from "react";
import "./bookmark.css";
import {Header} from "../header";
import {Sidebar} from "../sidebar";
import moment from "moment";
import parse from "html-react-parser";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import threeDots from "../../asset/image/three_dots.png";
import comment from "../../asset/image/comment.png";
import bookmark from "../../asset/image/bookmark.png";
import bookmarkFocus from "../../asset/image/bookmark_focus.png";
import hashtag from "../../asset/image/hashtag.png";
import {RightSidebar} from "../right_sidebar";
import upFocus from "../../asset/image/up_focus.png";
import up from "../../asset/image/up.png";
import down from "../../asset/image/down.png";
import downFocus from "../../asset/image/down_focus.png";
import more from "../../asset/image/more.png";
import comingSoon from "../../asset/image/coming_soon.png";
import LinearProgress from "@mui/material/LinearProgress";
import {coreActions} from "../../module/core";
import block from "../../asset/image/block.png";
import notify from "../../asset/image/notify.png";
import {bookmarkActions} from "../../module/bookmark";
import trash from "../../asset/image/trash.png";

export function Bookmark() {
    const navigate = useNavigate();

    const auth = getAuth();
    const firestore = getFirestore();

    const fetching = useAppSelector((state) => state.bookmark.fetching);
    const fetched = useAppSelector((state) => state.bookmark.fetched);
    const documents = useAppSelector((state) => state.bookmark.documents);

    const dispatch = useAppDispatch();

    const [images, setImages] = useState(new Map());
    const [timestamp, setTimestamp] = useState(Date.now());
    const [lastVisible, setLastVisible] = useState<any>(undefined);

    async function reactiveLength(content: any) {
        if (Array.isArray(content) === true) {
            let length = 0;
            for (let child of content) {
                if (Array.isArray(child.props.children)) {
                    length += await reactiveLength(child);
                } else {
                    if (child.props.children && child.props.children.type === "img") {
                        let img;
                        if (images.has(child.props.children.props.src)) {
                            img = images.get(child.props.children.props.src);
                        } else {
                            img = new Image();

                            img.src = child.props.children.props.src;
                            await img.decode();

                            images.set(child.props.children.props.src, img);
                        }

                        if (img.naturalHeight) {
                            length += img.naturalHeight;
                        } else {
                            length += 1;
                        }
                    } else if (child.props.children && child.props.children.length) {
                        length += child.props.children.length;
                    } else {
                        length += 1;
                    }
                }
            }

            setImages(images);
            return length;
        } else if (
            content.hasOwnProperty("props") &&
            content.props.hasOwnProperty("children") &&
            Array.isArray(content.props.children)
        ) {
            let length = 0;
            for (let child of content.props.children) {
                if (
                    child.hasOwnProperty("props") &&
                    child.props.hasOwnProperty("children") &&
                    Array.isArray(child.props.children)
                ) {
                    length += await reactiveLength(child);
                } else {
                    if (child.type === "img") {
                        let img;
                        if (images.has(child.props.src)) {
                            img = images.get(child.props.src);
                        } else {
                            img = new Image();

                            img.src = child.props.src;
                            await img.decode();

                            images.set(child.props.src, img);
                        }

                        if (img.naturalHeight) {
                            length += img.naturalHeight;
                        } else {
                            length += 1;
                        }
                    }

                    length += 1;
                }
            }

            setImages(images);
            return length;
        } else if (
            content.hasOwnProperty("props") &&
            content.props.hasOwnProperty("children") &&
            content.props.children &&
            content.props.children.type === "img"
        ) {
            let img;
            if (images.has(content.props.children.props.src)) {
                img = images.get(content.props.children.props.src);
            } else {
                img = new Image();

                img.src = content.props.children.props.src;
                await img.decode();

                images.set(content.props.children.props.src, img);
                setImages(images);
            }

            if (img.naturalHeight) {
                return img.naturalHeight;
            } else {
                return 1;
            }
        } else {
            return 1;
        }
    }

    async function extractContent(
        content: any,
        pos: number,
        accum: number,
    ): Promise<any> {
        if (Array.isArray(content) === true) {
            for (let i = 0; i < content.length; i++) {
                const child = content[i];

                if (accum <= pos && accum + (await reactiveLength(child)) > pos) {
                    return extractContent(child, pos, accum);
                } else if (accum > pos) {
                    return;
                } else {
                    accum += await reactiveLength(child);
                }
            }
        } else if (
            content.hasOwnProperty("props") &&
            content.props.hasOwnProperty("children") &&
            Array.isArray(content.props.children) === true
        ) {
            for (let i = 0; i < content.props.children.length; i++) {
                const child = content.props.children[i];

                if (accum <= pos && accum + (await reactiveLength(child)) > pos) {
                    return extractContent(child, pos, accum);
                } else if (accum > pos) {
                    return;
                } else {
                    accum += await reactiveLength(child);
                }
            }
        } else {
            if (pos === accum) {
                return content;
            }
        }
    }

    const getDocuments = async () => {
        if (auth.currentUser !== null) {
            let q;
            if (lastVisible === -1) {
                return;
            } else if (lastVisible !== undefined) {
                q = query(
                    collection(firestore, "user", auth.currentUser!.uid, "bookmark"),
                    orderBy("timestamp", "desc"),
                    limit(10),
                    startAfter(lastVisible),
                );
            } else {
                dispatch(bookmarkActions.resetFetching());
                dispatch(bookmarkActions.resetFetched());

                q = query(
                    collection(firestore, "user", auth.currentUser!.uid, "bookmark"),
                    orderBy("timestamp", "desc"),
                    limit(10),
                );
            }

            onSnapshot(q, async (snapshot) => {
                const newDocuments = snapshot.docs.map((elem) => elem.data());

                dispatch(bookmarkActions.incrementFetching(newDocuments.length));

                for (const elem of newDocuments) {
                    getDocument(elem);
                }

                if (snapshot.docs.length === 0) {
                    setLastVisible(-1);
                } else {
                    setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
                }
            });
        }
    };

    const getDocument = async (elem: any) => {
        const docRef = doc(firestore, "board", elem.documentId);

        getDoc(docRef).then(async (docSnap) => {
            if (docSnap.exists()) {
                if (auth.currentUser !== null) {
                    const blockDocRef = doc(
                        firestore,
                        "user",
                        auth.currentUser.uid,
                        "block",
                        docSnap.data()!.authorUid,
                    );
                    const blockDocSnap = await getDoc(blockDocRef);
                    if (blockDocSnap.exists()) {
                        dispatch(bookmarkActions.appendBlocked(elem.documentId));
                        dispatch(bookmarkActions.incrementFetched(1));
                        return;
                    }
                }

                let authorAvatarUrl = "";
                let authorNickname = "";

                const userDocRef = doc(firestore, "user", docSnap.data()!.authorUid);

                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    const data = userDocSnap.data();
                    authorAvatarUrl = data.avatarUrl || "";
                    authorNickname = data.nickname || "";
                } else {
                    dispatch(bookmarkActions.appendBlocked(elem.documentId));
                    dispatch(bookmarkActions.incrementFetched(1));
                    return;
                }

                const parsedContent = parse(docSnap.data()!.content);

                const lengthLimit = 700;
                const length = await reactiveLength(parsedContent);

                let thumbnailContent: any[] = [];
                let thumbnailImageSizes: any[] = [];

                if (length >= 100) {
                    let index = 0;
                    let extractLength = 0;

                    let batchedContent = [];

                    while (index < 5 && extractLength < lengthLimit) {
                        let ec = await extractContent(parsedContent, index, 0);

                        if (
                            ec &&
                            ec.props !== undefined &&
                            ec.props.children !== undefined
                        ) {
                            if (ec.props.children.type === "img") {
                                let img;
                                if (images.has(ec.props.children.props.src)) {
                                    img = images.get(ec.props.children.props.src);
                                } else {
                                    img = new Image();

                                    img.src = ec.props.children.props.src;
                                    await img.decode();

                                    images.set(ec.props.children.props.src, img);
                                    setImages(images);
                                }

                                if (img.naturalHeight) {
                                    if (extractLength + img.naturalHeight > lengthLimit) {
                                        thumbnailImageSizes = [
                                            ...thumbnailImageSizes,
                                            {
                                                src: ec.props.children.props.src,
                                                width: img.naturalWidth,
                                                height: lengthLimit - extractLength,
                                            },
                                        ];

                                        extractLength += lengthLimit - extractLength;
                                    } else {
                                        thumbnailImageSizes = [
                                            ...thumbnailImageSizes,
                                            {
                                                src: ec.props.children.props.src,
                                                width: img.naturalWidth,
                                                height: img.naturalHeight,
                                            },
                                        ];

                                        extractLength += img.naturalHeight;
                                    }
                                } else {
                                    extractLength += 1;
                                }
                            } else if (ec.props.children.length) {
                                extractLength += ec.props.children.length;
                            } else {
                                extractLength += 1;
                            }

                            batchedContent.push(ec);
                        } else if (ec && ec.type === "img") {
                            let img;
                            if (images.has(ec.props!.src)) {
                                img = images.get(ec.props.src);
                            } else {
                                img = new Image();

                                img.src = ec.props.src;
                                await img.decode();

                                images.set(ec.props.src, img);
                                setImages(images);
                            }

                            if (img.naturalHeight) {
                                if (extractLength + img.naturalHeight > lengthLimit) {
                                    thumbnailImageSizes = [
                                        ...thumbnailImageSizes,
                                        {
                                            src: ec.props.src,
                                            width: img.naturalWidth,
                                            height: lengthLimit - extractLength,
                                        },
                                    ];

                                    extractLength += lengthLimit - extractLength;
                                } else {
                                    thumbnailImageSizes = [
                                        ...thumbnailImageSizes,
                                        {
                                            src: ec.props.src,
                                            width: img.naturalWidth,
                                            height: img.naturalHeight,
                                        },
                                    ];

                                    extractLength += img.naturalHeight;
                                }
                            } else {
                                extractLength += 1;
                            }

                            batchedContent.push(ec);
                        } else if (ec) {
                            extractLength += 1;

                            batchedContent.push(ec);
                        } else {
                            extractLength += 1;
                        }

                        index += 1;
                    }

                    thumbnailContent = batchedContent;
                } else {
                    thumbnailContent = [parsedContent];
                }

                const upRef = doc(
                    firestore,
                    "metadata",
                    elem.documentId,
                    "up",
                    "summary",
                );
                const downRef = doc(
                    firestore,
                    "metadata",
                    elem.documentId,
                    "down",
                    "summary",
                );
                const commentRef = doc(
                    firestore,
                    "board",
                    elem.documentId,
                    "comment",
                    "summary",
                );

                const upSnap = await getDoc(upRef);
                const downSnap = await getDoc(downRef);
                const commentSnap = await getDoc(commentRef);

                let numUps = 0;
                let numDowns = 0;
                let numComments = 0;

                if (upSnap.exists()) {
                    numUps = upSnap.data().numUps || 0;
                }

                if (downSnap.exists()) {
                    numDowns = downSnap.data().numDowns || 0;
                }

                if (commentSnap.exists()) {
                    numComments = commentSnap.data().numComments || 0;
                }

                let clickUp = false;
                let clickDown = false;
                let clickBookmark = false;

                if (auth.currentUser !== null) {
                    const upDocRef = doc(
                        firestore,
                        "metadata",
                        elem.documentId,
                        "up",
                        auth.currentUser.uid,
                    );

                    const downDocRef = doc(
                        firestore,
                        "metadata",
                        elem.documentId,
                        "down",
                        auth.currentUser.uid,
                    );

                    const bookmarkDocRef = doc(
                        firestore,
                        "user",
                        auth.currentUser.uid,
                        "bookmark",
                        elem.documentId,
                    );

                    await getDoc(upDocRef).then((docSnap) => {
                        if (docSnap.exists()) {
                            clickUp = true;
                        }
                    });

                    await getDoc(downDocRef).then((docSnap) => {
                        if (docSnap.exists()) {
                            clickDown = true;
                        }
                    });

                    await getDoc(bookmarkDocRef).then((docSnap) => {
                        if (docSnap.exists()) {
                            clickBookmark = true;
                        }
                    });
                }

                const document: DocumentState = {
                    documentId: elem.documentId,
                    authorUid: docSnap.data()!.authorUid,
                    authorAvatarUrl: authorAvatarUrl,
                    authorNickname: authorNickname,
                    timestamp: docSnap.data()!.timestamp,
                    content: docSnap.data()!.content,
                    thumbnailContent: thumbnailContent,
                    thumbnailImageSizes: thumbnailImageSizes,
                    hashtags: docSnap.data()!.hashtags.split(","),
                    numUps: numUps,
                    numDowns: numDowns,
                    numComments: numComments,
                    numTokens: 0,
                    clickUp: clickUp,
                    clickDown: clickDown,
                    clickBookmark: clickBookmark,
                };

                if (elem.timestamp < timestamp) {
                    dispatch(bookmarkActions.appendDocument(document));
                }

                dispatch(bookmarkActions.incrementFetched(1));
            } else {
                dispatch(bookmarkActions.appendBlocked(elem.documentId));
                dispatch(bookmarkActions.incrementFetched(1));
            }
        });
    };

    useEffect(() => {
        if (auth.currentUser === null) {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    dispatch(coreActions.setFocus("bookmark"));

                    dispatch(bookmarkActions.resetBlocked());
                    dispatch(bookmarkActions.resetDocuments());

                    setLastVisible(undefined);

                    getDocuments();
                } else {
                    navigate("/login");
                }
            });
        } else {
            dispatch(coreActions.setFocus("bookmark"));

            dispatch(bookmarkActions.resetBlocked());
            dispatch(bookmarkActions.resetDocuments());

            setLastVisible(undefined);

            getDocuments();
        }
    }, [auth.currentUser]);

    useEffect(() => {
        const handleScroll = () => {
            if (fetched === fetching && window.scrollY > window.outerHeight / 3) {
                getDocuments();
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [fetched, fetching]);

    useBottomScrollListener(getDocuments);

    return (
        <div className="Bookmark">
            <Body documents={documents}/>
        </div>
    );
}

function Body(props: { documents: DocumentState[] }) {
    const fetching = useAppSelector((state) => state.bookmark.fetching);
    const fetched = useAppSelector((state) => state.bookmark.fetched);

    if (fetched < fetching) {
        return (
            <div className="Bookmark-Body">
                <Header/>
                <Sidebar/>
                <Title/>
                <LoadingBar/>
                <Documents documents={props.documents}/>
                <RightSidebar/>
            </div>
        );
    } else {
        return (
            <div className="Bookmark-Body">
                <Header/>
                <Sidebar/>
                <Title/>
                <Documents documents={props.documents}/>
                <RightSidebar/>
            </div>
        );
    }
}

function Title() {
    return (
        <div className="Bookmark-Title">
            <h1>북마크</h1>
        </div>
    );
}

function LoadingBar() {
    return (
        <div className="Bookmark-LoadingBar">
            <LinearProgress/>
        </div>
    );
}

function Documents(props: { documents: DocumentState[] }) {
    const documents = props.documents.map((item, _) => {
        return <Document document={item}/>;
    });

    return <div className="Bookmark-Documents">{documents}</div>;
}

function Document(props: { document: DocumentState }) {
    if (props.document.hashtags.length > 0 && props.document.hashtags[0] !== "") {
        return (
            <div className="Bookmark-Document">
                <DocumentHeader document={props.document}/>
                <ThumbnailContent document={props.document}/>
                <Hashtags document={props.document}/>
                <ReadMore document={props.document}/>
                <Summary document={props.document}/>
            </div>
        );
    } else {
        return (
            <div className="Bookmark-Document">
                <DocumentHeader document={props.document}/>
                <ThumbnailContent document={props.document}/>
                <ReadMore document={props.document}/>
                <Summary document={props.document}/>
            </div>
        );
    }
}

function DocumentHeader(props: { document: DocumentState }) {
    return (
        <div className="Bookmark-DocumentHeader">
            <Avatar document={props.document}/>
            <Nickname document={props.document}/>
            <Time document={props.document}/>
            <BookmarkButton document={props.document}/>
            <Misc document={props.document}/>
        </div>
    );
}

function Avatar(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/profile_view/${props.document.authorUid}`);
    };

    if (props.document.authorAvatarUrl !== "") {
        return (
            <div className="Bookmark-Avatar">
                <img src={props.document.authorAvatarUrl} onClick={onClick}/>
            </div>
        );
    } else {
        return (
            <div className="Bookmark-Avatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        );
    }
}

function Nickname(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/profile_view/${props.document.authorUid}`);
    };

    if (props.document.authorNickname !== "") {
        return (
            <div className="Bookmark-Nickname">
                <button onClick={onClick}>{props.document.authorNickname}</button>
            </div>
        );
    } else {
        return (
            <div className="Bookmark-Nickname">
                <button onClick={onClick}>닉네임 없음</button>
            </div>
        );
    }
}

function Time(props: { document: DocumentState }) {
    const endTimestamp = Date.now();

    const diff = endTimestamp - props.document.timestamp;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 1) {
        return (
            <div className="Bookmark-Time">
                <p>방금 전</p>
            </div>
        );
    } else if (hours < 1) {
        return (
            <div className="Bookmark-Time">
                <p>{minutes} 분 전</p>
            </div>
        );
    } else if (days < 1) {
        return (
            <div className="Bookmark-Time">
                <p>{hours} 시간 전</p>
            </div>
        );
    } else {
        const date = moment(props.document.timestamp);

        return (
            <div className="Bookmark-Time">
                <p>{date.format("YYYY-MM-DD")}</p>
            </div>
        );
    }
}

function BookmarkButton(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            const bookmarkDocRef = doc(
                firestore,
                "user",
                auth.currentUser.uid,
                "bookmark",
                props.document.documentId,
            );

            if (props.document.clickBookmark) {
                dispatch(
                    bookmarkActions.unsetDocumentClickBookmark(props.document.documentId),
                );

                await deleteDoc(bookmarkDocRef);
            } else {
                dispatch(
                    bookmarkActions.setDocumentClickBookmark(props.document.documentId),
                );

                const timestamp = Date.now();

                await setDoc(bookmarkDocRef, {
                    documentId: props.document.documentId,
                    uid: auth.currentUser!.uid,
                    timestamp: timestamp,
                });
            }
        } else {
            navigate("/login");
        }
    };

    if (auth.currentUser !== null) {
        if (props.document.clickBookmark) {
            return (
                <div className="Bookmark-BookmarkButton">
                    <button onClick={onClick}>
                        <img src={bookmarkFocus}/>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Bookmark-BookmarkButton">
                    <button onClick={onClick}>
                        <img src={bookmark}/>
                    </button>
                </div>
            );
        }
    } else {
        return (
            <div className="Bookmark-BookmarkButton">
                <button onClick={onClick}>
                    <img src={bookmark}/>
                </button>
            </div>
        );
    }
}

function Misc(props: { document: DocumentState }) {
    const isAdmin = useAppSelector((state) => state.core.isAdmin);

    const auth = getAuth();

    const [click, setClick] = useState(false);

    const onClick = () => {
        if (click) {
            setClick(false);
        } else {
            setClick(true);
        }
    };

    if (click) {
        if (
            auth.currentUser !== null &&
            (props.document.authorUid === auth.currentUser.uid || isAdmin)
        ) {
            return (
                <div className="Bookmark-Misc">
                    <button onClick={onClick}>
                        <img src={threeDots}/>
                    </button>
                    <div className="Bookmark-MiscFocus-Mine">
                        <Delete document={props.document}/>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="Bookmark-Misc">
                    <button onClick={onClick}>
                        <img src={threeDots}/>
                    </button>
                    <div className="Bookmark-MiscFocus">
                        <Block document={props.document}/>
                        <Report document={props.document}/>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className="Bookmark-Misc">
                <button onClick={onClick}>
                    <img src={threeDots}/>
                </button>
            </div>
        );
    }
}

function Block(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            if (auth.currentUser.uid !== props.document.authorNickname) {
                const answer = window.confirm(
                    `${props.document.authorNickname}님의 게시글을 모두 차단하겠습니까?`,
                );

                if (answer) {
                    const timestamp = Date.now();

                    const docRef = doc(
                        firestore,
                        "user",
                        auth.currentUser.uid,
                        "block",
                        props.document.authorUid,
                    );

                    await setDoc(docRef, {
                        uid: props.document.authorUid,
                        timestamp: timestamp,
                    });

                    navigate(0);
                }
            } else {
                alert("본인을 차단할 수는 없습니다.");
            }
        } else {
            navigate("/login");
        }
    };

    return (
        <div className="Bookmark-Block">
            <button onClick={onClick}>
                <img src={block}/>
                <p>차단하기</p>
            </button>
        </div>
    );
}

function Report(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const auth = getAuth();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            navigate("/report", {
                state: {
                    documentId: props.document.documentId,
                    commentId: "",
                    replyId: "",
                },
            });
        } else {
            navigate("/login");
        }
    };

    return (
        <div className="Bookmark-Report">
            <button onClick={onClick}>
                <img src={notify}/>
                <p>신고하기</p>
            </button>
        </div>
    );
}

function Delete(props: { document: DocumentState }) {
    const auth = getAuth();
    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    const onClick = async () => {
        const answer = window.confirm("정말 삭제하시겠습니까?");

        if (answer) {
            dispatch(coreActions.setIsLoading(true));

            await deleteDoc(doc(firestore, "metadata", props.document.documentId));
            await deleteDoc(
                doc(
                    firestore,
                    "user",
                    props.document.authorUid,
                    "post",
                    props.document.documentId,
                ),
            );

            for (const hashtag of props.document.hashtags) {
                if (hashtag !== "") {
                    await deleteDoc(
                        doc(
                            firestore,
                            "hashtag",
                            hashtag,
                            "post",
                            props.document.documentId,
                        ),
                    );
                }
            }

            await deleteDoc(doc(firestore, "board", props.document.documentId));

            dispatch(bookmarkActions.removeDocument(props.document));

            dispatch(coreActions.setIsLoading(false));
        }
    };

    return (
        <div className="Bookmark-Delete">
            <button onClick={onClick}>
                <img src={trash}/>
                <p>삭제하기</p>
            </button>
        </div>
    );
}

function ThumbnailContent(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions(),
    );

    const d: DocumentState = {
        documentId: props.document.documentId,
        authorUid: props.document.authorUid,
        authorAvatarUrl: props.document.authorAvatarUrl,
        authorNickname: props.document.authorNickname,
        timestamp: props.document.timestamp,
        content: props.document.content,
        thumbnailContent: [],
        thumbnailImageSizes: [],
        hashtags: props.document.hashtags,
        numUps: props.document.numUps,
        numDowns: props.document.numDowns,
        numComments: props.document.numComments,
        numTokens: props.document.numTokens,
        clickUp: props.document.clickUp,
        clickDown: props.document.clickDown,
        clickBookmark: props.document.clickBookmark,
    };

    const onClick = () => {
        navigate(`/read/${props.document.documentId}`, {
            state: {
                document: d,
            },
        });
        navigate(0);
    };

    function getWindowDimensions() {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height,
        };
    }

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className="Bookmark-ThumbnailContent">
            <button onClick={onClick}>
                {props.document.thumbnailContent.map((item, _) => {
                    if (item.props !== undefined && item.props.children !== undefined) {
                        if (item.props.children.type === "img") {
                            for (let imageSize of props.document.thumbnailImageSizes) {
                                if (imageSize.src === item.props.children.props.src) {
                                    if (
                                        0.9 * 0.44 * windowDimensions.width - 20 <
                                        imageSize.width
                                    ) {
                                        return (
                                            <img
                                                style={{
                                                    width: 0.85 * 0.44 * windowDimensions.width - 20,
                                                    maxHeight: imageSize.height,
                                                    objectFit: "cover",
                                                    objectPosition: "0% 0%",
                                                    backgroundColor: "transparent",
                                                }}
                                                src={item.props.children.props.src}
                                            />
                                        );
                                    } else {
                                        return (
                                            <img
                                                style={{
                                                    width: imageSize.width,
                                                    maxHeight: imageSize.height,
                                                    objectFit: "cover",
                                                    objectPosition: "0% 0%",
                                                    backgroundColor: "transparent",
                                                }}
                                                src={item.props.children.props.src}
                                            />
                                        );
                                    }
                                }
                            }

                            return item;
                        } else {
                            return item;
                        }
                    } else if (item.type === "img" && item.props !== undefined) {
                        for (let imageSize of props.document.thumbnailImageSizes) {
                            if (imageSize.src === item.props.src) {
                                if (
                                    0.9 * 0.44 * windowDimensions.width - 20 <
                                    imageSize.width
                                ) {
                                    return (
                                        <img
                                            style={{
                                                width: 0.85 * 0.44 * windowDimensions.width - 20,
                                                maxHeight: imageSize.height,
                                                objectFit: "cover",
                                                objectPosition: "0% 0%",
                                                backgroundColor: "transparent",
                                            }}
                                            src={item.props.src}
                                        />
                                    );
                                } else {
                                    return (
                                        <img
                                            style={{
                                                width: imageSize.width,
                                                maxHeight: imageSize.height,
                                                objectFit: "cover",
                                                objectPosition: "0% 0%",
                                                backgroundColor: "transparent",
                                            }}
                                            src={item.props.src}
                                        />
                                    );
                                }
                            }
                        }

                        return item;
                    } else {
                        return item;
                    }
                })}
            </button>
        </div>
    );
}

function Hashtags(props: { document: DocumentState }) {
    return (
        <div className="Bookmark-Hashtags">
            {
                <ul>
                    {props.document.hashtags.map((hashtag, index) => (
                        <li key={index}>
                            <Hashtag hashtag={hashtag}/>
                        </li>
                    ))}
                </ul>
            }
        </div>
    );
}

function Hashtag(props: { hashtag: string }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/search_query/${props.hashtag.slice(1)}`);
    };

    return (
        <div className="Bookmark-Hashtag">
            <img src={hashtag}/>
            <button className="Bookmark-Hashtag-tag" onClick={onClick}>
                {props.hashtag.slice(1)}
            </button>
        </div>
    );
}

function ReadMore(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const d: DocumentState = {
        documentId: props.document.documentId,
        authorUid: props.document.authorUid,
        authorAvatarUrl: props.document.authorAvatarUrl,
        authorNickname: props.document.authorNickname,
        timestamp: props.document.timestamp,
        content: props.document.content,
        thumbnailContent: [],
        thumbnailImageSizes: [],
        hashtags: props.document.hashtags,
        numUps: props.document.numUps,
        numDowns: props.document.numDowns,
        numComments: props.document.numComments,
        numTokens: props.document.numTokens,
        clickUp: props.document.clickUp,
        clickDown: props.document.clickDown,
        clickBookmark: props.document.clickBookmark,
    };

    const onClick = () => {
        navigate(`/read/${props.document.documentId}`, {
            state: {
                document: d,
            },
        });
        navigate(0);
    };

    return (
        <div className="Bookmark-ReadMore">
            <button onClick={onClick}>
                <img src={more}/>
            </button>
        </div>
    );
}

function Summary(props: { document: DocumentState }) {
    return (
        <div className="Bookmark-Summary">
            <NumUps document={props.document}/>
            <NumDowns document={props.document}/>
            <NumComments document={props.document}/>
            <NumTokens document={props.document}/>
        </div>
    );
}

function NumUps(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            const upDocRef = doc(
                firestore,
                "metadata",
                props.document.documentId,
                "up",
                auth.currentUser.uid,
            );

            if (props.document.clickUp) {
                dispatch(
                    bookmarkActions.unsetDocumentClickUp(props.document.documentId),
                );

                await deleteDoc(upDocRef);
            } else {
                dispatch(bookmarkActions.setDocumentClickUp(props.document.documentId));

                const upTimestamp = Date.now();

                await setDoc(upDocRef, {
                    uid: auth.currentUser!.uid,
                    timestamp: upTimestamp,
                });
            }
        } else {
            navigate("/login");
        }
    };

    if (auth.currentUser !== null) {
        if (props.document.clickUp) {
            return (
                <div className="Bookmark-NumUps-Focus">
                    <button onClick={onClick}>
                        <img src={upFocus}/>
                        <p>{props.document.numUps}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Bookmark-NumUps">
                    <button onClick={onClick}>
                        <img src={up}/>
                        <p>{props.document.numUps}</p>
                    </button>
                </div>
            );
        }
    } else {
        return (
            <div className="Bookmark-NumUps">
                <button onClick={onClick}>
                    <img src={up}/>
                    <p>{props.document.numUps}</p>
                </button>
            </div>
        );
    }
}

function NumDowns(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            const downDocRef = doc(
                firestore,
                "metadata",
                props.document.documentId,
                "down",
                auth.currentUser.uid,
            );

            if (props.document.clickDown) {
                dispatch(
                    bookmarkActions.unsetDocumentClickDown(props.document.documentId),
                );

                await deleteDoc(downDocRef);
            } else {
                dispatch(
                    bookmarkActions.setDocumentClickDown(props.document.documentId),
                );

                const downTimestamp = Date.now();

                await setDoc(downDocRef, {
                    uid: auth.currentUser!.uid,
                    timestamp: downTimestamp,
                });
            }
        } else {
            navigate("/login");
        }
    };

    if (auth.currentUser !== null) {
        if (props.document.clickDown) {
            return (
                <div className="Bookmark-NumDowns-Focus">
                    <button onClick={onClick}>
                        <img src={downFocus}/>
                        <p>{props.document.numDowns}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Bookmark-NumDowns">
                    <button onClick={onClick}>
                        <img src={down}/>
                        <p>{props.document.numDowns}</p>
                    </button>
                </div>
            );
        }
    } else {
        return (
            <div className="Bookmark-NumDowns">
                <button onClick={onClick}>
                    <img src={down}/>
                    <p>{props.document.numDowns}</p>
                </button>
            </div>
        );
    }
}

function NumComments(props: { document: DocumentState }) {
    const navigate = useNavigate();

    const d: DocumentState = {
        documentId: props.document.documentId,
        authorUid: props.document.authorUid,
        authorAvatarUrl: props.document.authorAvatarUrl,
        authorNickname: props.document.authorNickname,
        timestamp: props.document.timestamp,
        content: props.document.content,
        thumbnailContent: [],
        thumbnailImageSizes: [],
        hashtags: props.document.hashtags,
        numUps: props.document.numUps,
        numDowns: props.document.numDowns,
        numComments: props.document.numComments,
        numTokens: props.document.numTokens,
        clickUp: props.document.clickUp,
        clickDown: props.document.clickDown,
        clickBookmark: props.document.clickBookmark,
    };

    const onClick = () => {
        navigate(`/read/${props.document.documentId}`, {
            state: {
                document: d,
            },
        });
        navigate(0);
    };

    return (
        <div className="Bookmark-NumComments">
            <button onClick={onClick}>
                <img src={comment}/>
                <p>{props.document.numComments}</p>
            </button>
        </div>
    );
}

function NumTokens(props: { document: DocumentState }) {
    return (
        <div className="Bookmark-NumTokens">
            <button>
                <p>TOKEN</p>
                <ComingSoon/>
            </button>
        </div>
    );
}

function ComingSoon() {
    return (
        <div className="Bookmark-ComingSoon">
            <button>
                <img src={comingSoon}/>
            </button>
        </div>
    );
}
