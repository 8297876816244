import {reportActions} from "../../module/report";
import {coreActions} from "../../module/core";
import {useAppDispatch, useAppSelector} from "../../module/hook";
import {doc, getFirestore, setDoc} from "firebase/firestore";
import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import "./report.css";
import {Header} from "../header";
import {Sidebar} from "../sidebar";
import {getAuth} from "firebase/auth";
import {RightSidebar} from "../right_sidebar";
import uuid from "react-uuid";
import {Loading} from "../loading/loading";

export function Report() {
    const navigate = useNavigate();
    const location = useLocation();

    const auth = getAuth();

    const isLoading = useAppSelector((state) => state.core.isLoading);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (auth.currentUser === null) {
            navigate("/login");
        } else {
            dispatch(coreActions.setFocus("report"));

            dispatch(reportActions.setDocumentId(location.state.documentId));
            dispatch(reportActions.setCommentId(location.state.commentId));
            dispatch(reportActions.setReplyId(location.state.replyId));
            dispatch(reportActions.setDislikeChecked(false));
            dispatch(reportActions.setHarmfulChecked(false));
            dispatch(reportActions.setAdChecked(false));
            dispatch(reportActions.setPornChecked(false));
            dispatch(reportActions.setEtcChecked(false));
            dispatch(reportActions.setEtcContent(""));
        }
    }, []);

    return <div className="Report">{isLoading ? <Loading/> : <Body/>}</div>;
}

function Body() {
    return (
        <div className="Report-Body">
            <Header/>
            <Sidebar/>
            <Title/>
            <Main/>
            <RightSidebar/>
        </div>
    );
}

function Title() {
    return (
        <div className="Report-Title">
            <h1>신고하기</h1>
        </div>
    );
}

function Main() {
    return (
        <div className="Report-Main">
            <DislikeChecked/>
            <HarmfulChecked/>
            <AdChecked/>
            <PornChecked/>
            <EtcChecked/>
            <EtcInputBody/>
            <Continue/>
        </div>
    );
}

function DislikeChecked() {
    const dislikeChecked = useAppSelector((state) => state.report.dislikeChecked);

    const dispatch = useAppDispatch();

    const onChange = (_: any) => {
        if (dislikeChecked) {
            dispatch(reportActions.setDislikeChecked(false));
        } else {
            dispatch(reportActions.setDislikeChecked(true));
        }
    };

    return (
        <div className="Report-DislikeChecked">
            <input type="checkbox" checked={dislikeChecked} onChange={onChange}/>
            <p>마음에 들지 않습니다</p>
        </div>
    );
}

function HarmfulChecked() {
    const harmfulChecked = useAppSelector((state) => state.report.harmfulChecked);

    const dispatch = useAppDispatch();

    const onChange = (_: any) => {
        if (harmfulChecked) {
            dispatch(reportActions.setHarmfulChecked(false));
        } else {
            dispatch(reportActions.setHarmfulChecked(true));
        }
    };

    return (
        <div className="Report-HarmfulChecked">
            <input type="checkbox" checked={harmfulChecked} onChange={onChange}/>
            <p>폭력, 혐오, 차별 발언</p>
        </div>
    );
}

function AdChecked() {
    const adChecked = useAppSelector((state) => state.report.adChecked);

    const dispatch = useAppDispatch();

    const onChange = (_: any) => {
        if (adChecked) {
            dispatch(reportActions.setAdChecked(false));
        } else {
            dispatch(reportActions.setAdChecked(true));
        }
    };

    return (
        <div className="Report-AdChecked">
            <input type="checkbox" checked={adChecked} onChange={onChange}/>
            <p>광고, 홍보</p>
        </div>
    );
}

function PornChecked() {
    const pornChecked = useAppSelector((state) => state.report.pornChecked);

    const dispatch = useAppDispatch();

    const onChange = (_: any) => {
        if (pornChecked) {
            dispatch(reportActions.setPornChecked(false));
        } else {
            dispatch(reportActions.setPornChecked(true));
        }
    };

    return (
        <div className="Report-PornChecked">
            <input type="checkbox" checked={pornChecked} onChange={onChange}/>
            <p>포르노, 선정성</p>
        </div>
    );
}

function EtcChecked() {
    const etcChecked = useAppSelector((state) => state.report.etcChecked);

    const dispatch = useAppDispatch();

    const onChange = (_: any) => {
        if (etcChecked) {
            dispatch(reportActions.setEtcChecked(false));
        } else {
            dispatch(reportActions.setEtcChecked(true));
        }
    };

    return (
        <div className="Report-EtcChecked">
            <input type="checkbox" checked={etcChecked} onChange={onChange}/>
            <p>기타</p>
        </div>
    );
}

function EtcInputBody() {
    const etcChecked = useAppSelector((state) => state.report.etcChecked);
    const etcContent = useAppSelector((state) => state.report.etcContent);

    const dispatch = useAppDispatch();

    const onChange = (e: any) => {
        dispatch(reportActions.setEtcContent(e.target.value));
    };

    return (
        <div className="Report-EtcInputBody">
            <form>
        <textarea
            placeholder={"신고 내용을 설명해주세요."}
            wrap="soft"
            value={etcContent}
            onChange={onChange}
        />
            </form>
        </div>
    );
}

function Continue() {
    const documentId = useAppSelector((state) => state.report.documentId);
    const commentId = useAppSelector((state) => state.report.commentId);
    const replyId = useAppSelector((state) => state.report.replyId);

    const dislikeChecked = useAppSelector((state) => state.report.dislikeChecked);
    const harmfulChecked = useAppSelector((state) => state.report.harmfulChecked);
    const adChecked = useAppSelector((state) => state.report.adChecked);
    const pornChecked = useAppSelector((state) => state.report.pornChecked);
    const etcChecked = useAppSelector((state) => state.report.etcChecked);
    const etcContent = useAppSelector((state) => state.report.etcContent);

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (
            dislikeChecked ||
            harmfulChecked ||
            adChecked ||
            pornChecked ||
            etcChecked
        ) {
            dispatch(coreActions.setIsLoading(true));

            const timestamp = Date.now();

            const id = uuid();

            const reportId = timestamp.toString() + "-" + id;

            await setDoc(doc(firestore, "report", reportId), {
                reportId: reportId,
                documentId: documentId,
                commentId: commentId,
                replyId: replyId,
                authorUid: auth.currentUser!.uid,
                timestamp: timestamp,
                dislikeChecked: dislikeChecked,
                harmfulChecked: harmfulChecked,
                adChecked: adChecked,
                pornChecked: pornChecked,
                etcChecked: etcChecked,
                etcContent: etcContent,
            });

            dispatch(coreActions.setIsLoading(false));

            alert("신고가 접수되었습니다.");
            navigate("/");
        } else {
            alert("신고 항목을 적어도 1개 이상 선택해주셔야 합니다.");
        }
    };

    return (
        <div className="Report-Continue">
            <button onClick={onClick}>제출하기</button>
        </div>
    );
}
