import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UserState} from "./user";

export interface RankingState {
    tab: string;
    docRankingUsers: UserState[];
    commentRankingUsers: UserState[];
    totalRankingUsers: UserState[];
    docFetching: number;
    docFetched: number;
    commentFetching: number;
    commentFetched: number;
    totalFetching: number;
    totalFetched: number;
}

const initialState: RankingState = {
    tab: "total",
    docRankingUsers: [],
    commentRankingUsers: [],
    totalRankingUsers: [],
    docFetching: 0,
    docFetched: 0,
    commentFetching: 0,
    commentFetched: 0,
    totalFetching: 0,
    totalFetched: 0,
};

export const ranking = createSlice({
    name: "ranking",
    initialState,
    reducers: {
        setTab: (state, action: PayloadAction<string>) => {
            state.tab = action.payload;
        },

        resetDocRankingUsers(state) {
            state.docRankingUsers = [];
        },

        appendDocRankingUser(state, action: PayloadAction<UserState>) {
            const isEqualId = (user: UserState) => user.uid === action.payload.uid;

            if (state.docRankingUsers.findIndex(isEqualId) >= 0) {
                return;
            } else {
                state.docRankingUsers = [...state.docRankingUsers, action.payload].sort(
                    (a, b) => (a.docPoint < b.docPoint ? 1 : -1),
                );
            }
        },

        resetCommentRankingUsers(state) {
            state.commentRankingUsers = [];
        },

        appendCommentRankingUser(state, action: PayloadAction<UserState>) {
            const isEqualId = (user: UserState) => user.uid === action.payload.uid;

            if (state.commentRankingUsers.findIndex(isEqualId) >= 0) {
                return;
            } else {
                state.commentRankingUsers = [
                    ...state.commentRankingUsers,
                    action.payload,
                ].sort((a, b) => (a.commentPoint < b.commentPoint ? 1 : -1));
            }
        },

        resetTotalRankingUsers(state) {
            state.totalRankingUsers = [];
        },

        appendTotalRankingUser(state, action: PayloadAction<UserState>) {
            const isEqualId = (user: UserState) => user.uid === action.payload.uid;

            if (state.totalRankingUsers.findIndex(isEqualId) >= 0) {
                return;
            } else {
                state.totalRankingUsers = [
                    ...state.totalRankingUsers,
                    action.payload,
                ].sort((a, b) => (a.totalPoint < b.totalPoint ? 1 : -1));
            }
        },

        resetDocFetching(state) {
            state.docFetching = 0;
        },

        incrementDocFetching(state, action: PayloadAction<number>) {
            state.docFetching += action.payload;
        },

        resetDocFetched(state) {
            state.docFetched = 0;
        },

        incrementDocFetched(state, action: PayloadAction<number>) {
            state.docFetched += action.payload;
        },

        resetCommentFetching(state) {
            state.commentFetching = 0;
        },

        incrementCommentFetching(state, action: PayloadAction<number>) {
            state.commentFetching += action.payload;
        },

        resetCommentFetched(state) {
            state.commentFetched = 0;
        },

        incrementCommentFetched(state, action: PayloadAction<number>) {
            state.commentFetched += action.payload;
        },

        resetTotalFetching(state) {
            state.totalFetching = 0;
        },

        incrementTotalFetching(state, action: PayloadAction<number>) {
            state.totalFetching += action.payload;
        },

        resetTotalFetched(state) {
            state.totalFetched = 0;
        },

        incrementTotalFetched(state, action: PayloadAction<number>) {
            state.totalFetched += action.payload;
        },
    },
});

export const rankingActions = ranking.actions;
export default ranking.reducer;
