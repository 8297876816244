import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface NoticeWriteState {
    isImageLoading: boolean;
    content: string;
}

const initialState: NoticeWriteState = {
    isImageLoading: false,
    content: "",
};

export const noticeWrite = createSlice({
    name: "noticeWrite",
    initialState,
    reducers: {
        setIsImageLoading(state, action: PayloadAction<boolean>) {
            state.isImageLoading = action.payload;
        },

        setContent(state, action: PayloadAction<string>) {
            state.content = action.payload;
        },
    },
});

export const noticeWriteActions = noticeWrite.actions;
export default noticeWrite.reducer;
