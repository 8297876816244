import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../module/hook";
import {coreActions} from "../module/core";
import underscore from "../asset/image/underscore.png";
import React from "react";
import "./mobile_tab.css";

export function MobileTab() {
    return (
        <div className="MobileTab">
            <Recommendation/>
            <Following/>
            <Bookmark/>
            <Profile/>
        </div>
    );
}

function Recommendation() {
    const navigate = useNavigate();

    const focus = useAppSelector((state) => state.core.focus);

    const dispatch = useAppDispatch();

    const onClick = () => {
        dispatch(coreActions.setFocus("recommendation"));

        navigate("/");
    };

    if (focus === "recommendation") {
        return (
            <div className="MobileTab-Recommendation-Focus">
                <button onClick={onClick}>추천</button>
                <img src={underscore}/>
            </div>
        );
    } else {
        return (
            <div className="MobileTab-Recommendation">
                <button onClick={onClick}>추천</button>
            </div>
        );
    }
}

function Following() {
    const navigate = useNavigate();

    const focus = useAppSelector((state) => state.core.focus);

    const dispatch = useAppDispatch();

    const onClick = () => {
        dispatch(coreActions.setFocus("following"));

        navigate("/following");
    };

    if (focus === "following") {
        return (
            <div className="MobileTab-Following-Focus">
                <button onClick={onClick}>팔로잉</button>
                <img src={underscore}/>
            </div>
        );
    } else {
        return (
            <div className="MobileTab-Following">
                <button onClick={onClick}>팔로잉</button>
            </div>
        );
    }
}

function Bookmark() {
    const navigate = useNavigate();

    const focus = useAppSelector((state) => state.core.focus);

    const dispatch = useAppDispatch();

    const onClick = () => {
        dispatch(coreActions.setFocus("bookmark"));

        navigate("/bookmark");
    };

    if (focus === "bookmark") {
        return (
            <div className="MobileTab-Bookmark-Focus">
                <button onClick={onClick}>북마크</button>
                <img src={underscore}/>
            </div>
        );
    } else {
        return (
            <div className="MobileTab-Bookmark">
                <button onClick={onClick}>북마크</button>
            </div>
        );
    }
}

function Profile() {
    const navigate = useNavigate();

    const focus = useAppSelector((state) => state.core.focus);

    const dispatch = useAppDispatch();

    const onClick = () => {
        dispatch(coreActions.setFocus("profile"));

        navigate("/profile");
    };

    if (focus === "profile") {
        return (
            <div className="MobileTab-Profile-Focus">
                <button onClick={onClick}>프로필</button>
                <img src={underscore}/>
            </div>
        );
    } else {
        return (
            <div className="MobileTab-Profile">
                <button onClick={onClick}>프로필</button>
            </div>
        );
    }
}
