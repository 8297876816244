import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface SignUpState {
    email: string;
    password: string;
    name: string;
    nickname: string;
}

const initialState: SignUpState = {
    email: "",
    password: "",
    name: "",
    nickname: "",
};

export const signUp = createSlice({
    name: "signUp",
    initialState,
    reducers: {
        setEmail(state, action: PayloadAction<string>) {
            state.email = action.payload;
        },

        setPassword(state, action: PayloadAction<string>) {
            state.password = action.payload;
        },

        setName(state, action: PayloadAction<string>) {
            state.name = action.payload;
        },

        setNickname(state, action: PayloadAction<string>) {
            state.nickname = action.payload;
        },
    },
});

export const signUpActions = signUp.actions;
export default signUp.reducer;
