import "./eula.css";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../module/hook";
import {eulaActions} from "../../module/eula";
import {Header} from "../header";
import logo from "../../asset/image/logo.png";
import logoTransparent from "../../asset/image/logo_transparent.png";

export function Eula() {
    return (
        <div className="Eula">
            <Body/>
        </div>
    );
}

function Body() {
    return (
        <div className={"Eula-Body"}>
            <Header/>
            <Title/>
            <Background/>
            <EulaChecked/>
            <AgeChecked/>
            <Continue/>
        </div>
    );
}

function Title() {
    return (
        <div className="Eula-Title">
            <img src={logo}/>
            <h1>서비스 약관 동의</h1>
            <p>
                환영합니다. Giggles 입니다. 이용약관 및 개인정보처리방침에 동의하시면
                서비스 이용이 가능합니다.
            </p>
        </div>
    );
}

function Background() {
    return (
        <div className="Eula-Background">
            <img src={logoTransparent}/>
        </div>
    );
}

function EulaChecked() {
    const eulaChecked = useAppSelector((state) => state.eula.eulaChecked);

    const dispatch = useAppDispatch();

    const onChange = (_: any) => {
        if (eulaChecked === false) {
            dispatch(eulaActions.setEulaChecked(true));
        } else {
            dispatch(eulaActions.setEulaChecked(false));
        }
    };

    const openInNewTab = (url: any) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");

        if (newWindow === null) {
            newWindow!.opener = null;
        }
    };

    return (
        <div className="Eula-EulaChecked">
            <input type="checkbox" checked={eulaChecked} onChange={onChange}/>
            <p>[필수] 서비스 이용약관 동의</p>
            <button
                onClick={() =>
                    openInNewTab("https://sites.google.com/view/giggles-expert")
                }
            >
                보기
            </button>
        </div>
    );
}

function AgeChecked() {
    const ageChecked = useAppSelector((state) => state.eula.ageChecked);

    const dispatch = useAppDispatch();

    const onChange = (_: any) => {
        if (ageChecked === false) {
            dispatch(eulaActions.setAgeChecked(true));
        } else {
            dispatch(eulaActions.setAgeChecked(false));
        }
    };

    const openInNewTab = (url: any) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");

        if (newWindow === null) {
            newWindow!.opener = null;
        }
    };

    return (
        <div className="Eula-AgeChecked">
            <input type="checkbox" checked={ageChecked} onChange={onChange}/>
            <p>[필수] 개인정보처리방침 동의</p>
            <button
                onClick={() =>
                    openInNewTab("https://sites.google.com/view/giggles-expert")
                }
            >
                보기
            </button>
        </div>
    );
}

function Continue() {
    const eulaChecked = useAppSelector((state) => state.eula.eulaChecked);
    const ageChecked = useAppSelector((state) => state.eula.ageChecked);

    const navigate = useNavigate();

    const onClick = () => {
        if (eulaChecked && ageChecked) {
            navigate("/sign_up");
        } else {
            alert("필수 약관에 모두 동의하셔야 서비스 이용이 가능합니다.");
        }
    };

    return (
        <div className="Eula-Continue">
            <button onClick={onClick}>계속하기</button>
        </div>
    );
}
