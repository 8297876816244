import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../module/hook";
import {coreActions} from "../module/core";
import logo from "../asset/image/logo.png";
import {adminActions} from "../module/admin";
import underscore from "../asset/image/underscore.png";
import React from "react";
import "./admin_header.css";

export function AdminHeader() {
    return (
        <div className="AdminHeader">
            <Logo/>
            <Navigation/>
        </div>
    );
}

function Logo() {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const onClick = () => {
        dispatch(coreActions.setFocus("recommendation"));

        navigate("/");
    };

    return (
        <div className="AdminHeader-Logo">
            <img src={logo} onClick={onClick}/>
        </div>
    );
}

function Navigation() {
    return (
        <div className="AdminHeader-Navigation">
            <DocumentsTab/>
            <CommentsTab/>
            <RepliesTab/>
            <SearchTab/>
        </div>
    );
}

function DocumentsTab() {
    const navigate = useNavigate();
    const focus = useAppSelector((state) => state.admin.focus);

    const dispatch = useAppDispatch();

    const onClick = () => {
        navigate("/admin");
        dispatch(adminActions.setFocus("documents"));
    };

    if (focus === "documents") {
        return (
            <div className="AdminHeader-DocumentsTab-Focus">
                <button onClick={onClick}>게시글</button>
                <img src={underscore}/>
            </div>
        );
    } else {
        return (
            <div className="AdminHeader-DocumentsTab">
                <button onClick={onClick}>게시글</button>
            </div>
        );
    }
}

function CommentsTab() {
    const navigate = useNavigate();
    const focus = useAppSelector((state) => state.admin.focus);

    const dispatch = useAppDispatch();

    const onClick = () => {
        navigate("/admin");
        dispatch(adminActions.setFocus("comments"));
    };

    if (focus === "comments") {
        return (
            <div className="AdminHeader-CommentsTab-Focus">
                <button onClick={onClick}>댓글</button>
                <img src={underscore}/>
            </div>
        );
    } else {
        return (
            <div className="AdminHeader-CommentsTab">
                <button onClick={onClick}>댓글</button>
            </div>
        );
    }
}

function RepliesTab() {
    const navigate = useNavigate();
    const focus = useAppSelector((state) => state.admin.focus);

    const dispatch = useAppDispatch();

    const onClick = () => {
        navigate("/admin");
        dispatch(adminActions.setFocus("replies"));
    };

    if (focus === "replies") {
        return (
            <div className="AdminHeader-RepliesTab-Focus">
                <button onClick={onClick}>답글</button>
                <img src={underscore}/>
            </div>
        );
    } else {
        return (
            <div className="AdminHeader-RepliesTab">
                <button onClick={onClick}>답글</button>
            </div>
        );
    }
}

function SearchTab() {
    const navigate = useNavigate();
    const focus = useAppSelector((state) => state.admin.focus);

    const dispatch = useAppDispatch();

    const onClick = () => {
        navigate("/admin");
        dispatch(adminActions.setFocus("search"));
    };

    if (focus === "search") {
        return (
            <div className="AdminHeader-SearchTab-Focus">
                <button onClick={onClick}>검색</button>
                <img src={underscore}/>
            </div>
        );
    } else {
        return (
            <div className="AdminHeader-SearchTab">
                <button onClick={onClick}>검색</button>
            </div>
        );
    }
}
