import "./right_sidebar.css";
import gToken from "../asset/image/g_token.png";
import gRanking from "../asset/image/g_ranking.png";
import comingSoon from "../asset/image/coming_soon.png";
import React, {useEffect} from "react";
import {rightSidebarActions} from "../module/right_sidebar";
import {getAuth} from "firebase/auth";
import {collection, doc, getDoc, getFirestore, limit, onSnapshot, orderBy, query,} from "firebase/firestore";
import {useAppDispatch, useAppSelector} from "../module/hook";

export function RightSidebar() {
    const auth = getAuth();
    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (auth.currentUser === null) {
            dispatch(rightSidebarActions.setDocPoint(0));
            dispatch(rightSidebarActions.setDocRanking(0));
            dispatch(rightSidebarActions.setCommentPoint(0));
            dispatch(rightSidebarActions.setCommentRanking(0));
            dispatch(rightSidebarActions.setCommentPoint(0));
            dispatch(rightSidebarActions.setCommentRanking(0));
        } else {
            const rankingQuery = query(
                collection(firestore, "ranking"),
                orderBy("timestamp", "desc"),
                limit(1),
            );

            onSnapshot(rankingQuery, async (snapshot) => {
                const newDocuments = snapshot.docs.map((elem) => elem.data());

                for (const elem of newDocuments) {
                    const docRankingDocRef = doc(
                        firestore,
                        "ranking",
                        elem.timestamp.toString(),
                        "docPoint",
                        auth.currentUser!.uid,
                    );
                    const commentRankingDocRef = doc(
                        firestore,
                        "ranking",
                        elem.timestamp.toString(),
                        "commentPoint",
                        auth.currentUser!.uid,
                    );
                    const totalRankingDocRef = doc(
                        firestore,
                        "ranking",
                        elem.timestamp.toString(),
                        "totalPoint",
                        auth.currentUser!.uid,
                    );

                    getDoc(docRankingDocRef).then(async (docSnap) => {
                        if (docSnap.exists()) {
                            if (docSnap.data().hasOwnProperty("docPoint")) {
                                dispatch(
                                    rightSidebarActions.setDocPoint(docSnap.data()["docPoint"]),
                                );
                            } else {
                                dispatch(rightSidebarActions.setDocPoint(0));
                            }

                            if (docSnap.data().hasOwnProperty("docRanking")) {
                                dispatch(
                                    rightSidebarActions.setDocRanking(
                                        docSnap.data()["docRanking"],
                                    ),
                                );
                            } else {
                                dispatch(rightSidebarActions.setDocRanking(0));
                            }
                        } else {
                            dispatch(rightSidebarActions.setDocPoint(0));
                            dispatch(rightSidebarActions.setDocRanking(0));
                        }
                    });

                    getDoc(commentRankingDocRef).then(async (docSnap) => {
                        if (docSnap.exists()) {
                            if (docSnap.data().hasOwnProperty("commentPoint")) {
                                dispatch(
                                    rightSidebarActions.setCommentPoint(
                                        docSnap.data()["commentPoint"],
                                    ),
                                );
                            } else {
                                dispatch(rightSidebarActions.setCommentPoint(0));
                            }

                            if (docSnap.data().hasOwnProperty("commentRanking")) {
                                dispatch(
                                    rightSidebarActions.setCommentRanking(
                                        docSnap.data()["commentRanking"],
                                    ),
                                );
                            } else {
                                dispatch(rightSidebarActions.setCommentRanking(0));
                            }
                        } else {
                            dispatch(rightSidebarActions.setCommentPoint(0));
                            dispatch(rightSidebarActions.setCommentRanking(0));
                        }
                    });

                    getDoc(totalRankingDocRef).then(async (docSnap) => {
                        if (docSnap.exists()) {
                            if (docSnap.data().hasOwnProperty("totalPoint")) {
                                dispatch(
                                    rightSidebarActions.setTotalPoint(
                                        docSnap.data()["totalPoint"],
                                    ),
                                );
                            } else {
                                dispatch(rightSidebarActions.setTotalPoint(0));
                            }

                            if (docSnap.data().hasOwnProperty("totalRanking")) {
                                dispatch(
                                    rightSidebarActions.setTotalRanking(
                                        docSnap.data()["totalRanking"],
                                    ),
                                );
                            } else {
                                dispatch(rightSidebarActions.setTotalRanking(0));
                            }
                        } else {
                            dispatch(rightSidebarActions.setTotalPoint(0));
                            dispatch(rightSidebarActions.setTotalRanking(0));
                        }
                    });
                }
            });
        }
    }, [auth.currentUser]);

    return (
        <div className="RightSidebar">
            <RankingAndToken/>
        </div>
    );
}

function RankingAndToken() {
    return (
        <div className="RightSidebar-RankingAndToken">
            <Ranking/>
            <Token/>
        </div>
    );
}

function Ranking() {
    return (
        <div className="RightSidebar-Ranking">
            <GRanking/>
            <TotalRanking/>
            <DocRanking/>
            <CommentRanking/>
        </div>
    );
}

function GRanking() {
    return (
        <div className="RightSidebar-GRanking">
            <img src={gRanking}/>
        </div>
    );
}

function DocRanking() {
    const docPoint = useAppSelector((state) => state.rightSidebar.docPoint);
    const docRanking = useAppSelector((state) => state.rightSidebar.docRanking);

    if (docRanking !== 0) {
        return (
            <div className="RightSidebar-DocRanking">
                <h1>글</h1>
                <p>
                    {docRanking}위 ({docPoint} P)
                </p>
            </div>
        );
    } else {
        return (
            <div className="RightSidebar-DocRanking">
                <h1>글</h1>
                <p>-</p>
            </div>
        );
    }
}

function CommentRanking() {
    const commentPoint = useAppSelector(
        (state) => state.rightSidebar.commentPoint,
    );
    const commentRanking = useAppSelector(
        (state) => state.rightSidebar.commentRanking,
    );

    if (commentRanking !== 0) {
        return (
            <div className="RightSidebar-CommentRanking">
                <h1>댓글</h1>
                <p>
                    {commentRanking}위 ({commentPoint} P)
                </p>
            </div>
        );
    } else {
        return (
            <div className="RightSidebar-CommentRanking">
                <h1>댓글</h1>
                <p>-</p>
            </div>
        );
    }
}

function TotalRanking() {
    const totalPoint = useAppSelector((state) => state.rightSidebar.totalPoint);
    const totalRanking = useAppSelector(
        (state) => state.rightSidebar.totalRanking,
    );

    if (totalRanking !== 0) {
        return (
            <div className="RightSidebar-TotalRanking">
                <h1>전체</h1>
                <p>
                    {totalRanking}위 ({totalPoint} P)
                </p>
            </div>
        );
    } else {
        return (
            <div className="RightSidebar-TotalRanking">
                <h1>전체</h1>
                <p>-</p>
            </div>
        );
    }
}

function Token() {
    return (
        <div className="RightSidebar-Token">
            <GToken/>
            <BigComingSoon/>
        </div>
    );
}

function GToken() {
    return (
        <div className="RightSidebar-GToken">
            <img src={gToken}/>
        </div>
    );
}

function BigComingSoon() {
    return (
        <div className="RightSidebar-BigComingSoon">
            <img src={comingSoon}/>
        </div>
    );
}
