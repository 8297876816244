import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface ReportState {
    documentId: string;
    commentId: string;
    replyId: string;
    dislikeChecked: boolean;
    harmfulChecked: boolean;
    adChecked: boolean;
    pornChecked: boolean;
    etcChecked: boolean;
    etcContent: string;
}

const initialState: ReportState = {
    documentId: "",
    commentId: "",
    replyId: "",
    dislikeChecked: false,
    harmfulChecked: false,
    adChecked: false,
    pornChecked: false,
    etcChecked: false,
    etcContent: "",
};

export const report = createSlice({
    name: "report",
    initialState,
    reducers: {
        setDocumentId(state, action: PayloadAction<string>) {
            state.documentId = action.payload;
        },

        setCommentId(state, action: PayloadAction<string>) {
            state.commentId = action.payload;
        },

        setReplyId(state, action: PayloadAction<string>) {
            state.replyId = action.payload;
        },

        setDislikeChecked(state, action: PayloadAction<boolean>) {
            state.dislikeChecked = action.payload;
        },

        setHarmfulChecked(state, action: PayloadAction<boolean>) {
            state.harmfulChecked = action.payload;
        },

        setAdChecked(state, action: PayloadAction<boolean>) {
            state.adChecked = action.payload;
        },

        setPornChecked(state, action: PayloadAction<boolean>) {
            state.pornChecked = action.payload;
        },

        setEtcChecked(state, action: PayloadAction<boolean>) {
            state.etcChecked = action.payload;
        },

        setEtcContent(state, action: PayloadAction<string>) {
            state.etcContent = action.payload;
        },
    },
});

export const reportActions = report.actions;
export default report.reducer;
