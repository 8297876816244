import {useAppSelector} from "../../module/hook";
import {Loading} from "../loading/loading";
import {getFunctions, httpsCallable} from "firebase/functions";
import {getAuth, signInWithCustomToken} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export function NaverLogin() {
    const isLoading = useAppSelector((state) => state.core.isLoading);

    return <div className="NaverLogin">{isLoading ? <Loading/> : <Body/>}</div>;
}

function Body() {
    const params = new URLSearchParams(window.location.search);

    const code = params.get("code");

    const auth = getAuth();
    const navigate = useNavigate();

    const getToken = async () => {
        try {
            const getFirebaseTokenByNaverCode = httpsCallable(
                getFunctions(),
                "get_firebase_token_by_naver_code",
            );

            console.log(getFirebaseTokenByNaverCode);

            const {data} = await getFirebaseTokenByNaverCode({code: code});

            console.log(data);

            const {firebaseToken} = data as { firebaseToken: string };
            await signInWithCustomToken(auth, firebaseToken);

            navigate("/easy_sign_up");
        } catch (error) {
        }
    };

    useEffect(() => {
        getToken();
    }, []);

    return (
        <div className="NaverLogin-Body">
            <p>NaverLogin-Body</p>
        </div>
    );
}
