import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface EulaState {
    eulaChecked: boolean;
    ageChecked: boolean;
}

const initialState: EulaState = {
    eulaChecked: false,
    ageChecked: false,
};

export const eula = createSlice({
    name: "eula",
    initialState,
    reducers: {
        setEulaChecked(state, action: PayloadAction<boolean>) {
            state.eulaChecked = action.payload;
        },

        setAgeChecked(state, action: PayloadAction<boolean>) {
            state.ageChecked = action.payload;
        },
    },
});

export const eulaActions = eula.actions;
export default eula.reducer;
