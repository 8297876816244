import {ReplyState} from "../../module/reply";
import {notificationViewActions} from "../../module/notification_view";
import {coreActions} from "../../module/core";
import {useAppDispatch, useAppSelector} from "../../module/hook";
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getFirestore,
    limit,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    startAfter,
} from "firebase/firestore";
import {useLocation, useNavigate} from "react-router-dom";
import defaultAvatar from "../../asset/image/default_avatar.png";
import React, {useEffect, useState} from "react";
import "./notification_view.css";
import {Header} from "../header";
import {Sidebar} from "../sidebar";
import moment from "moment";
import {getAuth} from "firebase/auth";
import threeDots from "../../asset/image/three_dots.png";
import comment from "../../asset/image/comment.png";
import bookmark from "../../asset/image/bookmark.png";
import bookmarkFocus from "../../asset/image/bookmark_focus.png";
import hashtag from "../../asset/image/hashtag.png";
import {RightSidebar} from "../right_sidebar";
import reply from "../../asset/image/reply.png";
import uuid from "react-uuid";
import {Loading} from "../loading/loading";
import parse from "html-react-parser";
import {MessageState} from "../../module/message";
import upFocus from "../../asset/image/up_focus.png";
import up from "../../asset/image/up.png";
import down from "../../asset/image/down.png";
import downFocus from "../../asset/image/down_focus.png";
import more from "../../asset/image/more.png";
import comingSoon from "../../asset/image/coming_soon.png";
import {DocumentState} from "../../module/document";
import LinearProgress from "@mui/material/LinearProgress";
import block from "../../asset/image/block.png";
import notify from "../../asset/image/notify.png";
import trash from "../../asset/image/trash.png";

export function NotificationView() {
    const location = useLocation();

    const auth = getAuth();
    const firestore = getFirestore();

    const isLoading = useAppSelector((state) => state.core.isLoading);

    const dispatch = useAppDispatch();

    const cleanUp = async (message: MessageState) => {
        if (auth.currentUser !== null) {
            if (message.messageType === "comment") {
                const notificationRef = doc(
                    firestore,
                    "user",
                    auth.currentUser!.uid,
                    "notification",
                    message.commentId,
                );

                await deleteDoc(notificationRef);
            } else {
                const notificationRef = doc(
                    firestore,
                    "user",
                    auth.currentUser!.uid,
                    "notification",
                    message.replyId,
                );

                await deleteDoc(notificationRef);
            }
        }
    };

    useEffect(() => {
        dispatch(coreActions.setFocus("notification"));

        dispatch(coreActions.setIsLoading(true));

        dispatch(notificationViewActions.resetMessage());
        dispatch(notificationViewActions.resetReplies());

        dispatch(notificationViewActions.setMessage(location.state.message));

        cleanUp(location.state.message);

        dispatch(coreActions.setIsLoading(false));
    }, []);

    return (
        <div className="NotificationView">{isLoading ? <Loading/> : <Body/>}</div>
    );
}

function Body() {
    return (
        <div className="NotificationView-Body">
            <Header/>
            <Sidebar/>
            <Title/>
            <Message/>
            <RightSidebar/>
        </div>
    );
}

function Title() {
    return (
        <div className="NotificationView-Title">
            <h1>알림 보기</h1>
        </div>
    );
}

function Message() {
    const message = useAppSelector((state) => state.notificationView.message);

    if (message.documentId !== "") {
        return (
            <div className="NotificationView-Message">
                <Document/>
                <Comment/>
            </div>
        );
    } else {
        return (
            <div className="NotificationView-Message-Void">
                <p>존재하지 않는 게시물입니다.</p>
            </div>
        );
    }
}

function Document() {
    const message = useAppSelector((state) => state.notificationView.message);

    if (
        message.documentHashtags.length > 0 &&
        message.documentHashtags[0] !== ""
    ) {
        return (
            <div className="NotificationView-Document">
                <DocumentHeader/>
                <Content/>
                <Hashtags/>
                <ReadMore/>
                <Summary/>
            </div>
        );
    } else {
        return (
            <div className="NotificationView-Document">
                <DocumentHeader/>
                <Content/>
                <ReadMore/>
                <Summary/>
            </div>
        );
    }
}

function DocumentHeader() {
    return (
        <div className="NotificationView-DocumentHeader">
            <Avatar/>
            <Nickname/>
            <Time/>
            <Bookmark/>
            <Misc/>
        </div>
    );
}

function Avatar() {
    const message = useAppSelector((state) => state.notificationView.message);

    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/profile_view/${message.documentAuthorUid}`);
    };

    if (message.documentAuthorAvatarUrl !== "") {
        return (
            <div className="NotificationView-Avatar">
                <img src={message.documentAuthorAvatarUrl} onClick={onClick}/>
            </div>
        );
    } else {
        return (
            <div className="NotificationView-Avatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        );
    }
}

function Nickname() {
    const navigate = useNavigate();

    const message = useAppSelector((state) => state.notificationView.message);

    const onClick = () => {
        navigate(`/profile_view/${message.documentAuthorUid}`);
    };

    if (message.documentAuthorNickname !== "") {
        return (
            <div className="NotificationView-Nickname">
                <button onClick={onClick}>{message.documentAuthorNickname}</button>
            </div>
        );
    } else {
        return (
            <div className="NotificationView-Nickname">
                <button onClick={onClick}>닉네임 없음</button>
            </div>
        );
    }
}

function Time() {
    const message = useAppSelector((state) => state.notificationView.message);

    const endTimestamp = Date.now();

    const diff = endTimestamp - message.documentTimestamp;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 1) {
        return (
            <div className="NotificationView-Time">
                <p>방금 전</p>
            </div>
        );
    } else if (hours < 1) {
        return (
            <div className="NotificationView-Time">
                <p>{minutes} 분 전</p>
            </div>
        );
    } else if (days < 1) {
        return (
            <div className="NotificationView-Time">
                <p>{hours} 시간 전</p>
            </div>
        );
    } else {
        const date = moment(message.documentTimestamp);

        return (
            <div className="NotificationView-Time">
                <p>{date.format("YYYY-MM-DD")}</p>
            </div>
        );
    }
}

function Bookmark() {
    const navigate = useNavigate();

    const message = useAppSelector((state) => state.notificationView.message);

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            const bookmarkDocRef = doc(
                firestore,
                "user",
                auth.currentUser.uid,
                "bookmark",
                message.documentId,
            );

            if (message.documentClickBookmark) {
                dispatch(notificationViewActions.unsetDocumentClickBookmark());

                await deleteDoc(bookmarkDocRef);
            } else {
                dispatch(notificationViewActions.setDocumentClickBookmark());

                const timestamp = Date.now();

                await setDoc(bookmarkDocRef, {
                    documentId: message.documentId,
                    uid: auth.currentUser!.uid,
                    timestamp: timestamp,
                });
            }
        } else {
            navigate("/login");
        }
    };

    if (message.documentId !== "" && auth.currentUser !== null) {
        if (message.documentClickBookmark) {
            return (
                <div className="NotificationView-Bookmark">
                    <button onClick={onClick}>
                        <img src={bookmarkFocus}/>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="NotificationView-Bookmark">
                    <button onClick={onClick}>
                        <img src={bookmark}/>
                    </button>
                </div>
            );
        }
    } else {
        return (
            <div className="NotificationView-Bookmark">
                <button onClick={onClick}>
                    <img src={bookmark}/>
                </button>
            </div>
        );
    }
}

function Misc() {
    const isAdmin = useAppSelector((state) => state.core.isAdmin);

    const message = useAppSelector((state) => state.notificationView.message);

    const auth = getAuth();

    const [click, setClick] = useState(false);

    const onClick = () => {
        if (click) {
            setClick(false);
        } else {
            setClick(true);
        }
    };

    if (click) {
        if (
            auth.currentUser !== null &&
            (message.documentAuthorUid === auth.currentUser.uid || isAdmin)
        ) {
            return (
                <div className="NotificationView-Misc">
                    <button onClick={onClick}>
                        <img src={threeDots}/>
                    </button>
                    <div className="NotificationView-MiscFocus-Mine">
                        <Delete/>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="NotificationView-Misc">
                    <button onClick={onClick}>
                        <img src={threeDots}/>
                    </button>
                    <div className="NotificationView-MiscFocus">
                        <Block/>
                        <Report/>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className="NotificationView-Misc">
                <button onClick={onClick}>
                    <img src={threeDots}/>
                </button>
            </div>
        );
    }
}

function Block() {
    const message = useAppSelector((state) => state.notificationView.message);

    const navigate = useNavigate();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            if (auth.currentUser.uid !== message.documentAuthorNickname) {
                const answer = window.confirm(
                    `${message.documentAuthorNickname}님의 게시글을 모두 차단하겠습니까?`,
                );

                if (answer) {
                    const timestamp = Date.now();

                    const docRef = doc(
                        firestore,
                        "user",
                        auth.currentUser.uid,
                        "block",
                        message.documentAuthorUid,
                    );

                    await setDoc(docRef, {
                        uid: message.documentAuthorUid,
                        timestamp: timestamp,
                    });

                    navigate(0);
                }
            } else {
                alert("본인을 차단할 수는 없습니다.");
            }
        } else {
            navigate("/login");
        }
    };

    return (
        <div className="NotificationView-Block">
            <button onClick={onClick}>
                <img src={block}/>
                <p>차단하기</p>
            </button>
        </div>
    );
}

function Report() {
    const message = useAppSelector((state) => state.notificationView.message);

    const navigate = useNavigate();

    const auth = getAuth();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            navigate("/report", {
                state: {
                    documentId: message.documentId,
                    commentId: "",
                    replyId: "",
                },
            });
        } else {
            navigate("/login");
        }
    };

    return (
        <div className="NotificationView-Report">
            <button onClick={onClick}>
                <img src={notify}/>
                <p>신고하기</p>
            </button>
        </div>
    );
}

function Delete() {
    const message = useAppSelector((state) => state.notificationView.message);

    const navigate = useNavigate();

    const auth = getAuth();
    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    const onClick = async () => {
        const answer = window.confirm("정말 삭제하시겠습니까?");

        if (answer) {
            dispatch(coreActions.setIsLoading(true));

            await deleteDoc(doc(firestore, "metadata", message.documentId));
            await deleteDoc(
                doc(
                    firestore,
                    "user",
                    message.documentAuthorUid,
                    "post",
                    message.documentId,
                ),
            );

            for (const hashtag of message.documentHashtags) {
                if (hashtag !== "") {
                    await deleteDoc(
                        doc(firestore, "hashtag", hashtag, "post", message.documentId),
                    );
                }
            }

            await deleteDoc(doc(firestore, "board", message.documentId));

            dispatch(coreActions.setIsLoading(false));

            navigate("/notification");
        }
    };

    return (
        <div className="NotificationView-Delete">
            <button onClick={onClick}>
                <img src={trash}/>
                <p>삭제하기</p>
            </button>
        </div>
    );
}

function Content() {
    const message = useAppSelector((state) => state.notificationView.message);

    const parsedContent = parse(message.documentContent);

    return <div className="NotificationView-Content">{parsedContent}</div>;
}

function Hashtags() {
    const message = useAppSelector((state) => state.notificationView.message);

    return (
        <div className="NotificationView-Hashtags">
            {
                <ul>
                    {message.documentHashtags.map((hashtag, index) => (
                        <li key={index}>
                            <Hashtag hashtag={hashtag}/>
                        </li>
                    ))}
                </ul>
            }
        </div>
    );
}

function Hashtag(props: { hashtag: string }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/search_query/${props.hashtag.slice(1)}`);
    };

    return (
        <div className="NotificationView-Hashtag">
            <img src={hashtag}/>
            <button className="NotificationView-Hashtag-tag" onClick={onClick}>
                {props.hashtag.slice(1)}
            </button>
        </div>
    );
}

function ReadMore() {
    const navigate = useNavigate();

    const message = useAppSelector((state) => state.notificationView.message);

    const d: DocumentState = {
        documentId: message.documentId,
        authorUid: message.documentAuthorUid,
        authorAvatarUrl: message.documentAuthorAvatarUrl,
        authorNickname: message.documentAuthorNickname,
        timestamp: message.documentTimestamp,
        content: message.documentContent,
        thumbnailContent: [],
        thumbnailImageSizes: [],
        hashtags: message.documentHashtags,
        numUps: message.documentNumUps,
        numDowns: message.documentNumDowns,
        numComments: message.documentNumComments,
        numTokens: message.documentNumTokens,
        clickUp: message.documentClickUp,
        clickDown: message.documentClickDown,
        clickBookmark: message.documentClickBookmark,
    };

    const onClick = () => {
        navigate(`/read/${message.documentId}`, {
            state: {
                document: d,
            },
        });
        navigate(0);
    };

    return (
        <div className="NotificationView-ReadMore">
            <button onClick={onClick}>
                <img src={more}/>
            </button>
        </div>
    );
}

function Summary() {
    return (
        <div className="NotificationView-Summary">
            <NumUps/>
            <NumDowns/>
            <NumComments/>
            <NumTokens/>
        </div>
    );
}

function NumUps() {
    const navigate = useNavigate();

    const message = useAppSelector((state) => state.notificationView.message);

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            const upDocRef = doc(
                firestore,
                "metadata",
                message.documentId,
                "up",
                auth.currentUser.uid,
            );

            if (message.documentClickUp) {
                dispatch(notificationViewActions.unsetDocumentClickUp());

                await deleteDoc(upDocRef);
            } else {
                dispatch(notificationViewActions.setDocumentClickUp());

                const upTimestamp = Date.now();

                await setDoc(upDocRef, {
                    uid: auth.currentUser!.uid,
                    timestamp: upTimestamp,
                });
            }
        } else {
            navigate("/login");
        }
    };

    if (message.documentId !== "" && auth.currentUser !== null) {
        if (message.documentClickUp) {
            return (
                <div className="NotificationView-NumUps-Focus">
                    <button onClick={onClick}>
                        <img src={upFocus}/>
                        <p>{message.documentNumUps}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="NotificationView-NumUps">
                    <button onClick={onClick}>
                        <img src={up}/>
                        <p>{message.documentNumUps}</p>
                    </button>
                </div>
            );
        }
    } else {
        return (
            <div className="NotificationView-NumUps">
                <button onClick={onClick}>
                    <img src={up}/>
                    <p>{message.documentNumUps}</p>
                </button>
            </div>
        );
    }
}

function NumDowns() {
    const navigate = useNavigate();

    const message = useAppSelector((state) => state.notificationView.message);

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            const downDocRef = doc(
                firestore,
                "metadata",
                message.documentId,
                "down",
                auth.currentUser.uid,
            );

            if (message.documentClickDown) {
                dispatch(notificationViewActions.unsetDocumentClickDown());

                await deleteDoc(downDocRef);
            } else {
                dispatch(notificationViewActions.setDocumentClickDown());

                const downTimestamp = Date.now();

                await setDoc(downDocRef, {
                    uid: auth.currentUser!.uid,
                    timestamp: downTimestamp,
                });
            }
        } else {
            navigate("/login");
        }
    };

    if (message.documentId !== "" && auth.currentUser !== null) {
        if (message.documentClickDown) {
            return (
                <div className="NotificationView-NumDowns-Focus">
                    <button onClick={onClick}>
                        <img src={downFocus}/>
                        <p>{message.documentNumDowns}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="NotificationView-NumDowns">
                    <button onClick={onClick}>
                        <img src={down}/>
                        <p>{message.documentNumDowns}</p>
                    </button>
                </div>
            );
        }
    } else {
        return (
            <div className="NotificationView-NumDowns">
                <button onClick={onClick}>
                    <img src={down}/>
                    <p>{message.documentNumDowns}</p>
                </button>
            </div>
        );
    }
}

function NumComments() {
    const navigate = useNavigate();

    const message = useAppSelector((state) => state.notificationView.message);

    const d: DocumentState = {
        documentId: message.documentId,
        authorUid: message.documentAuthorUid,
        authorAvatarUrl: message.documentAuthorAvatarUrl,
        authorNickname: message.documentAuthorNickname,
        timestamp: message.documentTimestamp,
        content: message.documentContent,
        thumbnailContent: [],
        thumbnailImageSizes: [],
        hashtags: message.documentHashtags,
        numUps: message.documentNumUps,
        numDowns: message.documentNumDowns,
        numComments: message.documentNumComments,
        numTokens: message.documentNumTokens,
        clickUp: message.documentClickUp,
        clickDown: message.documentClickDown,
        clickBookmark: message.documentClickBookmark,
    };

    const onClick = () => {
        navigate(`/read/${message.documentId}`, {
            state: {
                document: d,
            },
        });
        navigate(0);
    };

    return (
        <div className="NotificationView-NumComments">
            <button onClick={onClick}>
                <img src={comment}/>
                <p>{message.documentNumComments}</p>
            </button>
        </div>
    );
}

function NumTokens() {
    return (
        <div className="NotificationView-NumTokens">
            <button>
                <p>TOKEN</p>
                <ComingSoon/>
            </button>
        </div>
    );
}

function ComingSoon() {
    return (
        <div className="NotificationView-ComingSoon">
            <button>
                <img src={comingSoon}/>
            </button>
        </div>
    );
}

function Comment() {
    return (
        <div className="NotificationView-Comment">
            <CommentHeader/>
            <CommentBody/>
            <CommentSummary/>
            <ReplyInput/>
            <Replies/>
        </div>
    );
}

function CommentHeader() {
    return (
        <div className="NotificationView-CommentHeader">
            <CommentAvatar/>
            <CommentNickname/>
            <CommentTime/>
            <CommentMisc/>
        </div>
    );
}

function CommentAvatar() {
    const navigate = useNavigate();

    const message = useAppSelector((state) => state.notificationView.message);

    const onClick = () => {
        navigate(`/profile_view/${message.commentAuthorUid}`);
    };

    if (message.commentAuthorAvatarUrl !== "") {
        return (
            <div className="NotificationView-CommentAvatar">
                <img src={message.commentAuthorAvatarUrl} onClick={onClick}/>
            </div>
        );
    } else {
        return (
            <div className="NotificationView-CommentAvatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        );
    }
}

function CommentTime() {
    const message = useAppSelector((state) => state.notificationView.message);

    const endTimestamp = Date.now();

    const diff = endTimestamp - message.commentTimestamp;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 1) {
        return (
            <div className="NotificationView-CommentTime">
                <p>방금 전</p>
            </div>
        );
    } else if (hours < 1) {
        return (
            <div className="NotificationView-CommentTime">
                <p>{minutes} 분 전</p>
            </div>
        );
    } else if (days < 1) {
        return (
            <div className="NotificationView-CommentTime">
                <p>{hours} 시간 전</p>
            </div>
        );
    } else {
        const date = moment(message.commentTimestamp);

        return (
            <div className="NotificationView-CommentTime">
                <p>{date.format("YYYY-MM-DD")}</p>
            </div>
        );
    }
}

function CommentNickname() {
    const navigate = useNavigate();

    const message = useAppSelector((state) => state.notificationView.message);

    const onClick = () => {
        navigate(`/profile_view/${message.commentAuthorUid}`);
    };

    if (message.commentAuthorNickname !== "") {
        return (
            <div className="NotificationView-CommentNickname">
                <button onClick={onClick}>{message.commentAuthorNickname}</button>
            </div>
        );
    } else {
        return (
            <div className="NotificationView-CommentNickname">
                <button onClick={onClick}>닉네임 없음</button>
            </div>
        );
    }
}

function CommentMisc() {
    const isAdmin = useAppSelector((state) => state.core.isAdmin);

    const auth = getAuth();

    const message = useAppSelector((state) => state.notificationView.message);

    const [click, setClick] = useState(false);

    const onClick = () => {
        if (click) {
            setClick(false);
        } else {
            setClick(true);
        }
    };

    if (click) {
        if (
            auth.currentUser !== null &&
            (message.commentAuthorUid === auth.currentUser.uid || isAdmin)
        ) {
            return (
                <div className="NotificationView-CommentMisc">
                    <button onClick={onClick}>
                        <img src={threeDots}/>
                    </button>
                    <div className="NotificationView-CommentMiscFocus-Mine">
                        <CommentDelete/>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="NotificationView-CommentMisc">
                    <button onClick={onClick}>
                        <img src={threeDots}/>
                    </button>
                    <div className="NotificationView-CommentMiscFocus">
                        <CommentBlock/>
                        <CommentReport/>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className="NotificationView-CommentMisc">
                <button onClick={onClick}>
                    <img src={threeDots}/>
                </button>
            </div>
        );
    }
}

function CommentBlock() {
    const message = useAppSelector((state) => state.notificationView.message);

    const navigate = useNavigate();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            if (auth.currentUser.uid !== message.commentAuthorNickname) {
                const answer = window.confirm(
                    `${message.commentAuthorNickname}님의 게시글을 모두 차단하겠습니까?`,
                );

                if (answer) {
                    const timestamp = Date.now();

                    const docRef = doc(
                        firestore,
                        "user",
                        auth.currentUser.uid,
                        "block",
                        message.commentAuthorUid,
                    );

                    await setDoc(docRef, {
                        uid: message.commentAuthorUid,
                        timestamp: timestamp,
                    });

                    navigate(0);
                }
            } else {
                alert("본인을 차단할 수는 없습니다.");
            }
        } else {
            navigate("/login");
        }
    };

    return (
        <div className="NotificationView-CommentBlock">
            <button onClick={onClick}>
                <img src={block}/>
                <p>차단하기</p>
            </button>
        </div>
    );
}

function CommentReport() {
    const message = useAppSelector((state) => state.notificationView.message);

    const navigate = useNavigate();

    const auth = getAuth();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            navigate("/report", {
                state: {
                    documentId: message.documentId,
                    commentId: message.commentId,
                    replyId: "",
                },
            });
        } else {
            navigate("/login");
        }
    };

    return (
        <div className="NotificationView-CommentReport">
            <button onClick={onClick}>
                <img src={notify}/>
                <p>신고하기</p>
            </button>
        </div>
    );
}

function CommentDelete() {
    const navigate = useNavigate();

    const message = useAppSelector((state) => state.notificationView.message);

    const auth = getAuth();
    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    const onClick = async () => {
        const answer = window.confirm("정말 삭제하시겠습니까?");

        if (answer) {
            dispatch(coreActions.setIsLoading(true));

            await deleteDoc(
                doc(
                    firestore,
                    "metadata",
                    message.documentId,
                    "comment",
                    message.commentId,
                ),
            );

            await deleteDoc(
                doc(
                    firestore,
                    "board",
                    message.documentId,
                    "comment",
                    message.commentId,
                ),
            );

            navigate("/notification");

            dispatch(coreActions.setIsLoading(false));
        }
    };

    return (
        <div className="NotificationView-CommentDelete">
            <button onClick={onClick}>
                <img src={trash}/>
                <p>삭제하기</p>
            </button>
        </div>
    );
}

function CommentBody() {
    const message = useAppSelector((state) => state.notificationView.message);

    return (
        <div className="NotificationView-CommentBody">
            <p>{message.commentContent}</p>
        </div>
    );
}

function CommentSummary() {
    return (
        <div className="NotificationView-CommentSummary">
            <CommentNumUps/>
            <CommentNumDowns/>
            <CommentNumReplies/>
            <CommentNumTokens/>
        </div>
    );
}

function CommentNumUps() {
    const navigate = useNavigate();

    const message = useAppSelector((state) => state.notificationView.message);

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            const upDocRef = doc(
                firestore,
                "metadata",
                message.documentId,
                "comment",
                message.commentId,
                "up",
                auth.currentUser.uid,
            );

            if (message.commentClickUp) {
                dispatch(notificationViewActions.unsetCommentClickUp());

                await deleteDoc(upDocRef);
            } else {
                dispatch(notificationViewActions.setCommentClickUp());

                const upTimestamp = Date.now();

                await setDoc(upDocRef, {
                    uid: auth.currentUser!.uid,
                    timestamp: upTimestamp,
                });
            }
        } else {
            navigate("/login");
        }
    };

    if (
        message.documentId !== "" &&
        message.commentId !== "" &&
        auth.currentUser !== null
    ) {
        if (message.commentClickUp) {
            return (
                <div className="NotificationView-CommentNumUps-Focus">
                    <button onClick={onClick}>
                        <img src={upFocus}/>
                        <p>{message.commentNumUps}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="NotificationView-CommentNumUps">
                    <button onClick={onClick}>
                        <img src={up}/>
                        <p>{message.commentNumUps}</p>
                    </button>
                </div>
            );
        }
    } else {
        return (
            <div className="NotificationView-CommentNumUps">
                <button onClick={onClick}>
                    <img src={up}/>
                    <p>{message.commentNumUps}</p>
                </button>
            </div>
        );
    }
}

function CommentNumDowns() {
    const navigate = useNavigate();

    const message = useAppSelector((state) => state.notificationView.message);

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            const downDocRef = doc(
                firestore,
                "metadata",
                message.documentId,
                "comment",
                message.commentId,
                "down",
                auth.currentUser.uid,
            );

            if (message.commentClickDown) {
                dispatch(notificationViewActions.unsetCommentClickDown());

                await deleteDoc(downDocRef);
            } else {
                dispatch(notificationViewActions.setCommentClickDown());

                const downTimestamp = Date.now();

                await setDoc(downDocRef, {
                    uid: auth.currentUser!.uid,
                    timestamp: downTimestamp,
                });
            }
        } else {
            navigate("/login");
        }
    };

    if (
        message.documentId !== "" &&
        message.commentId !== "" &&
        auth.currentUser !== null
    ) {
        if (message.commentClickDown) {
            return (
                <div className="NotificationView-CommentNumDowns-Focus">
                    <button onClick={onClick}>
                        <img src={downFocus}/>
                        <p>{message.commentNumDowns}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="NotificationView-CommentNumDowns">
                    <button onClick={onClick}>
                        <img src={down}/>
                        <p>{message.commentNumDowns}</p>
                    </button>
                </div>
            );
        }
    } else {
        return (
            <div className="NotificationView-CommentNumDowns">
                <button onClick={onClick}>
                    <img src={down}/>
                    <p>{message.commentNumDowns}</p>
                </button>
            </div>
        );
    }
}

function CommentNumReplies() {
    const message = useAppSelector((state) => state.notificationView.message);

    return (
        <div className="NotificationView-CommentNumReplies">
            <button>
                <img src={comment}/>
                <p>{message.commentNumReplies}</p>
            </button>
        </div>
    );
}

function CommentNumTokens() {
    return (
        <div className="NotificationView-CommentNumTokens">
            <button>
                <p>TOKEN</p>
                <ComingSoon/>
            </button>
        </div>
    );
}

function ReplyInput() {
    return (
        <div className="NotificationView-ReplyInput">
            <ReplyImage/>
            <ReplyInputAvatar/>
            <ReplyInputBody/>
        </div>
    );
}

function ReplyImage() {
    return (
        <div className="NotificationView-ReplyImage">
            <img src={reply}/>
        </div>
    );
}

function ReplyInputAvatar() {
    const [avatarUrl, setAvatarUrl] = useState("");

    const auth = getAuth();
    const firestore = getFirestore();

    useEffect(() => {
        if (auth.currentUser !== null) {
            const userDocRef = doc(firestore, "user", auth.currentUser.uid);

            getDoc(userDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    const data = docSnap.data();

                    setAvatarUrl(data.avatarUrl || "");
                }
            });
        }
    }, []);

    if (avatarUrl !== "") {
        return (
            <div className="NotificationView-ReplyInputAvatar">
                <img src={avatarUrl}/>
            </div>
        );
    } else {
        return (
            <div className="NotificationView-ReplyInputAvatar">
                <img src={defaultAvatar}/>
            </div>
        );
    }
}

function ReplyInputBody() {
    const navigate = useNavigate();

    const message = useAppSelector((state) => state.notificationView.message);

    const dispatch = useAppDispatch();

    const [content, setContent] = useState("");

    const auth = getAuth();
    const firestore = getFirestore();

    const onChange = (e: any) => {
        if (e.target.value.length < 200) {
            setContent(e.target.value);
        } else {
            alert("답글 글자수는 200자로 제한됩니다.");
        }
    };

    const onClick = async () => {
        if (content !== "") {
            if (content !== "") {
                dispatch(coreActions.setIsLoading(true));

                const timestamp = Date.now();

                const id = uuid();

                const replyId = timestamp.toString() + "-" + id;

                if (auth.currentUser !== null) {
                    await setDoc(
                        doc(
                            firestore,
                            "board",
                            message.documentId,
                            "comment",
                            message.commentId,
                            "reply",
                            replyId,
                        ),
                        {
                            documentId: message.documentId,
                            commentId: message.commentId,
                            replyId: replyId,
                            authorUid: auth.currentUser.uid,
                            content: content,
                            timestamp: timestamp,
                        },
                    );

                    await setDoc(
                        doc(
                            firestore,
                            "metadata",
                            message.documentId,
                            "comment",
                            message.commentId,
                            "reply",
                            replyId,
                        ),
                        {
                            documentId: message.documentId,
                            commentId: message.commentId,
                            replyId: replyId,
                            authorUid: auth.currentUser.uid,
                            timestamp: timestamp,
                        },
                    );

                    await setDoc(
                        doc(
                            firestore,
                            "user",
                            message.commentAuthorUid,
                            "notification",
                            replyId,
                        ),
                        {
                            messageType: "reply",
                            documentId: message.documentId,
                            commentId: message.commentId,
                            replyId: replyId,
                            authorUid: auth.currentUser!.uid,
                            timestamp: timestamp,
                        },
                    );

                    setContent("");
                    dispatch(notificationViewActions.incrementNumReplies(1));
                    dispatch(coreActions.setIsLoading(false));
                } else {
                    alert("답글 쓰기는 로그인하여야 가능합니다.");

                    navigate("/login");

                    dispatch(coreActions.setIsLoading(false));
                }
            }
        }
    };

    return (
        <div className="NotificationView-ReplyInputBody">
            <form>
        <textarea
            placeholder={"답글을 남겨주세요!"}
            wrap="soft"
            value={content}
            onChange={onChange}
        />
            </form>
            <button onClick={onClick}>등록</button>
        </div>
    );
}

function Replies() {
    const message = useAppSelector((state) => state.notificationView.message);
    const replies = useAppSelector((state) => state.notificationView.replies);
    const fetching = useAppSelector((state) => state.home.fetching);
    const fetched = useAppSelector((state) => state.home.fetched);

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const [repliesLastVisible, setRepliesLastVisible] = useState<any>(undefined);
    const [repliesTimestamp, setRepliesTimestamp] = useState(Date.now());

    const getReplies = () => {
        if (message.documentId! !== "" && message.commentId !== "") {
            let q;
            if (repliesLastVisible === -1) {
                return;
            } else if (repliesLastVisible !== undefined) {
                q = query(
                    collection(
                        firestore,
                        "board",
                        message.documentId,
                        "comment",
                        message.commentId,
                        "reply",
                    ),
                    orderBy("timestamp", "desc"),
                    limit(10),
                    startAfter(repliesLastVisible),
                );
            } else {
                dispatch(notificationViewActions.resetFetching());
                dispatch(notificationViewActions.resetFetched());

                q = query(
                    collection(
                        firestore,
                        "board",
                        message.documentId,
                        "comment",
                        message.commentId,
                        "reply",
                    ),
                    orderBy("timestamp", "desc"),
                    limit(10),
                );
            }

            onSnapshot(q, async (snapshot) => {
                const newReplies = snapshot.docs.map((elem) => elem.data());

                dispatch(notificationViewActions.incrementFetching(newReplies.length));

                for (const elem of newReplies) {
                    if (!elem.numReplies) {
                        getReply(elem);
                    }
                }

                if (snapshot.docs.length === 0) {
                    setRepliesLastVisible(-1);
                } else {
                    setRepliesLastVisible(snapshot.docs[snapshot.docs.length - 1]);
                }
            });
        }
    };

    const getReply = async (elem: any) => {
        if (auth.currentUser !== null) {
            const blockDocRef = doc(
                firestore,
                "user",
                auth.currentUser.uid,
                "block",
                elem.authorUid,
            );
            const blockDocSnap = await getDoc(blockDocRef);
            if (blockDocSnap.exists()) {
                dispatch(notificationViewActions.appendBlocked(elem.replyId));
                dispatch(notificationViewActions.incrementFetched(1));
                return;
            }
        }

        let authorAvatarUrl = "";
        let authorNickname = "";

        const userDocRef = doc(firestore, "user", elem.authorUid);

        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
            const data = docSnap.data();
            authorAvatarUrl = data.avatarUrl || "";
            authorNickname = data.nickname || "";
        } else {
            dispatch(notificationViewActions.appendBlocked(elem.replyId));
            dispatch(notificationViewActions.incrementFetched(1));
            return;
        }

        const upRef = doc(
            firestore,
            "metadata",
            message.documentId,
            "comment",
            message.commentId,
            "reply",
            elem.replyId,
            "up",
            "summary",
        );
        const downRef = doc(
            firestore,
            "metadata",
            message.documentId,
            "comment",
            message.commentId,
            "reply",
            elem.replyId,
            "down",
            "summary",
        );

        const upSnap = await getDoc(upRef);
        const downSnap = await getDoc(downRef);

        let numUps = 0;
        let numDowns = 0;

        if (upSnap.exists()) {
            numUps = upSnap.data().numUps || 0;
        }

        if (downSnap.exists()) {
            numDowns = downSnap.data().numDowns || 0;
        }

        let clickUp = false;
        let clickDown = false;

        if (auth.currentUser !== null) {
            const upDocRef = doc(
                firestore,
                "metadata",
                message.documentId,
                "comment",
                message.commentId,
                "up",
                auth.currentUser.uid,
            );

            const downDocRef = doc(
                firestore,
                "metadata",
                message.documentId,
                "comment",
                message.commentId,
                "down",
                auth.currentUser.uid,
            );

            await getDoc(upDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    clickUp = true;
                }
            });

            await getDoc(downDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    clickDown = true;
                }
            });
        }

        const reply: ReplyState = {
            replyId: elem.replyId,
            authorUid: elem.authorUid,
            authorAvatarUrl: authorAvatarUrl,
            authorNickname: authorNickname,
            timestamp: elem.timestamp,
            content: elem.content,
            numUps: numUps,
            numDowns: numDowns,
            clickUp: clickUp,
            clickDown: clickDown,
        };

        if (elem.timestamp < repliesTimestamp) {
            dispatch(notificationViewActions.appendReply(reply));
        }

        dispatch(notificationViewActions.incrementFetched(1));
    };

    useEffect(() => {
        dispatch(coreActions.setFocus("notification"));

        dispatch(notificationViewActions.resetBlocked());
        dispatch(notificationViewActions.resetReplies());

        setRepliesLastVisible(undefined);

        getReplies();
    }, [message.documentId, message.commentId]);

    const onClick = () => {
        getReplies();
    };

    if (fetched < fetching) {
        return (
            <div className="NotificationView-Replies">
                <LoadingBar/>
                {replies.map((item, i) => {
                    return <Reply reply={item}/>;
                })}
                <div className="NotificationView-ReadMoreReplies">
                    <button onClick={onClick}>답글 더보기</button>
                </div>
            </div>
        );
    } else {
        if (repliesLastVisible !== -1) {
            return (
                <div className="NotificationView-Replies">
                    {replies.map((item, i) => {
                        return <Reply reply={item}/>;
                    })}
                    <div className="NotificationView-ReadMoreReplies">
                        <button onClick={onClick}>답글 더보기</button>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="NotificationView-Replies">
                    {replies.map((item, i) => {
                        return <Reply reply={item}/>;
                    })}
                </div>
            );
        }
    }
}

function LoadingBar() {
    return (
        <div className="NotificationView-LoadingBar">
            <LinearProgress/>
        </div>
    );
}

function Reply(props: { reply: ReplyState }) {
    return (
        <div className="NotificationView-Reply">
            <ReplyHeader reply={props.reply}/>
            <ReplyBody reply={props.reply}/>
            <ReplySummary reply={props.reply}/>
        </div>
    );
}

function ReplyHeader(props: { reply: ReplyState }) {
    return (
        <div className="NotificationView-CommentHeader">
            <ReplyImage/>
            <ReplyAvatar reply={props.reply}/>
            <ReplyNickname reply={props.reply}/>
            <ReplyTime reply={props.reply}/>
            <ReplyMisc reply={props.reply}/>
        </div>
    );
}

function ReplyAvatar(props: { reply: ReplyState }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/profile_view/${props.reply.authorUid}`);
    };

    if (props.reply.authorAvatarUrl !== "") {
        return (
            <div className="NotificationView-ReplyAvatar">
                <img src={props.reply.authorAvatarUrl} onClick={onClick}/>
            </div>
        );
    } else {
        return (
            <div className="NotificationView-ReplyAvatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        );
    }
}

function ReplyTime(props: { reply: ReplyState }) {
    const endTimestamp = Date.now();

    const diff = endTimestamp - props.reply.timestamp;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 1) {
        return (
            <div className="NotificationView-ReplyTime">
                <p>방금 전</p>
            </div>
        );
    } else if (hours < 1) {
        return (
            <div className="NotificationView-ReplyTime">
                <p>{minutes} 분 전</p>
            </div>
        );
    } else if (days < 1) {
        return (
            <div className="NotificationView-ReplyTime">
                <p>{hours} 시간 전</p>
            </div>
        );
    } else {
        const date = moment(props.reply.timestamp);

        return (
            <div className="NotificationView-ReplyTime">
                <p>{date.format("YYYY-MM-DD")}</p>
            </div>
        );
    }
}

function ReplyNickname(props: { reply: ReplyState }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/profile_view/${props.reply.authorUid}`);
    };

    if (props.reply.authorNickname !== "") {
        return (
            <div className="NotificationView-ReplyNickname">
                <button onClick={onClick}>{props.reply.authorNickname}</button>
            </div>
        );
    } else {
        return (
            <div className="NotificationView-ReplyNickname">
                <button onClick={onClick}>닉네임 없음</button>
            </div>
        );
    }
}

function ReplyMisc(props: { reply: ReplyState }) {
    const isAdmin = useAppSelector((state) => state.core.isAdmin);

    const auth = getAuth();

    const [click, setClick] = useState(false);

    const onClick = () => {
        if (click) {
            setClick(false);
        } else {
            setClick(true);
        }
    };

    if (click) {
        if (
            auth.currentUser !== null &&
            (props.reply.authorUid === auth.currentUser.uid || isAdmin)
        ) {
            return (
                <div className="NotificationView-ReplyMisc">
                    <button onClick={onClick}>
                        <img src={threeDots}/>
                    </button>
                    <div className="NotificationView-ReplyMiscFocus-Mine">
                        <ReplyDelete reply={props.reply}/>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="NotificationView-ReplyMisc">
                    <button onClick={onClick}>
                        <img src={threeDots}/>
                    </button>
                    <div className="NotificationView-ReplyMiscFocus">
                        <ReplyBlock reply={props.reply}/>
                        <ReplyReport reply={props.reply}/>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className="NotificationView-ReplyMisc">
                <button onClick={onClick}>
                    <img src={threeDots}/>
                </button>
            </div>
        );
    }
}

function ReplyBlock(props: { reply: ReplyState }) {
    const navigate = useNavigate();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            if (auth.currentUser.uid !== props.reply.authorNickname) {
                const answer = window.confirm(
                    `${props.reply.authorNickname}님의 게시글을 모두 차단하겠습니까?`,
                );

                if (answer) {
                    const timestamp = Date.now();

                    const docRef = doc(
                        firestore,
                        "user",
                        auth.currentUser.uid,
                        "block",
                        props.reply.authorUid,
                    );

                    await setDoc(docRef, {
                        uid: props.reply.authorUid,
                        timestamp: timestamp,
                    });

                    navigate(0);
                }
            } else {
                alert("본인을 차단할 수는 없습니다.");
            }
        } else {
            navigate("/login");
        }
    };

    return (
        <div className="NotificationView-ReplyBlock">
            <button onClick={onClick}>
                <img src={block}/>
                <p>차단하기</p>
            </button>
        </div>
    );
}

function ReplyReport(props: { reply: ReplyState }) {
    const message = useAppSelector((state) => state.notificationView.message);

    const navigate = useNavigate();

    const auth = getAuth();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            navigate("/report", {
                state: {
                    documentId: message.documentId,
                    commentId: message.commentId,
                    replyId: props.reply.replyId,
                },
            });
        } else {
            navigate("/login");
        }
    };

    return (
        <div className="NotificationView-ReplyReport">
            <button onClick={onClick}>
                <img src={notify}/>
                <p>신고하기</p>
            </button>
        </div>
    );
}

function ReplyDelete(props: { reply: ReplyState }) {
    const message = useAppSelector((state) => state.notificationView.message);

    const auth = getAuth();
    const firestore = getFirestore();

    const dispatch = useAppDispatch();

    const onClick = async () => {
        const answer = window.confirm("정말 삭제하시겠습니까?");

        if (answer) {
            dispatch(coreActions.setIsLoading(true));

            await deleteDoc(
                doc(
                    firestore,
                    "metadata",
                    message.documentId,
                    "comment",
                    message.commentId,
                    "reply",
                    props.reply.replyId,
                ),
            );

            await deleteDoc(
                doc(
                    firestore,
                    "board",
                    message.documentId,
                    "comment",
                    message.commentId,
                    "reply",
                    props.reply.replyId,
                ),
            );

            dispatch(notificationViewActions.removeReply(props.reply));
            dispatch(notificationViewActions.incrementNumReplies(-1));

            dispatch(coreActions.setIsLoading(false));
        }
    };

    return (
        <div className="NotificationView-ReplyDelete">
            <button onClick={onClick}>
                <img src={trash}/>
                <p>삭제하기</p>
            </button>
        </div>
    );
}

function ReplyBody(props: { reply: ReplyState }) {
    return (
        <div className="NotificationView-ReplyBody">
            <p>{props.reply.content}</p>
        </div>
    );
}

function ReplySummary(props: { reply: ReplyState }) {
    return (
        <div className="NotificationView-ReplySummary">
            <ReplyNumUps reply={props.reply}/>
            <ReplyNumDowns reply={props.reply}/>
        </div>
    );
}

function ReplyNumUps(props: { reply: ReplyState }) {
    const navigate = useNavigate();

    const message = useAppSelector((state) => state.notificationView.message);

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            const upDocRef = doc(
                firestore,
                "metadata",
                message.documentId,
                "comment",
                message.commentId,
                "reply",
                props.reply.replyId,
                "up",
                auth.currentUser.uid,
            );

            if (props.reply.clickUp) {
                dispatch(
                    notificationViewActions.unsetReplyClickUp(props.reply.replyId),
                );

                await deleteDoc(upDocRef);
            } else {
                dispatch(notificationViewActions.setReplyClickUp(props.reply.replyId));

                const upTimestamp = Date.now();

                await setDoc(upDocRef, {
                    uid: auth.currentUser!.uid,
                    timestamp: upTimestamp,
                });
            }
        } else {
            navigate("/login");
        }
    };

    if (
        message.documentId !== "" &&
        message.commentId !== "" &&
        auth.currentUser !== null
    ) {
        if (props.reply.clickUp) {
            return (
                <div className="NotificationView-ReplyNumUps-Focus">
                    <button onClick={onClick}>
                        <img src={upFocus}/>
                        <p>{props.reply.numUps}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="NotificationView-ReplyNumUps">
                    <button onClick={onClick}>
                        <img src={up}/>
                        <p>{props.reply.numUps}</p>
                    </button>
                </div>
            );
        }
    } else {
        return (
            <div className="NotificationView-ReplyNumUps">
                <button onClick={onClick}>
                    <img src={up}/>
                    <p>{props.reply.numUps}</p>
                </button>
            </div>
        );
    }
}

function ReplyNumDowns(props: { reply: ReplyState }) {
    const navigate = useNavigate();

    const message = useAppSelector((state) => state.notificationView.message);

    const dispatch = useAppDispatch();

    const auth = getAuth();
    const firestore = getFirestore();

    const onClick = async () => {
        if (auth.currentUser !== null) {
            const downDocRef = doc(
                firestore,
                "metadata",
                message.documentId,
                "comment",
                message.commentId,
                "reply",
                props.reply.replyId,
                "down",
                auth.currentUser.uid,
            );

            if (props.reply.clickDown) {
                dispatch(
                    notificationViewActions.unsetReplyClickDown(props.reply.replyId),
                );

                await deleteDoc(downDocRef);
            } else {
                dispatch(
                    notificationViewActions.setReplyClickDown(props.reply.replyId),
                );

                const downTimestamp = Date.now();

                await setDoc(downDocRef, {
                    uid: auth.currentUser!.uid,
                    timestamp: downTimestamp,
                });
            }
        } else {
            navigate("/login");
        }
    };

    if (
        message.documentId !== "" &&
        message.commentId !== "" &&
        auth.currentUser !== null
    ) {
        if (props.reply.clickDown) {
            return (
                <div className="NotificationView-ReplyNumDowns-Focus">
                    <button onClick={onClick}>
                        <img src={downFocus}/>
                        <p>{props.reply.numDowns}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="NotificationView-ReplyNumDowns">
                    <button onClick={onClick}>
                        <img src={down}/>
                        <p>{props.reply.numDowns}</p>
                    </button>
                </div>
            );
        }
    } else {
        return (
            <div className="NotificationView-ReplyNumDowns">
                <button onClick={onClick}>
                    <img src={down}/>
                    <p>{props.reply.numDowns}</p>
                </button>
            </div>
        );
    }
}
